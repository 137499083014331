import { Box, Divider } from "@mui/material";
import { CustomTypography } from "../../shared";
import { CurveBgStyle } from "../../../assets/icons/CurveBgStyle";

interface TransactionProps {
  transType: string;
  assetName: string;
  date: string;
}

export default function TradeDetailsCard({
  transType,
  assetName,
  date,
}: TransactionProps) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        backgroundColor: "#EAF4DF",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        {/* FIXME: sv is not properly set */}
        <CurveBgStyle
        // width="inherit"
        // height="inherit"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "16px 32px",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box display={"block"}>
          <CustomTypography
            variant="Regular18"
            borderRadius={"4px"}
            padding={"5px 8px"}
            color={"#64677A"}
          >
            {"Stock name"}
          </CustomTypography>
          <CustomTypography
            variant="Medium24"
            color={"#253326"}
          >
            {assetName}
          </CustomTypography>
        </Box>
        <Box
          display={"flex"}
          mt={"40px"}
        >
          <Box>
            <CustomTypography
              variant="Regular18"
              color={"#64677A"}
            >
              {"Transaction Type"}
            </CustomTypography>
            <CustomTypography
              variant="Regular20"
              color={"#12131A"}
            >
              {date}
            </CustomTypography>
          </Box>
          <Box
            display={"block"}
            ml={"136px"}
          >
            <CustomTypography
              variant="Regular18"
              color={"#64677A"}
            >
              {"Date"}
            </CustomTypography>
            <CustomTypography
              variant="Regular20"
              color={"#12131A"}
            >
              {transType}
            </CustomTypography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
