import { AMIDAssetTotal, AMIDAssetTotal_Big, AssetTotal, AssetTypeEnum, AssetsAddInfo, AssetsByAssetType, MFETFPortfolioBreakdown, MFPortfolioBreakdown, Stocks_Sub_Category_Type, SIDAssetTotals, EE_BreakdownEntry, Portfolio } from "../constants";
import { addToAssetTotal, getAMIDAssetByAMID, getAMIDAssetTotals, getSIDAssetsByAMID, getSIDAssetsForAMID } from "./portfolioSummary";
import Big from "big.js";
import { DIRECT_HOLDINGS_CATEGORY_NAME, DIRECT_STOCKS_CATEGORY_NAME, PMS_STOCKS_CATEGORY_NAME } from "./shared";

export const getStocksAssetAllocationIncludingMFETFs =
    (
        PFID: number,
        assetsByAssetType: AssetsByAssetType,
        mfETFPortfolioBreakdown?: MFETFPortfolioBreakdown,
        assetsAddInfo?: AssetsAddInfo
    ) => {
    if (!assetsByAssetType || !mfETFPortfolioBreakdown || !assetsAddInfo || !assetsAddInfo.Stocks) return [];

    var stocksNameDict: { [AMID: number]: string } = {};

    mfETFPortfolioBreakdown.StocksInfo?.forEach((stocksInfo) => {
        stocksNameDict[stocksInfo.MProfitCode] = stocksInfo.CompanyName;
    })

    var stocksByAMID: {[AMID: number]: AMIDAssetTotal_Big} = {};
    var MFsByAMID: {[AMID: number]: AMIDAssetTotal_Big} = {};
    for (var key in assetsByAssetType) {
        var assetTypeID = +key;
        switch (assetTypeID) {
            case AssetTypeEnum.EQ:
                stocksByAMID = getAMIDAssetByAMID(assetsByAssetType[assetTypeID]);
                break;
            case AssetTypeEnum.MFEQ:
                MFsByAMID = getAMIDAssetByAMID(assetsByAssetType[assetTypeID]);
                break;
        }
    }

    mfETFPortfolioBreakdown.MF?.forEach((mfPortfolioBreakdown) => {
        var MFETFAsset = MFsByAMID[mfPortfolioBreakdown.MProfitCode];
        if (MFETFAsset) {
            addMFETFBreakdownToStocksTotals(MFETFAsset, mfPortfolioBreakdown, PFID, assetTypeID, stocksByAMID, stocksNameDict);
        }
    })

    mfETFPortfolioBreakdown.ETF?.forEach((mfPortfolioBreakdown) => {
        var MFETFAsset = stocksByAMID[mfPortfolioBreakdown.MProfitCode];
        if (MFETFAsset) {
            addMFETFBreakdownToStocksTotals(MFETFAsset, mfPortfolioBreakdown, PFID, assetTypeID, stocksByAMID, stocksNameDict);
        }
    })

    var amidAssetList: AMIDAssetTotal[] = [];

    for (var AMIDStr in stocksByAMID) {
        var AMID = +AMIDStr;
        var addInfo = assetsAddInfo.Stocks[AMID];
        if (addInfo && addInfo.SubCategoryType === Stocks_Sub_Category_Type.ETF) {
            continue;
        }
        var amidAsset = stocksByAMID[AMID];
        if (amidAsset && amidAsset.CurrValue && Big(amidAsset.CurrValue).gt(0)) {
            var amidAssetTotal: AMIDAssetTotal = {
                PFID: amidAsset.PFID,
                AMID: amidAsset.AMID,
                AssetTypeID: amidAsset.AssetTypeID,
                Name: amidAsset.Name,
                CurrValue: amidAsset.CurrValue ? new Big(amidAsset.CurrValue).toJSON(): '',
            };
            amidAssetList.push(amidAssetTotal);
        }
    }

    return amidAssetList;
}

const getNewAMIDAssetTotalForMFPortBreakdown = (PFID: number, AssetTypeID: number, AMID: number, stocksNameDict: { [AMID: number]: string }): AMIDAssetTotal_Big => {
    return {
        CurrValue: new Big(0),
        PFID,
        AMID,
        AssetTypeID,
        Name: stocksNameDict[AMID]
    };
}

const addMFETFBreakdownToStocksTotals = (MFETFAsset: AMIDAssetTotal_Big, mfPortfolioBreakdown: MFPortfolioBreakdown, PFID: number, assetTypeID: number, stocksByAMID: {[AMID: number]: AMIDAssetTotal_Big}, stocksNameDict: { [AMID: number]: string }) => {
    mfPortfolioBreakdown.AssetTypes.forEach((assetTypeBreakdown) => {
        if (assetTypeBreakdown.AssetTypeID === AssetTypeEnum.EQ) {
            console.log("🚀 ~ file: mfPortfolioBreakdown.ts:92 ~ mfPortfolioBreakdown.AssetTypes.forEach ~ assetTypeBreakdown:", assetTypeBreakdown)
            assetTypeBreakdown.Assets?.forEach((assetBreakdown) => {
                var stockAsset = stocksByAMID[assetBreakdown.MProfitCode];
                if (!stockAsset) {
                    stockAsset = getNewAMIDAssetTotalForMFPortBreakdown(PFID, assetTypeID, assetBreakdown.MProfitCode, stocksNameDict);
                }

                if (MFETFAsset) {
                    stockAsset.CurrValue = (stockAsset.CurrValue || new Big(0)).plus(Big(MFETFAsset.CurrValue || 0).times(assetBreakdown.PercentageOfAUM).div(100));
                }

                stocksByAMID[assetBreakdown.MProfitCode] = stockAsset;
            })
        }
    })
}

interface MFETFsHavingStockAMID {
    AMID: number;
    ETF: MFETFsHavingStockAMIDItem[];
    MF: MFETFsHavingStockAMIDItem[];
}

interface MFETFsHavingStockAMIDItem {
    MProfitCode: number;
    SelectedAssetPercentageOfAUM: number;
}

export interface DetailedEquityExposureForStockAMID {
    AMID: number;
    ETF: DetailedEquityExposureForStockAMIDItem[];
    MF: DetailedEquityExposureForStockAMIDItem[];
    Direct: DetailedEquityExposureForStockAMIDItem[];
}

export interface DetailedEquityExposureForStockAMIDItem {
    MProfitCode: number;
    AssetName?: string;
    SelectedAssetPercentageOfAUM: number;
    Assets?: AssetBreakdown[];
}

export interface AssetBreakdown {
    SID: number;
    PFID: number;
    PortfolioName: string;
    CurrentValue: string;
    IsCashMgmtPortfolio: boolean;
}

export const getMFETFsHavingStockAMID = (
    AMID: number,
    mfETFPortfolioBreakdown?: MFETFPortfolioBreakdown,
): MFETFsHavingStockAMID => {

    if (!mfETFPortfolioBreakdown) return { ETF: [], MF: [], AMID };

    var result: MFETFsHavingStockAMID = {
        AMID,
        ETF: [],
        MF: []
    };

    mfETFPortfolioBreakdown.ETF?.forEach(etf => {
        etf.AssetTypes.forEach(assetType => {
            if (assetType.AssetTypeID === AssetTypeEnum.EQ) {
                assetType.Assets?.forEach(asset => {
                    if (asset.MProfitCode === AMID) {
                        result.ETF.push({
                            MProfitCode: etf.MProfitCode,
                            SelectedAssetPercentageOfAUM: asset.PercentageOfAUM
                        })
                    }
                });
            }
        });
    });

    mfETFPortfolioBreakdown.MF?.forEach(mf => {
        mf.AssetTypes.forEach(assetType => {
            if (assetType.AssetTypeID === AssetTypeEnum.EQ) {
                assetType.Assets?.forEach(asset => {
                    if (asset.MProfitCode === AMID) {
                        result.MF.push({
                            MProfitCode: mf.MProfitCode,
                            SelectedAssetPercentageOfAUM: asset.PercentageOfAUM
                        })
                    }
                
                });
            }
        });
    });

    return result;
};

export const getDetailedEquityExposureForStockAMID =
    (
        AMID: number,
        ETFsByAMID: {[AMID: number]: AssetTotal[]},
        MFsByAMID: {[AMID: number]: AssetTotal[]},
        mfETFsHavingStockAMID: MFETFsHavingStockAMID,
        assetsByAssetType: AssetsByAssetType,
        portfolioMap?: { [key: number]: Portfolio }
    ): DetailedEquityExposureForStockAMID => {
    if (!ETFsByAMID || !MFsByAMID) return { AMID, ETF: [], MF: [], Direct: [] };

    var result: DetailedEquityExposureForStockAMID = {
        AMID,
        ETF: [],
        MF: [],
        Direct: []
    };

    result.ETF = mfETFsHavingStockAMID.ETF.map((etf) => {
        const ETFsByAMIDList = ETFsByAMID[etf.MProfitCode];
        const assets = ETFsByAMIDList.map((asset) => {
            const portfolio = portfolioMap?.[asset.PFID];
            return {
                SID: asset.SID,
                PFID: asset.PFID,
                PortfolioName: portfolio?.PName || "",
                IsCashMgmtPortfolio: portfolio?.IsCashMgmtPortfolio || false,
                CurrentValue: Big(asset.CurrValue || 0).times(etf.SelectedAssetPercentageOfAUM).div(100).toJSON()
            }
        })

        return {
            MProfitCode: etf.MProfitCode,
            AssetName: ETFsByAMIDList[0]?.Name || "", // FIXME: not sure atm if there is a better way to get the name
            Assets: assets,
            SelectedAssetPercentageOfAUM: etf.SelectedAssetPercentageOfAUM
        }
    })

    result.MF = mfETFsHavingStockAMID.MF.map((mf) => {
        const MFsByAMIDList = MFsByAMID[mf.MProfitCode];
        const assets = MFsByAMIDList.map((asset) => {
            const portfolio = portfolioMap?.[asset.PFID];
            return {
                SID: asset.SID,
                PFID: asset.PFID,
                PortfolioName: portfolio?.PName || "",
                IsCashMgmtPortfolio: portfolio?.IsCashMgmtPortfolio || false,
                CurrentValue: Big(asset.CurrValue || 0).times(mf.SelectedAssetPercentageOfAUM).div(100).toJSON()
            }
        })

        return {
            MProfitCode: mf.MProfitCode,
            AssetName: MFsByAMIDList[0]?.Name || "", // FIXME: not sure atm if there is a better way to get the name
            Assets: assets,
            SelectedAssetPercentageOfAUM: mf.SelectedAssetPercentageOfAUM
        }
    })

    const directStocksByAMID = getSIDAssetsForAMID(assetsByAssetType[AssetTypeEnum.EQ], AMID);
    
    if (directStocksByAMID.length > 0) {
        result.Direct.push({
            MProfitCode: AMID,
            AssetName: DIRECT_HOLDINGS_CATEGORY_NAME,
            SelectedAssetPercentageOfAUM: 100,
            Assets: directStocksByAMID.map((asset) => {
                const portfolio = portfolioMap?.[asset.PFID];
                return {
                    SID: asset.SID,
                    PFID: asset.PFID,
                    PortfolioName: portfolio?.PName || "",
                    IsCashMgmtPortfolio: portfolio?.IsCashMgmtPortfolio || false,
                    CurrentValue: asset.CurrValue || "0"
                }
            })
        });
    }

    return result;
}

export const getEquityExposureBreakdownEntryForSection = (
    sectionItems: DetailedEquityExposureForStockAMIDItem[],
    sectionName: string,
    isDirectStocks: boolean = false
): EE_BreakdownEntry => {
    const calculateTotal = (assets: any[] = []) => 
        assets.reduce((sum, asset) => Big(sum).plus(asset.CurrentValue || 0).toNumber(), 0);

    if (!isDirectStocks) {
        return {
            name: sectionName,
            currValue: calculateTotal(sectionItems.flatMap(item => item.Assets || [])),
            percentage: 0,
            subEntries: sectionItems.map(holding => {
                return <EE_BreakdownEntry>({
                    name: holding.AssetName,
                    currValue: calculateTotal(holding.Assets),
                    percentage: 0,
                    subEntries: holding.Assets?.map(asset => ({
                        name: asset.PortfolioName,
                        currValue: Big(asset.CurrentValue || 0).toNumber(),
                        percentage: 0,
                        SID: asset.SID,
                        PFID: asset.PFID,
                        IsCashMgmtPortfolio: asset.IsCashMgmtPortfolio
                    })) ?? []
                })
            })
        };
    } else {
        const createSubEntry = (name: string, assets: any[]) => ({
            name,
            currValue: calculateTotal(assets),
            percentage: 0,
            subEntries: assets.map(asset => ({
                name: asset.PortfolioName,
                currValue: Big(asset.CurrentValue || 0).toNumber(),
                percentage: 0,
                SID: asset.SID,
                PFID: asset.PFID,
                IsCashMgmtPortfolio: asset.IsCashMgmtPortfolio
            }))
        });

        const assets = sectionItems[0]?.Assets ?? [];
        const subEntries = [
            { name: DIRECT_STOCKS_CATEGORY_NAME, assets: assets.filter(asset => !asset.IsCashMgmtPortfolio) },
            { name: PMS_STOCKS_CATEGORY_NAME, assets: assets.filter(asset => asset.IsCashMgmtPortfolio) }
        ].filter(({ assets }) => assets.length > 0)
            .map(({ name, assets }) => createSubEntry(name, assets));

        return {
            name: sectionName,
            currValue: calculateTotal(assets),
            percentage: 0,
            subEntries
        };
    }
}

export const fillPercentagesInEquityExposureBreakdownEntry = (EEBreakdownEntry: EE_BreakdownEntry | undefined, totalValue: number) => {
    if (EEBreakdownEntry) {
        EEBreakdownEntry.percentage = Big(EEBreakdownEntry.currValue).div(totalValue).toNumber();
        EEBreakdownEntry.subEntries?.forEach(subEntry => {
            subEntry.percentage = Big(subEntry.currValue).div(totalValue).toNumber();
            subEntry.subEntries?.forEach(entry => {
                entry.percentage = Big(entry.currValue).div(totalValue).toNumber();
            });
        });
    }

    return EEBreakdownEntry;
}

const updatePortfolioMap = (
    portfolioMap: {
        [key: number]: {
            name: string,
            currValue: number,
            percentage: number,
            IsCashMgmtPortfolio: boolean,
            subEntries: {
                name: string,
                currValue: number,
                percentage: number,
                assetClass: 'Direct' | 'ETF' | 'MF'
            }[]
        }
    },
    asset: { PFID: number, PortfolioName: string, CurrentValue?: number, IsCashMgmtPortfolio: boolean },
    holdingName: string,
    assetClass: 'Direct' | 'ETF' | 'MF'
): number => {
    const PFID = asset.PFID;
    if (!portfolioMap[PFID]) {
        portfolioMap[PFID] = {
            name: asset.PortfolioName,
            currValue: 0,
            percentage: 0,
            IsCashMgmtPortfolio: asset.IsCashMgmtPortfolio,
            subEntries: []
        };
    }
    
    const currentValue = Big(asset.CurrentValue || 0).toNumber();
    portfolioMap[PFID].currValue = Big(portfolioMap[PFID].currValue).plus(currentValue).toNumber();
    portfolioMap[PFID].subEntries.push({
        name: holdingName,
        currValue: currentValue,
        percentage: 0,
        assetClass
    });
    
    return currentValue;
}

export const processHoldingAssetsInPortfolioWiseEquityExposure = (
    holding: { AssetName?: string, Assets?: any[] },
    portfolioMap: any,
    assetClass: 'Direct' | 'ETF' | 'MF',
    totalValue: number
): number => {
    let updatedTotal = totalValue;
    
    holding.Assets?.forEach((asset) => {
        const holdingName = assetClass === 'Direct' ? DIRECT_HOLDINGS_CATEGORY_NAME : (holding.AssetName || '');
        const currentValue = updatePortfolioMap(portfolioMap, asset, holdingName, assetClass);
        updatedTotal = Big(updatedTotal).plus(currentValue).toNumber();
    });
    
    return updatedTotal;
}