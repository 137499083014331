import { SharedConstants, SharedUtilities } from "@mprofit/shared";
import { Box, Radio, Typography } from "@mui/material";

interface DatabasesDisplayProps {
    nameColor?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    borderColor?: string;
    showRadioButton?: boolean;
    database?: SharedConstants.DatabaseItem;
    selectedDatabase?: SharedConstants.DatabaseItem; // The database selected by user
    onSelect?: (database: SharedConstants.DatabaseItem) => void;
    index?: number;
    disabled?: boolean;
}

export const DatabaseDisplay = ({ nameColor = "#12131A", backgroundColor, hoverBackgroundColor, borderColor, showRadioButton, selectedDatabase, database, onSelect, index, disabled }: DatabasesDisplayProps) => {
    if (!database) return <></>;
  
    const isSelected = database?.CustomerId == selectedDatabase?.CustomerId;
    const databaseName = SharedUtilities.getDatabaseDisplayName(database);
    return (
      <Box sx={{ display: "flex", alignItems: "center", border: `1px solid ${isSelected ? hoverBackgroundColor : borderColor}`, background: isSelected ? hoverBackgroundColor : backgroundColor, borderRadius: "7px", padding: "12px", cursor: disabled ? undefined : "pointer", ":hover": { background: hoverBackgroundColor, borderColor: hoverBackgroundColor } }}
        onClick={() => onSelect && onSelect(database)}>
        {showRadioButton && (
          <Radio
            sx={{
              marginRight: "10px",
              color: "#BABDCC",
              "&.Mui-checked": {
                color: "#5F854C",
              },
            }}
            checked={isSelected}
          />
        )}
        <Box
          sx={{
            background: index ? colors[index % colors.length] : "#5F6F57",
            borderRadius: "50%",
            height: 32,
            width: 32,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "12px",
            color: "#FFFFFF",
          }}
        >
          {databaseName.charAt(0).toUpperCase()}
        </Box>
        <Typography sx={{ fontSize: {sm: "14px", md: "15px", lg: "18px"}, color: nameColor }}>
          {databaseName}
        </Typography>
      </Box>
    )
};

export const colors = [
    "#D2D4FF",
    "#ACC18A",
    "#DAE5A1",
    "#E2DECD",
    "#E2DA90",
    "#D2D4FF",
    "#ACC18A",
    "#DAE5A1",
    "#E2DECD",
    "#E2DA90",
];