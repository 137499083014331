import { Box, TableCell } from "@mui/material";
import { ColumnDataProps, RowDataProps } from "../virtual-table-component";
import CustomTypography from "../customTypography";
import { TableIconUp } from "packages/web/src/assets/icons/TableIconUp";
import { TableIconDown } from "packages/web/src/assets/icons/TableIconDown";
import { CollapseIcon } from "packages/web/src/assets/icons/CollapseIcon";
import { ExpandIcon } from "packages/web/src/assets/icons/ExpandIcon";
import PMSBadge from "../pms-badge";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { useState } from "react";
import { SharedRedux } from '@mprofit/shared';
import { isZeroOrPositive } from '../../../../../shared/src/utilities';
import { RowLevelCellProps } from ".";

export const SecondaryRowCell = ({ columns, row, isFirstRow, isLastRow, onRowClick, PMSBadgeField, isHovered, isExpanded, hasTertiaryRows, EndingCell, cellRadius }: { columns: ColumnDataProps[], row: RowDataProps, isFirstRow: boolean, isLastRow: boolean, onRowClick?: (row: RowDataProps) => void, PMSBadgeField?: string, isHovered: boolean, isExpanded?: boolean, hasTertiaryRows: boolean, EndingCell?: RowLevelCellProps, cellRadius?: number }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openRowMenu = Boolean(anchorEl);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

    const handleOpenRowMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleCloseRowMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <>
            {
                columns.map((column, index) => {
                    var showRowNameClass = column.IsRowName;
                    const isFirstCol = index === 0;
                    if (column.subColumns) {
                        return column.subColumns.map((subColumn) => (
                            <TableCell
                                className={`${showRowNameClass ? "IsRowName" : ""} ${"IsHovered"} ${isExpanded ? "IsSelected" : ""}`}
                                key={subColumn.FieldKey}
                                align={column.Align}
                                sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, ...getSecondaryRowColStyle({ isFirstRow, isLastRow, isFirstCol, isLastCol: false, cellRadius }) }}>
                                {column.SecondaryRowCell ? column.SecondaryRowCell({column, row}) : (
                                <>
                                <CustomTypography variant='Regular18'>{column.FieldFormatter(row[column.FieldKey])}</CustomTypography>
                                {row[subColumn.FieldKey] ?
                                    <Box>
                                        {(subColumn.UpFieldKey ? row[subColumn.UpFieldKey] === true : isZeroOrPositive(row[subColumn.FieldKey])) ?
                                            <CustomTypography sx={{ color: !subColumn.UpFieldKey ? 'rgba(18, 19, 26, 0.6)' : "#5F854C", alignItems: 'center', gap: '4px' }} >
                                                {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                                                {subColumn.UpFieldKey ? <TableIconUp /> : null } 
                                            </CustomTypography>
                                            :
                                            <CustomTypography sx={{ color: !subColumn.UpFieldKey ? 'rgba(18, 19, 26, 0.6)' : '#C94C40', alignItems: 'center', gap: '4px' }} >
                                                {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                                                {subColumn.UpFieldKey ? <TableIconDown /> : null } 
                                            </CustomTypography>
                                        }
                                    </Box>
                                    :
                                    null
                                }
                                </>
                                )}
                            </TableCell >
                        ))
                    }
                    else {
                        return (
                            <TableCell className={`${showRowNameClass ? "IsRowName" : ""} ${"IsHovered"} ${isExpanded ? "IsSelected" : ""}`} key={column.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, ...getSecondaryRowColStyle({ isFirstRow, isLastRow, isFirstCol, isLastCol: false, cellRadius })}}>
                                <Box sx={{display: "flex", alignItems: "center", justifyContent: column.Align}}>
                                {column.SecondaryRowCell ? column.SecondaryRowCell({column, row}) : (
                                <>
                                {hasTertiaryRows && (<Box>
                                    {isFirstCol && ( isExpanded ? <Box sx={{marginRight: "4.28px", marginLeft: "-9px"}}><CollapseIcon /></Box> : <Box sx={{marginRight: "4.28px", marginLeft: "-9px"}}><ExpandIcon /></Box>)}
                                </Box>)}
                                <Box>
                                    <Box display="flex" justifyContent={column.Align}>
                                        <CustomTypography variant='Regular18' >{column.FieldFormatter(row[column.FieldKey === "AssetTypeName" ? "Name" : column.FieldKey])}</CustomTypography>
                                        {
                                            column.IsRowName && PMSBadgeField && row[PMSBadgeField] ? <Box ml={1}><PMSBadge badgeStyles={{ color: '#000', borderColor: '#000', backgroundColor: '#E2DA90', padding: '1px 6px' }} /></Box> : null
                                        }
                                    </Box>  
                                </Box>
                                </>
                                )}
                                </Box>                             
                            </TableCell>
                        )
                    }
                })
            }
            {EndingCell && EndingCell.SecondaryRowCell ? 
            EndingCell.SecondaryRowCell({ isExpanded: isExpanded || false, isFirstRow, isLastRow }) : 
            (<>
            <TableCell className="IsHovered" sx={{ width: "50px", ...getSecondaryRowColStyle({ isFirstRow, isLastRow, isFirstCol: false, isLastCol: true, cellRadius }) }}>
                {/* { isHovered && <Box onClick={handleOpenRowMenu} sx={{display:"flex", justifyContent: "center", alignItems: "center"}}><DotIcon /></Box> }
                { <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openRowMenu}
                    onClose={handleCloseRowMenu}
                    onClick={handleCloseRowMenu}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                        width: "237px",
                        overflow: 'hidden',
                        borderRadius:"4px",
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={viewTransactions}>View Transactions</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>Buy/ Sell</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>Other Transactions</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>All Transactions</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>Corporate Actions</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>Advanced</MenuItem>
                </Menu> } */}
            </TableCell>
            <Box sx={{ marginRight: "12px" }} />
            </>
            )}
        </>
    );
};

export const getSecondaryRowColStyle = ({ isFirstRow, isLastRow, isFirstCol, isLastCol, cellRadius }: any) => {

    if (isFirstRow && isLastRow) {
        if (isFirstCol) {
            return {
                borderTopLeftRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none solid solid",
                borderBottomLeftRadius: cellRadius ? cellRadius : 15,
            }
        }

        if (isLastCol) {
            return {
                borderTopRightRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid solid none",
                borderBottomRightRadius: cellRadius ? cellRadius : 15,
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none solid none",
        }
    }

    if (isFirstRow) {
        if (isFirstCol) {
            return {
                borderTopLeftRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none none solid",
            }
        }

        if (isLastCol) {
            return {
                borderTopRightRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid none none",
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none none none",
        }
    }

    if (isLastRow) {
        if (isFirstCol) {
            return {
                borderBottomLeftRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none solid solid",
            }
        }

        if (isLastCol) {
            return {
                borderBottomRightRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid solid none",
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none solid none",
        }
    }

    if (isFirstCol) {
        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none none solid",
        }
    }

    if (isLastCol) {
        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid solid none none",
        }
    }

    return {
        border: "solid 1px #EBECF2",
        borderStyle: "solid none none none",
    }
}
