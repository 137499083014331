export function NCD(props: any) {
  return (
  <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0"/>
    <g id="Group_222247" data-name="Group 222247" transform="translate(-34.885 -318.301)">
      <path id="Path_237160" data-name="Path 237160" d="M19.984,14.637v-2.71A3.484,3.484,0,0,0,16.5,8.444H14.952A1.161,1.161,0,0,1,13.79,7.282V5.734A3.484,3.484,0,0,0,10.307,2.25H8.371m2.323,0H5.661A1.162,1.162,0,0,0,4.5,3.411V21.218a1.162,1.162,0,0,0,1.161,1.161H18.823a1.162,1.162,0,0,0,1.161-1.161V11.54a9.29,9.29,0,0,0-9.29-9.29Z" transform="translate(38.385 322.051)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
      <path id="Path_237161" data-name="Path 237161" d="M9,14.52l4.582-4.582L15.27,8.25m-5.486.784h.008v.008H9.784Zm.392,0a.392.392,0,1,1-.392-.392A.392.392,0,0,1,10.176,9.034Zm4.31,4.7h.008v.008h-.008Zm.392,0a.392.392,0,1,1-.392-.392A.392.392,0,0,1,14.878,13.736Z" transform="translate(38.492 326.115)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
    </g>
  </svg>
)}
