import { Box, Skeleton, SkeletonProps, styled } from '@mui/material'

interface CustomSkeletonProps extends SkeletonProps {
    position?: string;
    style?: React.CSSProperties;
    sxContainer?: React.CSSProperties;
}

export default function SkeletonLoader(props: CustomSkeletonProps) {
    return (
        <Box sx={{ ...props.sxContainer }}>
            <Skeletons  {...props} animation={props.animation || false} sx={props.style} />
        </Box>
    )
}

const Skeletons = styled(Skeleton)({
    background: '#252833',
    borderRadius: '14px'
})