export function INS(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_150659" data-name="Group 150659" transform="translate(-35 -318)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0"/>
        <g id="Shield_Done" data-name="Shield Done" transform="translate(42.25 323.25)">
          <path id="Stroke_1" data-name="Stroke 1" d="M8.2,20s8.137-2.464,8.137-9.256.295-7.324-.357-7.977S9.271,0,8.2,0,1.077,2.113.425,2.766.066,3.95.066,10.744,8.2,20,8.2,20Z" transform="translate(0.75 0.75)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
          <path id="Stroke_3" data-name="Stroke 3" d="M0,2.4,2.265,4.669,6.931,0" transform="translate(5.778 8.048)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
        </g>
      </g>
    </svg>
)}
