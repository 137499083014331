import { Routes, Route } from "react-router-dom";
import routeNames from "./routeNames";
import Login from "../components/auth/login";
import Register from "../components/auth/register";
import Temp from "../components/temp";
import Transaction from "../components/transaction";
import Cashflows from "../components/cashflows";
import { withLoggedIn } from "./protectedRoutes";
import ErrorPage from "../components/ErrorPage";
import Today from "../components/portfolio/today";
import Holding from "../components/portfolio/holding";
import Performance from "../components/portfolio/performance";
import PortfolioLayout from "../components/portfolio/portfolioLayout";
import Reports from "../components/reports/explore-reports";
import SavedReports from "../components/reports/saved-reports";
import ReportLog from "../components/reports/report-log";
import AuthRedirectComponent from "../components/auth/redirect";
import TransactionDetails from "../components/transaction/tradeDetails";
import ContractNoteDetails from "../components/transaction/contractNoteDetails";
import Summary from "../components/summary";

const Router = () => {
  const Layout = withLoggedIn(PortfolioLayout);
  const TodayPage = withLoggedIn(Today);
  const HoldingPage = withLoggedIn(Holding);
  const PerformancePage = withLoggedIn(Performance);
  const CashflowsPage = withLoggedIn(Cashflows);
  const SummaryPage = withLoggedIn(Summary);

  return (
    <Routes>
      <Route
        element={<Layout />}
        path={routeNames.PORTFOLIO}
      >
        <Route
          path={routeNames.TODAY}
          element={<TodayPage />}
        />
        <Route
          path={routeNames.HOLDING}
          element={<HoldingPage />}
        />
        <Route
          path={routeNames.PERFORMANCE}
          element={<PerformancePage />}
        />
        <Route
          path={routeNames.REPORTS}
          element={<Reports />}
        />
        <Route
          path={routeNames.SAVEDREPORTS}
          element={<SavedReports />}
        />
        <Route
          path={routeNames.REPORTLOG}
          element={<ReportLog />}
        />
        <Route
          path={routeNames.TEMP}
          element={<Temp />}
        />
        <Route
          path={routeNames.SUMMARY}
          element={<SummaryPage />}
        />
        <Route
          path={routeNames.TRANSACTION}
          element={<Transaction />}
        />
        <Route
          path={routeNames.TRANSACTIONDETAILS}
          element={<TransactionDetails />}
        />
        <Route
          path={routeNames.CONTRACTNOTE}
          element={<ContractNoteDetails />}
        />
        <Route
          path={routeNames.CASHFLOWS}
          element={<CashflowsPage />}
        />
      </Route>
      <Route
        path={routeNames.REGISTER}
        element={<Register />}
      />
      <Route
        path={routeNames.LOGIN}
        element={<Login />}
      />
      <Route
        path={routeNames.REDIRECT}
        element={<AuthRedirectComponent />}
      />
      <Route
        path={routeNames.ERROR}
        element={<ErrorPage />}
      />
    </Routes>
  );
};
export default Router;
