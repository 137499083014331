export function ART(props: any) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="Group_150671" data-name="Group 150671" transform="translate(-35 -318)">
      <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0"/>
      <g id="Group_150606" data-name="Group 150606" transform="translate(37.938 320.938)">
        <path id="Path_198389" data-name="Path 198389" d="M0,0H26.123V26.123H0Z" fill="none"/>
        <path id="Path_198390" data-name="Path 198390" d="M12.8,22.592A9.8,9.8,0,0,1,12.8,3c5.41,0,9.8,3.9,9.8,8.708a4.65,4.65,0,0,1-4.9,4.354H14.973a2.177,2.177,0,0,0-1.088,4.082A1.415,1.415,0,0,1,12.8,22.592" transform="translate(0.265 0.265)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke"/>
        <circle id="Ellipse_1971" data-name="Ellipse 1971" cx="0.5" cy="0.5" r="0.5" transform="translate(8.062 11.062)" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
        <circle id="Ellipse_1972" data-name="Ellipse 1972" cx="0.544" cy="0.544" r="0.544" transform="translate(12.517 7.619)" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
        <circle id="Ellipse_1973" data-name="Ellipse 1973" cx="0.5" cy="0.5" r="0.5" transform="translate(17.062 11.062)" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
      </g>
    </g>
  </svg>
)}
