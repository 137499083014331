import { AM_PeriodRequest } from "./assetMasters";

export interface PeriodInfo {
    PeriodFrom?: string;
    PeriodTo?: string;
    PeriodText: string;
    IsAllToDate: boolean;
}

export interface SetPeriodPayload {
    PeriodFromValue?: Date;
    PeriodToValue?: Date;
    PeriodText: string;
    IsAllToDate: boolean;
}

export interface XIRRPerformance {
    XIRRTotal: XIRRTotal;
    XIRRTable: XIRRTable;
    XIRRFYWise: XIRRFYWise;
    XIRRYearWise: XIRRYearWise;
    XIRRBenchmark: XIRRBenchmark;
    XIRRTablePortfolioWise: XIRRTable;
}

export interface XIRRTotal {
    IsLoading: boolean;
    Data?: XIRRTotalItem;
    IndexReturns?: IndexReturnsByIndex[];
    IndexReturnsPeriodRequest?: AM_PeriodRequest;
    IsOlderThan2012Return?: boolean;
    IsIndexLoading?: boolean;
}

export interface IndexReturnsByIndex extends IndexReturnValue {
    MProfitCode: number;
    IndexName: string;
}

export interface IndexReturnsByPeriod extends IndexReturnValue {
    StartDate: string;
    StartDateStr: string;
    EndDate: string;
    EndDateStr: string;
}

export interface IndexReturnValue {
    CAGR: number;
    AbsoluteReturnPct: number;
}

export interface XIRRTable {
    IsLoading: boolean;
    Item?: XIRRTableItem;
}

export interface XIRRFYWise {
    IsLoading: boolean;
    Item?: XIRRFYWiseItem;
    IndexReturnsPeriodRequests?: AM_PeriodRequest[],
    IndexReturns?: IndexReturnsByPeriod[];
    IsIndexLoading?: boolean;
}

export interface XIRRYearWise {
    IsLoading: boolean;
    Item?: XIRRYearWiseItem;
    IndexReturnsPeriodRequests?: AM_PeriodRequest[],
    IndexReturns?: IndexReturnsByPeriod[];
    IsIndexLoading?: boolean;
}
export interface XIRRBenchmark {
    IsLoading: boolean;
    Item?: XIRRBenchmarkItem;
}

export interface XIRRValues {
    XIRR: number;
    OpeningValuation: number;
    ClosingValuation: number;
    TotalInvestment: number;
    TotalWithdrawal: number;
    IncomePayout: number;
    IncomeCumulative: number;
    TotalIncome: number;
    TotalGain: number;
    TotalGainWithoutIncome: number;
    TotalGainPct: number;
    RealisedGain: number;
    UnrealisedGain: number;
    FirstTransDate?: Date;
}

export interface GroupXIRRData extends XIRRValues {
    RowName: string;
    PFID: number;
    IsCashMgmtPortfolio: boolean;
    ATTY: number;
    SID: number;
    IncomeCumulative: number;
    GroupErrorMessage: string | null;
}

export interface FinancialYearWiseGroupData extends XIRRValues {
    IncomeCumulative: number;
    PeriodFrom: string;
    PeriodTo: string;
    GroupErrorMessage: string | null;
}

export interface XIRRTotalItem extends XIRRValues {
    ID: string;
    Status: number;
    PeriodFrom: string;
    PeriodTo: string;
    ErrorMessage: string | null;
    LastRefreshedTime: string;
    LastRequestedTime: string;
    IsCashMgmtPortfolio: boolean;
    TotalInflow: number;
    TotalOutflow: number;
    ClosingCashBalance: number;
    ClosingCashBalanceDate: string;
    OpeningCashBalance: number;
    OpeningCashBalanceDate: string;
    TotalFees: number;
    FeesBreakdown: CashMgmtFeesBreakdown;
}

export class CashMgmtFeesBreakdown {
    PerformanceFees: number = 0;
    CustodyFees: number = 0;
    ManagementFees: number = 0;
    STT: number = 0;
    TDSOnDividend: number = 0;
    OtherCharges: number = 0;
    LegacyFees: number = 0;
}

export interface XIRRTableItem {
    ID: string;
    CustomerId: number;
    FamilyId: number;
    UserId: number;
    PFID: number;
    ATTY: number;
    AMID: number;
    SID: number;
    Status: number;
    ErrorMessage: string | null;
    LastRefreshedTime: string;
    LastRequestedTime: string;
    Timestamp: string;
    PeriodFrom: string;
    PeriodTo: string;
    GroupXIRRData?: GroupXIRRData[];
}

export interface XIRRFYWiseItem {
    ID: string;
    CustomerId: number;
    FamilyId: number;
    UserId: number;
    PFID: number;
    ATTY: number;
    Status: number;
    ErrorMessage: string | null;
    LastRefreshedTime: string;
    LastRequestedTime: string;
    Timestamp: string;
    SID: number;
    FinancialYearWiseGroupData: FinancialYearWiseGroupData[];
}

export interface XIRRYearWiseItem {
    ID: string;
    CustomerId: number;
    FamilyId: number;
    UserId: number;
    PFID: number;
    ATTY: number;
    AMID: number;
    SID: number;
    PortfolioATTY: number[];
    Status: number;
    ErrorMessage: string;
    LastRefreshedTime: string;
    LastRequestedTime: string;
    Timestamp: string;
    AsOfDate: string;
    TimePeriods: TimePeriodFromEndDate[];
    YearWiseGroupData: YearWiseGroupData[];
}
export interface XIRRBenchmarkItem extends XIRRTotalItem {
    BenchmarkXIRRValue: number;
    IsBenchmarkXIRRRequired: boolean;
    BenchmarkMProfitCode: number;
}

export interface TimePeriodFromEndDate {
    Year: number;
    Month: number;
    Day: number;
    ActiveInvOnly?: boolean;
}

export interface YearWiseGroupData extends XIRRValues {
    PeriodFrom: string;
    PeriodTo: string;
    TimePeriodFromEndDate: TimePeriodFromEndDate;
    GroupErrorMessage: string;
}


export enum XIRRStatus {
    Pending = -1,
    Processing = 0,
    Completed = 1,
    Failed = 2,
    NoInvestments = 3,
    Completed_CalculatingRelUnrelGain = 4,
    BenchmarkPricesNotAvailable = 5
}

export interface CashMgmtRow {
    CMID: number;
    Date: string;
    DateStr: string;
    TRTY: number;
    TRSTR: string;
    AMT: number;
}

export interface CashMgmtDetails {
    CashMgmtRows: CashMgmtRow[];
    ClosingCashBalance: number;
    ClosingCashBalanceDate: Date | null;
}

export interface CashMgmtTotalsFeesBreakdown {
    PerformanceFees: number;
    CustodyFees: number;
    ManagementFees: number;
    STT: number;
    TDSOnDividend: number;
    OtherCharges: number;
    LegacyFees: number;
}

export interface CashMgmtTotalsValues {
    TotalInflow: number;
    TotalOutflow: number;
    ClosingCashBalance: number;
    ClosingCashBalanceDate: string;
    OpeningCashBalance: number;
    OpeningCashBalanceDate: string | null;
    TotalIncome: number | null;
    TotalFees: number;
    OpeningValuation: number;
    FirstTransDate: string;
    FeesBreakdown: CashMgmtTotalsFeesBreakdown;
}

export interface CashMgmtTotals {
    CashMgmtTotals: CashMgmtTotalsValues;
    IsManualCashBalRefreshRequired: boolean;
    LastAutoCalcRefreshTimeStamp: string | null;
}

export const XIRRLoadingStatuses = [XIRRStatus.Pending, XIRRStatus.Processing];

export interface CashflowChartIn {
    name: "In";
    "Opening Investment Value"?: number;
    "Opening Cash"?: number;
    "Cash In": number;
}

export interface CashflowChartOut {
    name: "Out";
    "Closing Investment Value": number;
    "Closing Cash": number;
    "Cash Out": number;
    "Income Payout": number;
}

export type CashflowChartData = [CashflowChartIn, CashflowChartOut];