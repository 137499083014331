export function Equity(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g id="Group_228841" data-name="Group 228841" transform="translate(-11489 8928)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(11489 -8928)" fill="#fff" opacity="0" />
        <g id="Group_228830" data-name="Group 228830" transform="translate(11476.229 -8925.016)">
          <path id="Path_272243" data-name="Path 272243" d="M41.49,2a.49.49,0,0,0-.49.49v9.792a.49.49,0,0,0,.49.49h9.278a.49.49,0,0,0,.489-.464l.009-.149c.008-.121.016-.243.016-.367A9.8,9.8,0,0,0,41.49,2Zm.49,9.792v-8.8a8.824,8.824,0,0,1,8.323,8.8Z" transform="translate(-11.739)" fill="#babdcc" stroke="#babdcc" stroke-width="0.2" />
          <path id="Path_272244" data-name="Path 272244" d="M18,16.792a9.792,9.792,0,0,0,19.584,0,.49.49,0,0,0-.49-.49H28.282V7.49a.49.49,0,0,0-.49-.49A9.8,9.8,0,0,0,18,16.792Zm9.3-8.8v8.8a.49.49,0,0,0,.49.49h8.8A8.813,8.813,0,1,1,27.3,7.992Z" transform="translate(0 -2.552)" fill="#babdcc" stroke="#babdcc" stroke-width="0.2" />
          <path id="Path_272299" data-name="Path 272299" d="M11.116,7.2H7.8m3.318,1.659H7.8m1.659,3.318L7.8,10.516h.83a1.659,1.659,0,1,0,0-3.318" transform="translate(24.198 -1.442)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </g>
    </svg>
  )
}