import React, { useState, useEffect } from 'react';
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { LeftNavIcon } from '../../assets/icons/LeftNavIcon'
import { Box, Stack } from '@mui/material';
import styles from "./index.module.css";
import { PortfolioIcon } from '../../assets/icons/PortfolioIcon';
import { MProfitIcon } from '../../assets/icons/MprofitIcon';
import useWindowDimensions from '../shared/utilities/windowDimensions';
import CustomTypography from '../shared/customTypography';
import FamilyModal from './familyModal';
import { DownArrow } from '../../assets/icons/DownArrow';
import PortfolioDropdown from './portfolioDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import SkeletonLoader from '../shared/skeleton-loader';
import { useAppSelector } from '../../redux/hooks';
import { SharedConstants, SharedRedux, SharedUtilities } from '@mprofit/shared';
import { OutlinedAssetTypeIcons, SolidAssetTypeIcons } from '../../assets/icons/assetTypes'
import IndexCards from '../shared/index-card';
import { NavigateToAssetType, NavigateToPortfolio, NavigateToEquityExposure, isDashboardNavigationRoute, NavigateToAssetAllocationCateg } from '../../routes/navigationMethods';
import { PortfolioGroupIcon, PortfolioOverviewIcon } from '../../assets/icons/global';
import SwitchAccountModal from './switchAccountModal';
import routeNames from '../../routes/routeNames';
import { PortfolioGroup } from '../../assets/icons/PortfolioGroup';
import TooltipDark from '../shared/tooltip-dark';

import TabsButton from '../shared/tabs-button';


const assetsBottomList = [
  // { name: "Reports", key: "reports", outlinedIcon: <ReportsIcon color="#acc18a" />, solidIcon: <ReportsIcon color="#acc18a" /> },
  { name: "Equity Exposure", key: "equityExposure", outlinedIcon: <OutlinedAssetTypeIcons.EQ />, solidIcon: <SolidAssetTypeIcons.EQ /> },
];

interface IconProps {
  [key: number]: JSX.Element;
}

const outlinedAssetIcons: IconProps = {
  [SharedConstants.AssetTypeEnum.EQ]: <OutlinedAssetTypeIcons.EQ />,
  [SharedConstants.AssetTypeEnum.ULIP]: <OutlinedAssetTypeIcons.ULIP />,
  [SharedConstants.AssetTypeEnum.MFEQ]: <OutlinedAssetTypeIcons.MFEQ />,
  [SharedConstants.AssetTypeEnum.INS]: <OutlinedAssetTypeIcons.INS />,
  [SharedConstants.AssetTypeEnum.MFD]: <OutlinedAssetTypeIcons.MFD />,
  [SharedConstants.AssetTypeEnum.PE]: <OutlinedAssetTypeIcons.PE />,
  [SharedConstants.AssetTypeEnum.FD]: <OutlinedAssetTypeIcons.FD />,
  [SharedConstants.AssetTypeEnum.BND]: <OutlinedAssetTypeIcons.BND />,
  [SharedConstants.AssetTypeEnum.NCD]: <OutlinedAssetTypeIcons.NCD />,
  [SharedConstants.AssetTypeEnum.CD]: <OutlinedAssetTypeIcons.CD />,
  [SharedConstants.AssetTypeEnum.PPF]: <OutlinedAssetTypeIcons.PPF />,
  [SharedConstants.AssetTypeEnum.PO]: <OutlinedAssetTypeIcons.PO />,
  [SharedConstants.AssetTypeEnum.GLD]: <OutlinedAssetTypeIcons.GLD />,
  [SharedConstants.AssetTypeEnum.SLV]: <OutlinedAssetTypeIcons.SLV />,
  [SharedConstants.AssetTypeEnum.JWL]: <OutlinedAssetTypeIcons.JWL />,
  [SharedConstants.AssetTypeEnum.PR]: <OutlinedAssetTypeIcons.PR />,
  [SharedConstants.AssetTypeEnum.ART]: <OutlinedAssetTypeIcons.ART />,
  [SharedConstants.AssetTypeEnum.MIS]: <OutlinedAssetTypeIcons.MIS />,
  [SharedConstants.AssetTypeEnum.LN]: <OutlinedAssetTypeIcons.LN />,
  [SharedConstants.AssetTypeEnum.BANKS]: <OutlinedAssetTypeIcons.BANKS />,

  //Category
  [SharedConstants.AssetAllocationCategID.RealEstate]: <OutlinedAssetTypeIcons.RealEstate />,
  [SharedConstants.AssetAllocationCategID.Equity]: <OutlinedAssetTypeIcons.Equity />,
  [SharedConstants.AssetAllocationCategID.Debt]: <OutlinedAssetTypeIcons.Debt />,
  [SharedConstants.AssetAllocationCategID.Gold_Plus]: <OutlinedAssetTypeIcons.Gold_Plus />,
  [SharedConstants.AssetAllocationCategID.Retirement]: <OutlinedAssetTypeIcons.Retirement />,
  [SharedConstants.AssetAllocationCategID.Other]: <OutlinedAssetTypeIcons.Other />,
  [SharedConstants.AssetAllocationCategID.International]: <OutlinedAssetTypeIcons.International />,
  [SharedConstants.AssetAllocationCategID.Cash]: <OutlinedAssetTypeIcons.Cash />,

}

const solidAssetIcons: IconProps = {
  [SharedConstants.AssetTypeEnum.EQ]: <SolidAssetTypeIcons.EQ />,
  [SharedConstants.AssetTypeEnum.ULIP]: <SolidAssetTypeIcons.ULIP />,
  [SharedConstants.AssetTypeEnum.MFEQ]: <SolidAssetTypeIcons.MFEQ />,
  [SharedConstants.AssetTypeEnum.INS]: <SolidAssetTypeIcons.INS />,
  [SharedConstants.AssetTypeEnum.MFD]: <SolidAssetTypeIcons.MFD />,
  [SharedConstants.AssetTypeEnum.PE]: <SolidAssetTypeIcons.PE />,
  [SharedConstants.AssetTypeEnum.FD]: <SolidAssetTypeIcons.FD />,
  [SharedConstants.AssetTypeEnum.BND]: <SolidAssetTypeIcons.BND />,
  [SharedConstants.AssetTypeEnum.NCD]: <SolidAssetTypeIcons.NCD />,
  [SharedConstants.AssetTypeEnum.CD]: <SolidAssetTypeIcons.CD />,
  [SharedConstants.AssetTypeEnum.PPF]: <SolidAssetTypeIcons.PPF />,
  [SharedConstants.AssetTypeEnum.PO]: <SolidAssetTypeIcons.PO />,
  [SharedConstants.AssetTypeEnum.GLD]: <SolidAssetTypeIcons.GLD />,
  [SharedConstants.AssetTypeEnum.SLV]: <SolidAssetTypeIcons.SLV />,
  [SharedConstants.AssetTypeEnum.JWL]: <SolidAssetTypeIcons.JWL />,
  [SharedConstants.AssetTypeEnum.PR]: <SolidAssetTypeIcons.PR />,
  [SharedConstants.AssetTypeEnum.ART]: <SolidAssetTypeIcons.ART />,
  [SharedConstants.AssetTypeEnum.MIS]: <SolidAssetTypeIcons.MIS />,
  [SharedConstants.AssetTypeEnum.LN]: <SolidAssetTypeIcons.LN />,
  [SharedConstants.AssetTypeEnum.BANKS]: <SolidAssetTypeIcons.BANKS />,
  //Category
  [SharedConstants.AssetAllocationCategID.RealEstate]: <SolidAssetTypeIcons.RealEstate />,
  [SharedConstants.AssetAllocationCategID.Equity]: <SolidAssetTypeIcons.Equity />,
  [SharedConstants.AssetAllocationCategID.Debt]: <SolidAssetTypeIcons.Debt />,
  [SharedConstants.AssetAllocationCategID.Gold_Plus]: <SolidAssetTypeIcons.Gold_Plus />,
  [SharedConstants.AssetAllocationCategID.Retirement]: <SolidAssetTypeIcons.Retirement />,
  [SharedConstants.AssetAllocationCategID.Other]: <SolidAssetTypeIcons.Other />,
  [SharedConstants.AssetAllocationCategID.International]: <SolidAssetTypeIcons.International />,
  [SharedConstants.AssetAllocationCategID.Cash]: <SolidAssetTypeIcons.Cash />,
}

export const ConditionalTooltip: React.FC<{
  show: boolean;
  title: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactElement;
}> = ({ show, title, children, placement }) => {
  if (!show) return children;
  return (
    <TooltipDark title={title} placement={placement}>
      {children}
    </TooltipDark>
  );
};

const LeftNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const portfolioSubPath = location.pathname.split("/")[2];
  let isReport = portfolioSubPath === "reports";
  let isDashboardPage = isDashboardNavigationRoute(portfolioSubPath)
  let isSummaryPage = portfolioSubPath === "summary";

  const theme = useTheme();
  const lgBreakpointValue = theme.breakpoints.values.lg1;

  const { width } = useWindowDimensions();
  const drawerWidth = width >= lgBreakpointValue ? '320px' : '235px';

  const [showPortfolioDropdown, setShowPortfolioDropdown] = useState<boolean>(false);
  const [isFamilyModalOpen, setIsFamilyModalOpen] = useState<boolean>(false);
  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = useState<boolean>(false);
  const isPortfoliosLoading = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsPortfoliosLoading);
  const assetTypeList = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveAssetTypesList);
  const activeAssetType = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetTypeIfNotAAA);
  const activeAssetAllocationCategID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategID);

  const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);
  const activePortfolio = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolio);
  const activeScreen = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreen);

  var activeBottomItemKey: 'equityExposure' | 'reports' | undefined = undefined;
  if (activeScreen === SharedConstants.ScreenEnum.L2_AllStocks || activeScreen === SharedConstants.ScreenEnum.L2_AllStocks_AA) {
    activeBottomItemKey = 'equityExposure';
  } else if (isReport) {
    activeBottomItemKey = 'reports';
  } else {
    activeBottomItemKey = undefined;
  }

  const isPortfolioOverviewPage = isDashboardPage && (activeAssetType == undefined || activeAssetType <= 0) && (activeAssetAllocationCategID == undefined || activeAssetAllocationCategID <= 0) && activeBottomItemKey === undefined;

  const [isLeftNavOpen, setIsLeftNavOpen] = useState(!isSummaryPage && width >= lgBreakpointValue);

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    backgroundColor: '#010006',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
      width: '4px',
      marginRight: '10px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#BABDCC',
      borderRadius: '16px',
      opacity: '0.4',
      marginRight: '10px'
    }
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#010006',
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
      width: '5px',
    },
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      height: '100%',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );


  const handleClick = () => {
    setShowPortfolioDropdown(!showPortfolioDropdown);
  };

  const handleDrawer = () => {
    setShowPortfolioDropdown(false);
    setIsLeftNavOpen(!isLeftNavOpen);
  };

  const handleDrawerForPortfolioMenu = () => {
    setShowPortfolioDropdown(false);
    setIsLeftNavOpen(false);
  };

  React.useEffect(() => {
    if (isSummaryPage || width < lgBreakpointValue) {
      setIsLeftNavOpen(false);
      setShowPortfolioDropdown(false);
    } else {
      setIsLeftNavOpen(true);
    }
  }, [width])

  // const handleReport = (val: string) => {
  //   if (val === "Reports") {
  //     navigate({ pathname: '/portfolio/reports/explore', search: location.search });
  //   }
  // }

  const clickBottomItem = (key: string) => {
    switch (key) {
      case 'reports':
        break;
      case 'equityExposure':
        NavigateToEquityExposure({ navigate, activeScreenIDs, pathname: location.pathname });
        break;
    }
  }

  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onSelectAssetType = (assetType: { AssetTypeID: number, AssetTypeName: string }) => {
    NavigateToAssetType({ navigate, pathname: isDashboardPage ? location.pathname : '/portfolio/today', activeScreenIDs, assetTypeID: assetType.AssetTypeID, removeAssetAllocationCategID: true });
  }

  const onSelectCategory = (item: SharedConstants.PieChartData) => {
    NavigateToAssetAllocationCateg({ navigate, activeScreenIDs, assetAllocationCategID: item.IDs.AssetAllocationCategID, pathname: isDashboardPage ? location.pathname : '/portfolio/today' });
  }

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: SharedConstants.HoldingsMode) => {
    setActiveTabIndex(newValue);
  };

  // Reorder: asset class first, then categories
  const tabs = [
    {
      id: SharedConstants.HoldingsMode.MProfitClassification,
      name: "Asset Class"
    },
    {
      id: SharedConstants.HoldingsMode.AssetAllocation,
      name: "Category"
    }
  ];

  const { PieChartData } = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL1PieChartData_ByAssetAllocation(state, 'CurrValue'));

  useEffect(() => {
    if ((activeAssetAllocationCategID || 0) > 0 && (activeAssetType || 0) <= 0) {
      setActiveTabIndex(1);
    } else {
      setActiveTabIndex(0);
    }
  }, [activeAssetType, activeAssetAllocationCategID])

  return (
    <>
      <FamilyModal isModalOpen={isFamilyModalOpen} setIsModalOpen={setIsFamilyModalOpen} />
      <SwitchAccountModal isModalOpen={isSwitchAccountModalOpen} setIsModalOpen={setIsSwitchAccountModalOpen} onSwitchAccount={() => { setShowPortfolioDropdown(false) }} />
      <Drawer variant="permanent" open={isLeftNavOpen} anchor='left' >
        <DrawerHeader sx={{ padding: '16px 0px 7px 24px' }}>
          <Box sx={{ width: '100%' }}>
            {isLeftNavOpen && <MProfitIcon />}
          </Box>
          <IconButton onClick={handleDrawer} sx={{ marginRight: isLeftNavOpen ? '0px' : '10px' }}>
            {isLeftNavOpen ?
              <Box style={{ transform: 'rotate(180deg)' }}>
                <LeftNavIcon />
              </Box>
              : <LeftNavIcon />
            }
          </IconButton>
        </DrawerHeader>
        {/* Family */}
        {isLeftNavOpen ?
          <Box
            sx={{
              padding: '0px 12px 0px 12px',
              backgroundColor: '#12131a',
              borderBottom: showPortfolioDropdown ? 'none' : '4px solid #3a3d4d',
              borderRadius: "7px",
              margin: { xs: '0px 8px ', lg1: '0px 24px' },
              cursor: 'pointer'
            }}
            onClick={handleClick}
            className={'apptour-today-portfolioselect'}
          >
            <Box className={styles.select}>
              <ActiveFamilyText />
              <Box sx={{ transform: showPortfolioDropdown ? 'rotate(180deg)' : undefined, height: '40px', cursor: 'pointer' }}>
                <DownArrow />
              </Box>
            </Box>
            <Stack direction="row" alignItems="center" gap={1} paddingBottom={showPortfolioDropdown ? '12px' : '10px'}>
              <ActivePortfolioText showPortfolioDropdown={showPortfolioDropdown} />
              <ActivePortfolioBadge />
            </Stack>
          </Box>
          :
          <ConditionalTooltip show={!isLeftNavOpen} title="Switch Portfolio" placement="right">
            <Box>
              <Box
                sx={{
                  margin: '0px 5px',
                  height: '69px',
                  backgroundColor: '#12131A',
                  borderBottom: '4px solid #3a3d4d',
                  borderRadius: "4px",
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsLeftNavOpen(true);
                  setShowPortfolioDropdown(true);
                }}
              >
                <CustomTypography
                  variant='Regular20'
                  sx={{
                    padding: '16px 0px',
                    color: { xs: '#ffffff' },
                    paddingBottom: showPortfolioDropdown ? '12px' : '10px',
                    textAlign: 'center',
                  }}
                >{activePortfolio ? SharedUtilities.getInitialsFromName(activePortfolio.PFullName || activePortfolio.PName) : ''}</CustomTypography>
              </Box>
            </Box>
          </ConditionalTooltip>
        }
        {showPortfolioDropdown && <PortfolioDropdown setIsFamilyModalOpen={setIsFamilyModalOpen} setIsDropdownOpen={setShowPortfolioDropdown} setIsSwitchAccountModalOpen={setIsSwitchAccountModalOpen} />}
        <List
          sx={{
            padding: { lg1: isLeftNavOpen ? '0px 24px' : '0px', xs: '0px' },
            flex: 1,
            marginTop: '20px',
            overflow: 'auto',
            '::-webkit-scrollbar': {
              width: '0px',
            },
          }}
          className='apptour-leftnav-assettypes'
        >
          <ListItem
            key={'0'}
            disablePadding
            sx={{ display: 'block', height: 'auto', margin: '0px', borderRadius: '5px' }}
          >
            {isPortfoliosLoading ?
              <SkeletonLoader
                sx={{
                  width: { xs: '48px', lg1: isLeftNavOpen ? '90%' : '48px' },
                  height: { xs: '12px', lg1: isLeftNavOpen ? '12px' : '12px' },
                  borderRadius: { xs: '4px', lg1: isLeftNavOpen ? "14px" : "4px" },
                  display: 'flex',
                  margin: { xs: 'auto', lg1: isLeftNavOpen ? '0' : 'auto' },
                }}
              />
              :
              <>
                <ConditionalTooltip show={!isLeftNavOpen} title="Dashboard" placement="right">
                  <Box>
                    <ListItemButton
                      onClick={() => NavigateToPortfolio({ navigate, activePortfolio: activePortfolioIDs, pathname: isDashboardPage ? location.pathname : '/portfolio/today', screen: SharedConstants.ScreenEnum.L1 })}
                      sx={{
                        minHeight: 48,
                        justifyContent: isLeftNavOpen ? 'initial' : 'center',
                        px: 2.5,
                        margin: { xs: '0px 8px', lg1: isLeftNavOpen ? '0px' : '0px 8px' },
                        padding: { xs: '8px 12px', lg1: isLeftNavOpen ? '8px 20px' : '8px 12px' },
                        backgroundColor: isPortfolioOverviewPage ? '#253326' : 'transparent',
                        borderRadius: '5px',
                        '&:hover': {
                          backgroundColor: isPortfolioOverviewPage ? '#253326' : '#12131a',
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isLeftNavOpen ? 2 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {isPortfolioOverviewPage ?
                          <PortfolioIcon />
                          :
                          <PortfolioOverviewIcon />}

                      </ListItemIcon>
                      <ListItemText>
                        <CustomTypography
                          variant='Medium16'
                          sx={{ color: { xs: isPortfolioOverviewPage ? '#D0E3BB' : "#BABDCC" }, opacity: isLeftNavOpen ? 1 : 0, }}
                        >
                          {'Dashboard'}
                        </CustomTypography>
                      </ListItemText>
                    </ListItemButton>
                  </Box>
                </ConditionalTooltip>

                <ConditionalTooltip show={!isLeftNavOpen} title="Portfolio" placement="right">
                  <Box>
                    <ListItemButton
                      onClick={() => {
                        navigate({ pathname: routeNames.SUMMARY, search: location.search });
                        handleDrawerForPortfolioMenu();
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: isLeftNavOpen ? 'initial' : 'center',
                        px: 2.5,
                        margin: { xs: '15px 8px', lg1: isLeftNavOpen ? '16px 0 0 0' : '15px 8px' },
                        padding: { xs: '8px 12px', lg1: isLeftNavOpen ? '8px 20px' : '8px 12px' },
                        backgroundColor: isSummaryPage ? '#253326' : 'transparent',
                        borderRadius: '5px',
                        '&:hover': {
                          backgroundColor: isSummaryPage ? '#253326' : '#12131a',
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isLeftNavOpen ? "16px" : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {isSummaryPage ?
                          <PortfolioGroupIcon />
                          :
                          <PortfolioGroup />}

                      </ListItemIcon>
                      <ListItemText>
                        <CustomTypography
                          variant='Medium16'
                          sx={{ color: { xs: isSummaryPage ? '#D0E3BB' : "#BABDCC" }, opacity: isLeftNavOpen ? 1 : 0, }}
                        >
                          {'Portfolio'}
                        </CustomTypography>
                      </ListItemText>
                    </ListItemButton>
                  </Box>
                </ConditionalTooltip>
              </>
            }
          </ListItem>

          <Divider sx={{ display: { xs: isLeftNavOpen ? "none" : 'block', lg1: 'none' }, background: '#BABDCC', width: '48px', margin: '20px 8px', opacity: '0.4', height: '1px' }} />

          {!isPortfoliosLoading ?
            <>
              <TabsButton
                labels={tabs.map(label => label.name)}
                value={activeTabIndex}
                handleChange={handleTabChange}
                sxProps={{
                  marginTop: '24px',
                  display: { xs: isLeftNavOpen ? "block" : "none" },
                  "& .MuiTab-root": {
                    color: "#828699",
                    borderBottom: 2,
                    borderColor: "rgba(249, 249, 249, 0.1)",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: "#F9F9F9",
                    borderBottom: 2,
                    borderColor: "#F9F9F9",
                  },
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                  },
                }}
                tabSx={{
                  textTransform: "none",
                  fontSize: '16px',
                  fontWeight: '400',
                  "&.MuiButtonBase-root": {
                    width: {
                      xs: '90px',
                      lg1: '136px'
                    },
                  },
                }}
                TabIndicatorProps={{
                  backgroundColor: 'transparent'
                }}
              />
            </>
            :
            <SkeletonLoader
              sx={{
                width: { xs: '48px', lg1: isLeftNavOpen ? '50%' : '48px' },
                height: { xs: '12px', lg1: isLeftNavOpen ? '8px' : '12px' },
                borderRadius: { xs: '4px', lg1: isLeftNavOpen ? "14px" : "4px" },
                display: 'flex',
                margin: { xs: 'auto', lg1: isLeftNavOpen ? '0' : 'auto' },
              }}
            />
          }

          {
            isPortfoliosLoading ?
              [1, 2, 3].map((item, index) => (
                <ListItem key={index} disablePadding
                  sx={{
                    display: 'block',
                    height: '52px',
                    borderRadius: '5px',
                    width: '100%',
                    margin: 'inherit'
                  }}>
                  <SkeletonLoader sx={{
                    width: { xs: '48px', lg1: isLeftNavOpen ? '90%' : '48px' },
                    height: { xs: '12px', lg1: isLeftNavOpen ? '12px' : '12px' },
                    borderRadius: { xs: '4px', lg1: isLeftNavOpen ? "14px" : "4px" },
                    display: 'flex',
                    margin: { xs: 'auto', lg1: isLeftNavOpen ? '0' : 'auto' },
                  }} />
                </ListItem>
              ))
              :
              <>
                {tabs[activeTabIndex].id === SharedConstants.HoldingsMode.AssetAllocation ? PieChartData.map((item, index) => {
                  const isActiveCategoryType = isDashboardPage && item.IDs.AssetAllocationCategID === activeAssetAllocationCategID && activeBottomItemKey === undefined;
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{ display: 'block', height: '52px', borderRadius: '5px', my: '10px', width: '100%' }}
                      onClick={(e) => onSelectCategory(item)}
                    >
                      <ConditionalTooltip show={!isLeftNavOpen} title={item.name} placement="right">
                        <Box>
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: isLeftNavOpen ? 'initial' : 'center',
                              px: 2.5,
                              margin: { xs: '0px 8px', lg1: isLeftNavOpen ? '0px' : '0px 8px' },
                              padding: { xs: '8px', lg1: isLeftNavOpen ? '8px 20px' : '8px' },
                              backgroundColor: isActiveCategoryType ? '#253326' : 'unset',
                              borderRadius: isActiveCategoryType ? '5px' : '0px',
                              '&:hover': {
                                backgroundColor: isActiveCategoryType ? '#253326' : '#12131a',
                                borderRadius: '5px',
                              }
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: 0, mr: isLeftNavOpen ? 3 : 'auto', justifyContent: 'center' }}>
                              {isActiveCategoryType ? solidAssetIcons[item.IDs.AssetAllocationCategID] : outlinedAssetIcons[item.IDs.AssetAllocationCategID]}
                            </ListItemIcon>
                            <ListItemText>
                              <CustomTypography
                                variant='Medium16'
                                sx={{ color: { xs: isActiveCategoryType ? '#D0E3BB' : '#BABDCC' }, display: isLeftNavOpen ? 'block' : 'none' }}
                              >
                                {item.name}
                              </CustomTypography>
                            </ListItemText>
                          </ListItemButton>
                        </Box>
                      </ConditionalTooltip>
                    </ListItem>
                  );
                }) :
                  assetTypeList.map((item, index) => {
                    const isActiveAssetType = isDashboardPage && item.AssetTypeID == activeAssetType && activeBottomItemKey === undefined;
                    return (
                      <ListItem
                        key={index}
                        disablePadding
                        sx={{ display: 'block', height: '52px', borderRadius: '5px', my: '10px', width: '100%' }}
                        onClick={(e) => onSelectAssetType(item)}
                      >
                        <ConditionalTooltip show={!isLeftNavOpen} title={item.AssetTypeName} placement="right">
                          <Box>
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: isLeftNavOpen ? 'initial' : 'center',
                                px: 2.5,
                                margin: { xs: '0px 8px', lg1: isLeftNavOpen ? '0px' : '0px 8px' },
                                padding: { xs: '8px', lg1: isLeftNavOpen ? '8px 20px' : '8px' },
                                backgroundColor: isActiveAssetType ? '#253326' : 'unset',
                                borderRadius: isActiveAssetType ? '5px' : '0px',
                                '&:hover': {
                                  backgroundColor: isActiveAssetType ? '#253326' : '#12131a',
                                  borderRadius: '5px',
                                }
                              }}
                            >
                              <ListItemIcon
                                sx={{ minWidth: 0, mr: isLeftNavOpen ? 3 : 'auto', justifyContent: 'center' }}>
                                {isActiveAssetType ? solidAssetIcons[item.AssetTypeID] : outlinedAssetIcons[item.AssetTypeID]}
                              </ListItemIcon>
                              <ListItemText>
                                <CustomTypography
                                  variant='Medium16'
                                  sx={{ color: { xs: isActiveAssetType ? '#D0E3BB' : '#BABDCC' }, opacity: isLeftNavOpen ? 1 : 0 }}
                                >
                                  {item.AssetTypeName}
                                </CustomTypography>
                              </ListItemText>
                            </ListItemButton>
                          </Box>
                        </ConditionalTooltip>
                      </ListItem>
                    );
                  })}
              </>
          }
        </List>
        <Divider sx={{ backgroundColor: '#3a3d4d', width: '90%', alignSelf: 'center' }} />
        <List
          sx={{
            padding: { lg1: isLeftNavOpen ? '0px 24px' : '0px', xs: '0px' },
            overflow: 'auto',
            '::-webkit-scrollbar': {
              width: '0px',
            },
          }}>
          {assetsBottomList.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: 'block',
                borderRadius: '5px',
                width: '100%',
                my: '10px',
              }}>
              {
                isPortfoliosLoading ?
                  <Box sx={{ padding: "20px 0px" }}>
                    <SkeletonLoader sx={{
                      width: { xs: '48px', lg1: isLeftNavOpen ? '90%' : '48px' },
                      height: { xs: '12px', lg1: isLeftNavOpen ? '12px' : '12px' },
                      borderRadius: { xs: '4px', lg1: isLeftNavOpen ? "14px" : "4px" },
                      display: 'flex',
                      margin: { xs: 'auto', lg1: isLeftNavOpen ? '0' : 'auto' },
                    }} />
                  </Box>
                  :
                  <ConditionalTooltip show={!isLeftNavOpen} title={item.name} placement="right">
                    <Box>
                      <ListItemButton
                        onClick={() => clickBottomItem(item.key)}
                        sx={{
                          minHeight: 48,
                          px: 2.5,
                          justifyContent: isLeftNavOpen ? 'initial' : 'center',
                          margin: { xs: '0px 8px', lg1: isLeftNavOpen ? '0px' : '0px 8px' },
                          padding: { xs: '8px', lg1: isLeftNavOpen ? '8px 20px' : '8px' },
                          backgroundColor: activeBottomItemKey === item.key ? '#253326' : "none",
                          borderRadius: '4px',
                          '&:hover': {
                            backgroundColor: '#253326',
                            borderRadius: '5px',
                          }
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: isLeftNavOpen ? 3 : 'auto', justifyContent: 'center' }}>
                          {activeBottomItemKey === item.key ? item.solidIcon : item.outlinedIcon}
                        </ListItemIcon>
                        <ListItemText>
                          <CustomTypography variant='Medium16' sx={{ color: { xs: activeBottomItemKey === item.key ? '#D0E3BB' : '#BABDCC' }, opacity: isLeftNavOpen ? 1 : 0, }}>{item.name}</CustomTypography>
                        </ListItemText>
                      </ListItemButton>
                    </Box>
                  </ConditionalTooltip>
              }
            </ListItem>
          ))}
        </List>
        <Divider sx={{ backgroundColor: '#3a3d4d', width: '90%', alignSelf: 'center' }} />
        {
          isPortfoliosLoading && isLeftNavOpen ?
            <Box sx={{ height: '100px', margin: { xs: '24px 8px', lg1: '24px' } }}>
              {/* <SkeletonLoader width={'100%'} height={'100%'} variant="rectangular" /> */}
              <SkeletonLoader
                variant="rectangular"
                sx={{
                  width: { xs: '48px', lg1: '100%' },
                  height: { xs: '75px', lg1: '100%' },
                  borderRadius: { xs: '4px', lg1: "14px" },
                  display: 'flex',
                  margin: { xs: 'auto', lg1: '0' },
                }} />
            </Box>
            :
            <Box sx={{ display: width < lgBreakpointValue ? "none" : { md: "none", lg1: isLeftNavOpen ? "block" : "none" } }}>
              <IndexCards />
            </Box>
        }
        {
          isPortfoliosLoading &&
          <Box sx={{ margin: { xs: '24px 8px', lg1: '30px 26px' }, borderRadius: '12px', height: '162px' }}>
            <SkeletonLoader
              variant="rectangular"
              sx={{
                width: { xs: '48px', lg1: '100%' },
                height: { xs: '100px', lg1: '100%' },
                borderRadius: { xs: '4px', lg1: "14px" },
                display: 'flex',
                margin: { xs: 'auto', lg1: '0' },
              }} />
          </Box>
        }
      </Drawer>
    </>
  );
}

const ActiveFamilyText = () => {
  const activeFamily = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveFamily);
  return (
    <CustomTypography variant='Regular16' sx={{ color: { xs: '#EBECF2' } }}>
      {!activeFamily ? <SkeletonLoader width={70} height={8} /> : SharedUtilities.truncateTextWithEllipsis(activeFamily.FamilyName, 20)}
    </CustomTypography>
  )
}

const ActivePortfolioText = ({ showPortfolioDropdown }: { showPortfolioDropdown: boolean }) => {
  const activePortfolio = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolio);
  return (
    <CustomTypography
      variant='Regular24'
      sx={{ color: { xs: '#ffffff' }, paddingLeft: '10px', }}>
      {!activePortfolio ? <SkeletonLoader height={13} /> : SharedUtilities.truncateTextWithEllipsis(activePortfolio.PFullName || activePortfolio.PName, 15)}
    </CustomTypography>
  )
}

const ActivePortfolioBadge = () => {
  const IsCashMgmtPortfolio = useAppSelector(SharedRedux.Licensing.Selectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck);

  const getBadgeText = () => {
    if (IsCashMgmtPortfolio) {
      return 'PMS';
    }

    return '';
  }

  const badgeText = getBadgeText();

  if (badgeText) {
    return (
      <CustomTypography
        variant='Medium14'
        sx={{ color: '#E2DA90', borderWidth: 1, borderColor: '#E2DA90', borderStyle: 'solid', borderRadius: '4px', padding: '2px 6px' }}>
        {badgeText}
      </CustomTypography>
    )
  }

  return null;
}

export default LeftNav;