import { ColumnDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter, TablePriceFieldFormatter } from '../shared/virtual-table-component';
import { SharedConstants, SharedRedux } from '@mprofit/shared';
import ExpandableVirtualizedTable, { RowDataProps } from '../shared/expandable-table';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Box, Button, Radio, TableCell, TableRow } from '@mui/material';
import { TableSearchBar } from '../shared/table-search-bar';
import { useEffect, useMemo, useState } from 'react';
import { MPrUrlParams } from '../../classes/MPrUrlParams';
import { AmountWithTooltip } from '../shared/numberWithTooltip';
import Big from 'big.js';
import { CustomTypography } from '../shared';
import ButtonPrimaryCTA from '../shared/button-primary-cta';
import { InfoIcon } from '../../assets/icons/InfoIcon';
import styled from '@emotion/styled';
import { GreenArrowUp } from '../../assets/icons/GreeArrowUp';
import { ReactComponent as RedArrowDown } from '../../assets/icons/redArrowDown.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import routeNames from '../../routes/routeNames';
import styles from "../shared/asset-allocation-pie-chart/pie-chart-left-legend/index.module.css";
import { SettingIcon } from '../../assets/icons/settingIcon';
import { NumberPercent } from '../shared/numberPercent';
import SkeletonLoader from '../shared/skeleton-loader';
import { TableCellContentProps } from '../transaction';
import { NavigateToAsset } from 'packages/web/src/routes/navigationMethods';
import { ArrowDown } from '../../assets/icons/ArrowDown';
import { UserProfileKeys } from 'packages/shared/src/constants';
import { PreferenceModal } from './preferenceModal';
import debounce from 'lodash.debounce';

export interface PrimaryRowCellProps {
  column: ColumnDataProps;
  row: RowDataProps;
}

const commonTableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'Quant',
    HeaderName: "QTY",
    SubHeaderName: "AVG. PUR. PRICE",
    ColumnWidthPercent: '16%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'PxPur',
        FieldFormatter: TableDecimalFieldFormatter,
      }
    ],
    // HeaderRowCell
  },
  {
    FieldKey: "InvAmt",
    HeaderName: "AMOUNT\nINVESTED",
    // ColumnWidthPercent: "15%",
    FieldFormatter: TableDecimalFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: 'PxCurr',
    HeaderName: "CURRENT\nPRICE",
    // ColumnWidthPercent: '13%',
    FieldFormatter: TablePriceFieldFormatter,
    Align: 'right'
  },
  {
    FieldKey: 'TGain',
    HeaderName: "TODAY'S\nGAIN",
    // ColumnWidthPercent: '13%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'TGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'TUp'
      }
    ]
  },
  {
    FieldKey: "OGain",
    HeaderName: "UNREALISED\nGAIN",
    // ColumnWidthPercent: "15%",
    FieldFormatter: TableDecimalFieldFormatter,
    Align: "right",
    subColumns: [
      {
        FieldKey: "OGainPct",
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: "OUp",
      },
    ],
  },
  {
    FieldKey: "CurrValue",
    HeaderName: "CURRENT\nVALUE",
    // ColumnWidthPercent: "15%",
    FieldFormatter: TableDecimalFieldFormatter,
    Align: "right",
  }
];

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: "AssetTypeName",
    HeaderName: "ASSETS",
    FieldFormatter: TableDefaultFieldFormatter,
    IsRowName: true,
    ColumnWidthPercent: "250px",
    Align: "left",
    // PrimaryRowCell,
    // SecondaryRowCell,
    // TertiaryRowCell
  },
  ...commonTableColumns,
];

interface FinancialDataDisplayProps {
  label: string;
  value: string | number | Big | undefined;
}

interface CustomRadioProps {
  label: string;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FinancialDataDisplay: React.FC<FinancialDataDisplayProps> = ({ label, value }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
      <Box sx={{ minWidth: "130px" }}>
        <CustomTypography variant="Regular16" sx={{ color: "#64677A", lineHeight: "24px" }}>{label}</CustomTypography>
        <CustomTypography variant="Regular20" sx={{ color: "#12131A", lineHeight: "30px" }}>
          <AmountWithTooltip Value={value} />
        </CustomTypography>
      </Box>
    </Box>
  );
};

export const CustomRadio: React.FC<CustomRadioProps> = ({ label, checked, onChange }) => {
  return (
    <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <Radio
        sx={{
          color: "#BABDCC",
          "&.Mui-checked": {
            color: "#5F854C",
          },
        }}
        checked={checked}
        onChange={onChange}
      />
      <CustomTypography variant="Regular18" color="#12131A">
        {label}
      </CustomTypography>
    </label>
  );
};

export const GainLossCont = styled(Box)({
  display: 'flex',
  width: 'fit-content',
  alignItems: 'center',
  borderRadius: '6px',
  padding: '6px 7px',
})

const skeletonLoaderStyles = {
  'AssetTypeName': {
    height: '16px',
    width: '197px',
    position: 'flex-start'
  }
}

const skeletonLoaderStylesCommon = {
  'AssetTypeName': {
    height: '20px',
    width: '250px',
    position: 'flex-start'
  }
}

function Summary(props: { MPrUrlParams: MPrUrlParams }) {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const searchTermFromConfig = useAppSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(SharedConstants.TableType.SummaryScreen))?.searchTerm || ''; 

  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(searchTermFromConfig);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  
  const isCashMgmtPortfolio = useAppSelector(SharedRedux.Licensing.Selectors.selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck);
  const activePortfolio = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolio);
  const userProfile = useAppSelector(SharedRedux.Dashboard.Selectors.selectUserProfile);

  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);
  // Keep track of the actual applied settings
  const [userPreferences, setUserPreferences] = useState({
    defaultSort: 'Amount Invested',
    sortDirection: 'Ascending',
    separator: userProfile.Separator!,
    showZeroValues: userProfile.ShowZeroValues!,
    showDecimals: userProfile.ShowDecimals!,
  });
  // Temporary settings for the modal
  const [draftPreferences, setDraftPreferences] = useState({...userPreferences});

  const closePreferencesModal = () => {
    // Reset temp settings to applied settings when closing
    setDraftPreferences({...userPreferences});
    setIsPreferencesModalOpen(false);
  };

  const updatePreference = (key: string, value: any) => {
    setDraftPreferences(prevSettings => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  const submitPreferences = () => {
    // Create an array of changed preferences by comparing draft vs current profile
    const changedPreferences = Object.entries({
      ShowZeroValues: draftPreferences.showZeroValues,
      ShowDecimals: draftPreferences.showDecimals,
      Separator: draftPreferences.separator
    })
    .filter(([key, value]) => value !== userProfile[key as keyof typeof userProfile])
    .map(([key, value]) => ({
      Key: key as UserProfileKeys,
      Value: value
    }));
  
    // Only dispatch if there are changes
    if (changedPreferences.length > 0) {
      dispatch(SharedRedux.Dashboard.Actions.addUpdateUserProfileItem({
        Item: changedPreferences
      }));
    }
  
    setUserPreferences({...draftPreferences});
    closePreferencesModal();
  };

  useEffect(() => {
    setUserPreferences(prevSettings => ({
      ...prevSettings,
      separator: userProfile.Separator,
      showZeroValues: userProfile.ShowZeroValues,
      showDecimals: userProfile.ShowDecimals,
    }));
    setDraftPreferences(prevSettings => ({
      ...prevSettings,
      separator: userProfile.Separator,
      showZeroValues: userProfile.ShowZeroValues,
      showDecimals: userProfile.ShowDecimals,
    }));
  }, [userProfile]);

  useEffect(() => {
    const debouncedFn = debounce((term: string) => {
      setDebouncedSearchTerm(term);
    }, 300);

    debouncedFn(searchTerm);
    
    return () => {
      debouncedFn.cancel();
    };
  }, [searchTerm]);

  const preferences = useMemo(() => ({
    defaultSort: userPreferences.defaultSort,
    sortDirection: userPreferences.sortDirection,
    separator: userPreferences.separator,
    showZeroValues: userPreferences.showZeroValues,
    showDecimals: userPreferences.showDecimals,
    searchTerm: debouncedSearchTerm
  }), [
    userPreferences.defaultSort,
    userPreferences.sortDirection,
    userPreferences.separator,
    userPreferences.showZeroValues,
    userPreferences.showDecimals,
    debouncedSearchTerm
  ]);

const tableData = useAppSelector(state => 
    SharedRedux.PortfolioSummary.Selectors.selectFilteredAssetsWithPreferences(
        state, 
        preferences
    )
);

  console.log("🚀 ~ Summary ~ tableData:", tableData)
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActivePortfolioSummaryTotal);
  const cashMgmtTotals = useAppSelector(SharedRedux.Performance.Selectors.selectCashMgmtTotals);
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  useEffect(() => {
    if (isCashMgmtPortfolio) {
      if (tableData && totalValues && cashMgmtTotals?.CashMgmtTotals) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } else {
      if (tableData && totalValues) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [tableData, totalValues, cashMgmtTotals, isCashMgmtPortfolio]);

  useEffect(() => {
    dispatch(SharedRedux.Performance.Actions.refreshCashMgmtTotals({}));
  }, [activePortfolio]);

  const footerContent = ({ excludeLoadingFields = [], skeletonLoaderStyles = {} }: TableCellContentProps) => {
    return (
      <TableRow sx={{ height: "100px", boxShadow: 5, verticalAlign: "top" }}>
        <TableCell sx={{ borderStyle: "none", width: "10px", background: "white", pointerEvents: "none" }} />
        <TableCell sx={{ borderStyle: "none", background: "white" }} />
        {tableColumns.map((column) => (
          <TableCell
            key={column.FieldKey}
            align={column.Align}
            variant="head"
            sx={{
              width: column.ColumnWidthPercent,
              maxWidth: column.ColumnWidthPercent,
              minWidth: "130px",
              background: "white",
            }}
          >
            {isLoading && !excludeLoadingFields.includes(column.FieldKey) ? (
              <Box sx={{ marginLeft: column.FieldKey === "AssetTypeName" ? "-78px" : "0px" }}>
                <SkeletonLoader {...(skeletonLoaderStyles[column.FieldKey] || { height: "12px", width: "86px" })} animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8' }} sxContainer={{ display: "flex", justifyContent: skeletonLoaderStyles[column.FieldKey]?.position || "flex-end" }} />
              </Box>
            ) : (
              <>
                {column.FieldKey === "AssetTypeName" &&
                  <>
                    {isCashMgmtPortfolio ?
                      (<Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column", marginLeft: "-78px" }}>
                        <CustomTypography variant="Medium20" sx={{ marginBottom: "12px" }}>
                          Get your performance snapshot
                        </CustomTypography>
                        <ButtonPrimaryCTA
                          buttonText="Performance Snapshot"
                          maxHeight="48px"
                          handleClick={() => navigate({ pathname: routeNames.PERFORMANCE, search: location.search })}
                        />
                      </Box>) :
                      (<Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "-63px" }}>
                        <CustomTypography variant="Regular18">
                          Net worth
                        </CustomTypography>
                      </Box>)}
                  </>
                }
                {column.FieldKey === 'InvAmt' &&
                  <CustomTypography variant="Regular18">
                    {isCashMgmtPortfolio ?
                      (<FinancialDataDisplay label="Total Cash In" value={cashMgmtTotals?.CashMgmtTotals?.TotalInflow} />) :
                      (<AmountWithTooltip Value={totalValues?.InvAmt} />)}
                  </CustomTypography>
                }
                {isCashMgmtPortfolio && column.FieldKey === 'PxCurr' &&
                  <CustomTypography variant="Regular18">
                    <FinancialDataDisplay label="Total Cash Out" value={cashMgmtTotals?.CashMgmtTotals?.TotalOutflow} />
                  </CustomTypography>
                }
                {column.FieldKey === 'TGain' &&
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {isCashMgmtPortfolio ?
                      (<FinancialDataDisplay label="Cash on Hand" value={cashMgmtTotals?.CashMgmtTotals?.ClosingCashBalance} />) :
                      (<>
                        <CustomTypography variant="Regular18">
                          <AmountWithTooltip Value={totalValues?.TGain} />
                        </CustomTypography>
                        <GainLossCont sx={{ backgroundColor: totalValues?.TUp ? '#EAF4DF' : '#FFF5F5', display: 'inline-flex', marginTop: '4px' }}>
                          {totalValues?.TUp ? <GreenArrowUp /> : <RedArrowDown />}
                          <CustomTypography variant='Medium14' sx={{ color: totalValues?.TUp ? '#5F854C' : '#C94C40' }}>
                            <NumberPercent Value={totalValues?.TGainPct} />
                          </CustomTypography>
                        </GainLossCont>
                      </>)}
                  </Box>
                }
                {column.FieldKey === 'OGain' &&
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    {isCashMgmtPortfolio ?
                      (<FinancialDataDisplay label="Current Value" value={totalValues?.CurrValue} />) :
                      (<><CustomTypography variant="Regular18">
                        <AmountWithTooltip Value={totalValues?.OGain} />
                      </CustomTypography>
                        <GainLossCont sx={{ backgroundColor: totalValues?.OUp ? '#EAF4DF' : '#FFF5F5', display: 'inline-flex', marginTop: '4px' }}>
                          {totalValues?.OUp ? <GreenArrowUp /> : <RedArrowDown />}
                          <CustomTypography variant='Medium14' sx={{ color: totalValues?.OUp ? '#5F854C' : '#C94C40' }}>
                            <NumberPercent Value={totalValues?.OGainPct} />
                          </CustomTypography>
                        </GainLossCont></>)}
                  </Box>
                }
                {column.FieldKey === 'CurrValue' &&
                  <CustomTypography variant="Regular18">
                    {isCashMgmtPortfolio ?
                      (<FinancialDataDisplay label="Total value" value={Big(totalValues?.CurrValue || 0).plus(cashMgmtTotals?.CashMgmtTotals?.ClosingCashBalance || 0)} />) :
                      (<AmountWithTooltip Value={totalValues?.CurrValue} />)}
                  </CustomTypography>
                }
              </>
            )}
          </TableCell>
        ))}
        <TableCell sx={{ borderStyle: "none", width: "50px", background: "white" }} />
        <Box sx={{width: "12px", height: "100px", background: "white"}} />
        <TableCell sx={{ borderStyle: "none", width: "10px", background: "white", pointerEvents: "none" }} />
      </TableRow>
    )
  }

  return (
    <>
    <PreferenceModal 
      isOpen={isPreferencesModalOpen}
      onClose={closePreferencesModal}
      preferences={draftPreferences}
      onPreferenceChange={updatePreference}
      onSubmit={submitPreferences}
    />

    <div className='temp-page'>
      <Box display="flex" justifyContent="space-between" alignItems="center" margin="0 35px 24px 35px">
        <CustomTypography variant="Medium28" sx={{ color: "#12131A" }}>All Assets</CustomTypography>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <Box width="408px">
            <TableSearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              sxProps={{ "& .MuiInputBase-root": { height: 48 } }}
            />
          </Box>
          <Box className={styles.dropDown}>
            <Button 
              sx={{
                background: "#FFFFFF", 
                borderRadius: "4px", 
                border: "1px solid #D8DAE5", 
                padding: "15px 16px", 
                height: "48px", 
                fontSize: "16px", 
                color: "#64677A", 
                textTransform: "none", 
                gap: "8px", 
                fontWeight: "normal" 
              }} 
              startIcon={<SettingIcon />} 
              endIcon={<ArrowDown />} 
              onClick={()=>{ setIsPreferencesModalOpen(true) }}
            >
              Preferences
            </Button>
          </Box>
        </Box>
      </Box>
      <div style={{ margin: "0px", position: "relative" }}>
        <ExpandableVirtualizedTable 
          rows={tableData}
          columns={tableColumns}
          primaryRowId="AssetTypeID"
          secondaryRowId="AMID" 
          headerColor="#EAF4DF" 
          tableHeight={isCashMgmtPortfolio ? 'calc(100vh - (88px + 34px + 48px + 24px))' : 'calc(100vh - (88px + 34px + 48px + 24px))'} 
          sorting={true}
          fieldNamesToSearch={["AssetTypeName", "Name", "PortfolioName"]} 
          searchTerm={debouncedSearchTerm} 
          defaultSortConfig={{ key: 'CurrValue', direction: SharedConstants.SortDirection.Down }} 
          footerContent={() => footerContent({ excludeLoadingFields: ['Quant'], skeletonLoaderStyles: skeletonLoaderStylesCommon }) } 
          excludeLoadingFields={['Quant', 'PxCurr']} 
          skeletonLoaderStyles={skeletonLoaderStyles} 
          isLoading={isLoading}
          onSecondaryRowClick={(row) => {
            // dispatch(SharedRedux.Portfolios.Actions.clearActivePeriod());
            dispatch(SharedRedux.Portfolios.Actions.clearTransactionsAndBalances());
            NavigateToAsset({
                navigate, 
                activeScreenIDs, 
                assetTypeID: row.AssetTypeID, 
                AMID: row.AMID, 
                SID: row.SID, 
                pathname: '/portfolio/summary/transaction', 
                OrigPFID: row.PFID 
            });
        }}
        onTertiaryRowClick={(row) => {
            // dispatch(SharedRedux.Portfolios.Actions.clearActivePeriod());
            dispatch(SharedRedux.Portfolios.Actions.clearTransactionsAndBalances());
            NavigateToAsset({
                navigate, 
                activeScreenIDs, 
                assetTypeID: row.AssetTypeID, 
                AMID: row.AMID, 
                SID: row.SID, 
                pathname: '/portfolio/summary/transaction', 
                OrigPFID: row.PFID 
            });
        }}
        tableType={SharedConstants.TableType.SummaryScreen} 
        />
      </div>
    </div>
    </>
  );
}

export default Summary;
