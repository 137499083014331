export function SLV(props: any) {
  return (
  <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32.001" height="32" viewBox="0 0 32.001 32">
    <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0"/>
    <g id="Group_222246" data-name="Group 222246" transform="translate(-34.137 -500.497)">
      <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(34.137 507.497)" fill="none" stroke="#babdcc" strokeWidth="1" className="icon-by-asset-type-stroke" >
        <rect width="32" height="18" rx="4" stroke="none"/>
        <rect x="0.5" y="0.5" width="31" height="17" rx="3.5" fill="none"/>
      </g>
      <path id="Name" d="M3.852.12A4.639,4.639,0,0,1,1.986-.21a2.731,2.731,0,0,1-1.14-.864A2.019,2.019,0,0,1,.432-2.2.244.244,0,0,1,.5-2.376a.244.244,0,0,1,.18-.072H1.752a.355.355,0,0,1,.234.066.687.687,0,0,1,.138.162,1.215,1.215,0,0,0,.276.45,1.522,1.522,0,0,0,.558.366,2.39,2.39,0,0,0,.894.144,2.325,2.325,0,0,0,1.29-.288.9.9,0,0,0,.426-.78.722.722,0,0,0-.228-.558,2.126,2.126,0,0,0-.7-.384,12.31,12.31,0,0,0-1.254-.366,6.986,6.986,0,0,1-1.506-.522,2.286,2.286,0,0,1-.912-.774A2.131,2.131,0,0,1,.66-6.12a2.1,2.1,0,0,1,.378-1.236,2.535,2.535,0,0,1,1.08-.852A4.1,4.1,0,0,1,3.792-8.52a4.169,4.169,0,0,1,1.38.21,3.044,3.044,0,0,1,1,.552,2.614,2.614,0,0,1,.606.732A1.722,1.722,0,0,1,7-6.264a.263.263,0,0,1-.066.174.23.23,0,0,1-.186.078H5.628a.426.426,0,0,1-.192-.048.353.353,0,0,1-.156-.18,1,1,0,0,0-.468-.636,1.8,1.8,0,0,0-1.02-.264,2,2,0,0,0-1.044.24.809.809,0,0,0-.4.744.807.807,0,0,0,.2.558,1.622,1.622,0,0,0,.636.39,11.944,11.944,0,0,0,1.158.36,8.407,8.407,0,0,1,1.668.528,2.181,2.181,0,0,1,.948.762,2.109,2.109,0,0,1,.3,1.17,2.159,2.159,0,0,1-.426,1.35A2.689,2.689,0,0,1,5.64-.18,4.9,4.9,0,0,1,3.852.12ZM8.928,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.112a.278.278,0,0,1,.084-.2.278.278,0,0,1,.2-.084h1.116a.278.278,0,0,1,.2.084.278.278,0,0,1,.084.2v6.684h3.8a.292.292,0,0,1,.216.084.292.292,0,0,1,.084.216v.84a.278.278,0,0,1-.084.2A.292.292,0,0,1,14.136,0Zm8,0a.436.436,0,0,1-.3-.1.545.545,0,0,1-.156-.24l-2.568-7.7a.3.3,0,0,1-.024-.108.244.244,0,0,1,.072-.18.244.244,0,0,1,.18-.072h1.056a.349.349,0,0,1,.258.09.419.419,0,0,1,.114.174l2.028,6.18,2.028-6.18a.419.419,0,0,1,.114-.174.349.349,0,0,1,.258-.09h1.056a.251.251,0,0,1,.174.072.235.235,0,0,1,.078.18.3.3,0,0,1-.024.108l-2.568,7.7a.545.545,0,0,1-.156.24.436.436,0,0,1-.3.1Z" transform="translate(39.705 521.016)" fill="#babdcc" className="icon-by-asset-type-fill" />
    </g>
  </svg>
)}
