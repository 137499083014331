import { SortDirection } from "./constants";

export interface SortConfig {
    key: string;
    direction: SortDirection;
    isRowName?: boolean;
};

export const DefaultSortingForHoldingTable: SortConfig = {
    key: 'HoldingPct',
    direction: SortDirection.Down
};

export const DefaultSortingForTodayTable: SortConfig = {
    key: 'CurrValue',
    direction: SortDirection.Down
};

export const DefaultSortingForPerformanceTable: SortConfig = {
    key: 'XIRR',
    direction: SortDirection.Down
};

export const DefaultSortingForTransactionTable: SortConfig = {
    key: 'DT',
    direction: SortDirection.Down
};

export const DefaultSortingForL2AllStocksEquityExposure: SortConfig = {
    key: 'HoldingPct',
    direction: SortDirection.Down
};

export const DefaultSortingForL2AllStocksAAEquityExposure: SortConfig = {
    key: 'Value',
    direction: SortDirection.Down
};
