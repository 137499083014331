export function SettingIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_150698" data-name="Group 150698" transform="translate(-8.225)">
        <rect id="Rectangle_24197" data-name="Rectangle 24197" width="24" height="24" transform="translate(8.225)" fill="#fff" opacity="0"/>
        <path id="_63155da3952f1ef24445d8a07c4673f9" data-name="63155da3952f1ef24445d8a07c4673f9" d="M4.815,18.8a.444.444,0,0,1-.47-.47V8.273a.444.444,0,0,1,.47-.47.444.444,0,0,1,.47.47V18.333A.471.471,0,0,1,4.815,18.8Zm.47-16.17V.47A.471.471,0,0,0,4.815,0a.444.444,0,0,0-.47.47V2.632A2.16,2.16,0,0,0,2.7,4.7a2.115,2.115,0,0,0,4.231,0A2.16,2.16,0,0,0,5.285,2.632Zm-.47,3.243A1.175,1.175,0,1,1,5.99,4.7,1.164,1.164,0,0,1,4.815,5.876Zm12.692-.893V.47a.471.471,0,0,0-.47-.47.444.444,0,0,0-.47.47V4.983a2.16,2.16,0,0,0-1.645,2.068,2.115,2.115,0,1,0,4.231,0A2.16,2.16,0,0,0,17.507,4.983Zm-.47,3.243a1.175,1.175,0,1,1,1.175-1.175A1.164,1.164,0,0,1,17.037,8.226Zm0,10.577a.444.444,0,0,1-.47-.47V10.671a.47.47,0,0,1,.94,0v7.662A.471.471,0,0,1,17.037,18.8Zm-6.111-6.816A2.107,2.107,0,0,0,8.811,14.1a2.16,2.16,0,0,0,1.645,2.068v2.162a.444.444,0,0,0,.47.47.471.471,0,0,0,.47-.47V16.17A2.16,2.16,0,0,0,13.041,14.1a2.107,2.107,0,0,0-2.115-2.115Zm0,3.29A1.175,1.175,0,1,1,12.1,14.1,1.164,1.164,0,0,1,10.926,15.277Zm0-4.137a.444.444,0,0,1-.47-.47V.47a.47.47,0,1,1,.94,0v10.2A.506.506,0,0,1,10.926,11.141Z" transform="translate(9.412 2.586)" fill="#9a9eb2"/>
      </g>
    </svg>
  )
}
