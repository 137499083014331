
export function PortfolioGroup(props: any) {
  return (
    <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0"/>
      <g id="Group_229463" data-name="Group 229463" transform="translate(-1547 4643)">
        <path id="Path_272390" data-name="Path 272390" d="M4,11H8a1,1,0,0,1,1,1v8a1,1,0,0,1-1,1H6a3,3,0,0,1-3-2.824L3,18V12A1,1,0,0,1,4,11Z" transform="translate(1551 -4639)" fill="#babdcc"/>
        <path id="Path_272391" data-name="Path 272391" d="M21,12v6a3,3,0,0,1-2.824,3L18,21H12a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1Z" transform="translate(1551 -4639)" fill="#babdcc"/>
        <path id="Path_272392" data-name="Path 272392" d="M18,3a3,3,0,0,1,3,2.824L21,6V8a1,1,0,0,1-1,1H12a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1h6Z" transform="translate(1551 -4639)" fill="#babdcc"/>
        <path id="Path_272393" data-name="Path 272393" d="M9,4V8A1,1,0,0,1,8,9H4A1,1,0,0,1,3,8V6A3,3,0,0,1,5.824,3L6,3H8A1,1,0,0,1,9,4Z" transform="translate(1551 -4639)" fill="#babdcc"/>
      </g>
    </svg>
  );
}

