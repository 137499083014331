export function EQ(props: any) {
  return (
  <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0" />
  <g id="Group_150728" data-name="Group 150728" transform="translate(0.999 -2)">
    <g id="trending-up-outline" transform="translate(8.223 20.358)">
      <path id="Path_198278" data-name="Path 198278" d="M352,144h4.187v4.187" transform="translate(-340.634 -144)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
      <path id="Path_198279" data-name="Path 198279" d="M48,167.776l4.538-4.538a1.2,1.2,0,0,1,1.692,0l1.9,1.9a1.2,1.2,0,0,0,1.692,0L62.955,160" transform="translate(-48 -159.402)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
    </g>
    <g id="np_stocks_2397585_000000" transform="translate(3.5 3.499)">
      <path id="Path_198227" data-name="Path 198227" d="M38.187,21.246h.834v.895a.447.447,0,0,0,.895,0v-.895h.834a.4.4,0,0,0,.386-.386V15.53a.386.386,0,0,0-.386-.386h-.834v-.9a.447.447,0,0,0-.895,0v.895h-.834a.4.4,0,0,0-.386.386V20.88a.369.369,0,0,0,.386.366Z" transform="translate(-28.119 -8.205)" fill="#babdcc" className="icon-by-asset-type-fill" />
      <path id="Path_198228" data-name="Path 198228" d="M3.721,40.064a.447.447,0,1,0,.895,0V39.17H5.45a.4.4,0,0,0,.386-.386V33.432a.386.386,0,0,0-.386-.386H4.615v-.895a.447.447,0,1,0-.895,0v.895H2.886a.386.386,0,0,0-.386.386v5.351a.386.386,0,0,0,.386.386h.834Z" transform="translate(0 -22.465)" fill="#babdcc" className="icon-by-asset-type-fill" />
      <path id="Path_198229" data-name="Path 198229" d="M73.488,10.947h.834v.895a.447.447,0,0,0,.895,0v-.895h.834a.4.4,0,0,0,.386-.386V5.23a.386.386,0,0,0-.386-.386h-.834v-.9a.447.447,0,0,0-.895,0v.895h-.834a.386.386,0,0,0-.386.386v5.351a.369.369,0,0,0,.386.366Z" transform="translate(-56.238)" fill="#babdcc" className="icon-by-asset-type-fill" />
    </g>
  </g>
  </svg>
)}
