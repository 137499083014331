import { Box, TableCell } from "@mui/material";
import { ColumnDataProps, RowDataProps } from "../virtual-table-component";
import CustomTypography from "../customTypography";
import { TableIconUp } from "packages/web/src/assets/icons/TableIconUp";
import { TableIconDown } from "packages/web/src/assets/icons/TableIconDown";
import PMSBadge from "../pms-badge";
import { isZeroOrPositive } from '../../../../../shared/src/utilities';
import { getColor, RowLevelCellProps } from "./index";

export const TertiaryRowCell = ({ columns, row, isFirstRow, isLastRow, isLastRowOfPrimary, onRowClick, PMSBadgeField, isHovered, isExpanded, EndingCell, cellRadius }: { columns: ColumnDataProps[], row: RowDataProps, isFirstRow: boolean, isLastRow: boolean, isLastRowOfPrimary: boolean, onRowClick?: (row: RowDataProps) => void, PMSBadgeField?: string, isHovered: boolean, isExpanded?: boolean, EndingCell?: RowLevelCellProps, cellRadius?: number }) => {
    return (
        <>
            {
                columns.map((column, index) => {
                    var showRowNameClass = column.IsRowName;
                    const isFirstCol = index === 0;
                    const isLastCol = index === columns.length - 1;
                    if (column.subColumns) {
                        return column.subColumns.map((subColumn) => (
                            <TableCell
                                className={`${showRowNameClass ? "IsRowName" : ""} ${"IsHovered"}`}
                                key={subColumn.FieldKey}
                                align={column.Align}
                                sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, ...getTertiaryRowColStyle({ isLastRow, isFirstCol, isLastCol: false, cellRadius }), backgroundColor: "#F9F9F9" }}>
                                {column.TertiaryRowCell ? column.TertiaryRowCell({column, row}) : (
                                <>
                                <CustomTypography variant='Regular18'>{column.FieldFormatter(row[column.FieldKey])}</CustomTypography>
                                {row[subColumn.FieldKey] ?
                                    <Box>
                                        {(subColumn.UpFieldKey ? row[subColumn.UpFieldKey] === true : isZeroOrPositive(row[subColumn.FieldKey])) ?
                                            <CustomTypography sx={{ color: !subColumn.UpFieldKey ? 'rgba(18, 19, 26, 0.6)' : "#5F854C", alignItems: 'center', gap: '4px' }} >
                                                {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                                                {subColumn.UpFieldKey ? <TableIconUp /> : null}
                                            </CustomTypography>
                                            :
                                            <CustomTypography sx={{ color: !subColumn.UpFieldKey ? 'rgba(18, 19, 26, 0.6)' : '#C94C40', alignItems: 'center', gap: '4px' }} >
                                                {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                                                {subColumn.UpFieldKey ? <TableIconDown /> : null}
                                            </CustomTypography>
                                        }
                                    </Box>
                                    :
                                    null
                                }
                                </>
                                )}
                            </TableCell >
                        ))
                    }
                    else {
                        return (
                            <TableCell className={`${showRowNameClass ? "IsRowName" : ""} ${"IsHovered"}`} key={column.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, ...getTertiaryRowColStyle({ isLastRow, isFirstCol, isLastCol: false  }), backgroundColor: "#F9F9F9", cellRadius }}>
                                {column.TertiaryRowCell ? column.TertiaryRowCell({column, row}) : (
                                <>
                                <Box display="flex" justifyContent={column.Align} alignItems="center">
                                    <Box sx={{ width: "22px", height: "22px", marginRight: "12.28px" }} />
                                    {isFirstCol && (
                                        <Box sx={{ background: getColor(row?.PFID || 0), width: "32px", height:"32px", borderRadius: "50%", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-6px" }}>
                                            <CustomTypography variant='Regular14' textAlign="center">
                                                {getInitials(row[column.FieldKey === "AssetTypeName" ? "PortfolioName" : column.FieldKey])}
                                            </CustomTypography>
                                        </Box>
                                    )}
                                    <CustomTypography variant='Regular18' >{column.FieldFormatter(row[column.FieldKey === "AssetTypeName" ? "PortfolioName" : column.FieldKey])}</CustomTypography>
                                    {
                                        column.IsRowName && PMSBadgeField && row[PMSBadgeField] ? <Box ml={1}><PMSBadge badgeStyles={{ color: '#000', borderColor: '#000', backgroundColor: '#E2DA90', padding: '1px 6px' }} /></Box> : null
                                    }
                                </Box>
                                </>
                                )}
                            </TableCell >
                        )
                    }
                })
            }
            {EndingCell ? 
            EndingCell.TertiaryRowCell : 
            <>
            <TableCell className="IsHovered" sx={{ width: "50px", ...getTertiaryRowColStyle({ isLastRow, isFirstCol: false, isLastCol: true }), backgroundColor: "#F9F9F9", cellRadius }}>
            </TableCell>
            <Box sx={{ marginRight: "12px" }} />
            </>
            }
        </>
    );
};

export const getTertiaryRowColStyle = ({ isFirstRow, isLastRow, isFirstCol, isLastCol, cellRadius }: any) => {

    if (isFirstRow && isLastRow) {
        if (isFirstCol) {
            return {
                borderTopLeftRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none solid solid",
                borderBottomLeftRadius: cellRadius ? cellRadius : 15,
            }
        }

        if (isLastCol) {
            return {
                borderTopRightRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid solid none",
                borderBottomRightRadius: cellRadius ? cellRadius : 15,
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none solid none",
        }
    }

    if (isFirstRow) {
        if (isFirstCol) {
            return {
                borderTopLeftRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none none solid",
            }
        }

        if (isLastCol) {
            return {
                borderTopRightRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid none none",
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none none none",
        }
    }

    if (isLastRow) {
        if (isFirstCol) {
            return {
                borderBottomLeftRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid none solid solid",
            }
        }

        if (isLastCol) {
            return {
                borderBottomRightRadius: cellRadius ? cellRadius : 15,
                border: "solid 1px #EBECF2",
                borderStyle: "solid solid solid none",
            }
        }

        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none solid none",
        }
    }

    if (isFirstCol) {
        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid none none solid",
        }
    }

    if (isLastCol) {
        return {
            border: "solid 1px #EBECF2",
            borderStyle: "solid solid none none",
        }
    }

    return {
        border: "solid 1px #EBECF2",
        borderStyle: "solid none none none",
    }
}

const getInitials = (name: string) => {
    return name.split(' ').map(word => word[0]).join('').toUpperCase();
};
