export enum ScreenEnum {
    UNDEEFINED = 0,
    L1_Group = 5,
    L1 = 10,
    L1_AA = 15,
    L2 = 20,
    L2_AllStocks = 21,
    L2_AllStocks_AA = 22,
    L3 = 30,
    L3_Category = 31,
    L4 = 40,
    L4_RefNo = 45
}

export enum DashboardTabs {
    TODAY = 1,
    HOLDING = 2,
    PERFORMANCE = 3
}