import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CustomTypography from '../customTypography';

interface DropdownButtonProps {
  IconLeft?: JSX.Element;
  IconRight?: JSX.Element;
  text: string;
  onButtonClick: () => void;
}

export const DropdownButton: FC<DropdownButtonProps> = ({
    IconLeft,
    IconRight,
    text,
    onButtonClick
}) => {
  return (
    <Button
      variant="contained"
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      startIcon={IconLeft}
      endIcon={IconRight}
      sx={{
        background: '#fff',
        height: '48px',
        border: '1px solid #D8DAE5',
        borderRadius: '4px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#f9f9f9'
        }
      }}
      onClick={onButtonClick}
    >
      <Box display="flex" alignItems="center">
        <CustomTypography variant="Regular16" sx={{color: '#64677A'}}>{text}</CustomTypography>
      </Box>
    </Button>
  );
};