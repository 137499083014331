export function JWL(props: any) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="Group_150667" data-name="Group 150667" transform="translate(-35 -318)">
      <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0"/>
      <g id="diamond-outline" transform="translate(8.999 277)">
        <path id="Path_198276" data-name="Path 198276" d="M32.154,54.259l9.275,12.03a.722.722,0,0,0,1.146,0l9.275-12.03a.737.737,0,0,0,.042-.837l-3.146-5.078A.724.724,0,0,0,48.13,48H35.873a.724.724,0,0,0-.616.344l-3.146,5.078A.737.737,0,0,0,32.154,54.259Zm.562-.545H51.287" transform="translate(0 0)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
        <path id="Path_198277" data-name="Path 198277" d="M124.857,48.714l-2.143,5L118.429,48M112,48.714l2.143,5L118.429,48m0,17.857-4.286-12.143m4.286,12.143,4.286-12.143" transform="translate(-76.427 0)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" className="icon-by-asset-type-stroke" />
      </g>
    </g>
  </svg>
)}
