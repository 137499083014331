import { combineReducers } from "redux";

import { SharedRedux } from '@mprofit/shared';
import reportsReducer from "./modules/reportsReducer";
import authReducer from "./modules/authReducer";
import tutorialReducer from "./modules/tutorialReducer";

// import catsReducer from './modules/tempReducer';

const sharedReducer = SharedRedux.Reducers;

const appReducer = combineReducers({
    shared: sharedReducer,
    reports: reportsReducer,
    auth: authReducer,
    tutorial: tutorialReducer,
});

const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
