import { IconButton, InputAdornment, SxProps, TextField } from "@mui/material"
import { SearchIcon } from "../../../assets/icons/global"
import { Dispatch, SetStateAction } from "react";

interface TableSearchBarProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  sxProps?: SxProps;
}

export const TableSearchBar = ({ searchTerm, setSearchTerm, sxProps}: TableSearchBarProps) => {
  return (
    <TextField sx={{ border: "none", outline: "none", backgroundColor: '#ffffff', padding: 0, margin: 0, width: '100%', "& .MuiInputBase-root": { height: 40, paddingLeft: 0 }, ...sxProps }}
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ margin: 0 }}>
            <IconButton disableRipple>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}