export function PPF(props: any) {
  return (
  <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32.001" height="32" viewBox="0 0 32.001 32">
    <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0"/>
    <g id="Group_222246" data-name="Group 222246" transform="translate(-34.137 -500.497)">
      <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(34.137 507.497)" fill="none" stroke="#babdcc" strokeWidth="1" className="icon-by-asset-type-stroke" >
        <rect width="32" height="18" rx="4" stroke="none"/>
        <rect x="0.5" y="0.5" width="31" height="17" rx="3.5" fill="none"/>
      </g>
      <path id="Generated_a_sustaine" data-name="Generated a sustaine" d="M1.224,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.1a.3.3,0,0,1,.078-.216.273.273,0,0,1,.21-.084H4.44a3.478,3.478,0,0,1,2.268.678,2.418,2.418,0,0,1,.828,1.986,2.391,2.391,0,0,1-.822,1.974A3.516,3.516,0,0,1,4.44-3.1H2.652V-.288a.289.289,0,0,1-.078.2A.273.273,0,0,1,2.364,0ZM4.38-4.428a1.559,1.559,0,0,0,1.068-.33,1.239,1.239,0,0,0,.372-.978,1.309,1.309,0,0,0-.36-.978,1.482,1.482,0,0,0-1.08-.354H2.628v2.64ZM9.132,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.1a.3.3,0,0,1,.078-.216.273.273,0,0,1,.21-.084h3.216a3.478,3.478,0,0,1,2.268.678,2.418,2.418,0,0,1,.828,1.986,2.391,2.391,0,0,1-.822,1.974,3.516,3.516,0,0,1-2.274.666H10.56V-.288a.289.289,0,0,1-.078.2.273.273,0,0,1-.21.084Zm3.156-4.428a1.559,1.559,0,0,0,1.068-.33,1.239,1.239,0,0,0,.372-.978,1.309,1.309,0,0,0-.36-.978,1.482,1.482,0,0,0-1.08-.354H10.536v2.64ZM17.04,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.1a.3.3,0,0,1,.078-.216.273.273,0,0,1,.21-.084h5.208a.292.292,0,0,1,.216.084.292.292,0,0,1,.084.216v.84a.273.273,0,0,1-.084.21.3.3,0,0,1-.216.078H18.4v2.28h3.612a.292.292,0,0,1,.216.084.292.292,0,0,1,.084.216v.84a.273.273,0,0,1-.084.21.3.3,0,0,1-.216.078H18.4V-.288a.273.273,0,0,1-.084.21A.3.3,0,0,1,18.1,0Z" transform="translate(38.396 520.697)" fill="#babdcc" className="icon-by-asset-type-fill" />
    </g>
  </svg>
)}
