import { Box, Button, Divider, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
  usePrevious,
} from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import {
  NavigateOnLogout,
  NavigateToPortfolio,
} from "packages/web/src/routes/navigationMethods";
import { SetAPIResponseInterceptors } from "packages/shared/src/api";
import { CustomTypography } from "../../shared";
import BreadCrumbComponent from "../../shared/breadcrumbs";
import { ArrowDown } from "../../../assets/icons/ArrowDown";
import ButtonWithTextAndIcon from "../../shared/button-with-label-and-icon";
import ButtonPrimaryCTA from "../../shared/button-primary-cta";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { DocumentIcon } from "../../../assets/icons/documentIcon";
import SharedVirtualizedTable, {
  ColumnDataProps,
  TableDefaultFieldFormatter,
} from "../../shared/virtual-table-component";
import ButtonText from "../../shared/button-text";
import { RowElements } from "../tradeDetails";
import { DashboardItemContainer } from "../../portfolio/shared";

const data2 = [
  { type: "Date", value: "10 Jun, 2023" },
  { type: "Broker", value: "1234.00" },
  { type: "Cntr. Note", value: "1234.00" },
  { type: "Settlement Number", value: "1234.00" },
];
const data3 = [
  { type: "Date", value: "10 Jun, 2023" },
  { type: "Broker", value: "1234.00" },
  { type: "Cntr. Note", value: "1234.00" },
];

export default function ContractNoteDetails(props: {
  MPrUrlParams?: MPrUrlParams;
  isPlaceholder?: boolean;
}) {
  const getTableHeight = (dataArray: any): string => {
    const baseHeight = 120; // Base height for 1 item in pixels
    const incrementHeight = 60; // Incremental height for each additional item
    const maxRows = 8; // Maximum number of rows
    const dataCount = dataArray.length;

    // Calculate height based on count
    let tableHeight;

    if (dataCount === 1) {
      tableHeight = baseHeight;
    } else if (dataCount > 1 && dataCount <= maxRows) {
      tableHeight = baseHeight + (dataCount - 1) * incrementHeight;
    } else {
      tableHeight = baseHeight + (maxRows - 1) * incrementHeight; // Max height for more than 8 items
    }

    return `${tableHeight}px`;
  };

  return (
    <Grid
      container
      sx={style.layout}
    >
      <Grid
        item
        xs={12}
      >
        <Grid
          item
          mx={"32px"}
        >
          <BreadCrumbComponent />
        </Grid>
        <Grid
          item
          sx={{
            flex: "1 1 100%",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            margin: "0px 40px",
            borderRadius: "8px",
            minHeight: "80vh",
          }}
        >
          <Box
            sx={{
              borderRadius: "8px",
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "#ffffff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "32px 40px",
              }}
            >
              <CustomTypography variant="Medium24">
                Contract Note Details
              </CustomTypography>
              <ButtonText
                handleClick={() => {}}
                buttonText="Edit details"
              />
            </Box>
            <Divider />
          </Box>

          <Box
            sx={{
              padding: "40px",
            }}
          >
            <Box>
              <CustomTypography variant="Medium20">
                General Details
              </CustomTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: "32px",
              }}
            >
              <RowElements data={data2} />
            </Box>
            <Divider sx={{ marginY: "48px" }} />
            <Box sx={{}}>
              <Box>
                <CustomTypography variant="Medium20">
                  Purchase Details
                </CustomTypography>
              </Box>
              <Grid
                item
                my={"24px"}
              >
                <DashboardItemContainer>
                  <SharedVirtualizedTable
                    columns={transactionTableColumns}
                    rows={transactionTableData1}
                    sorting={false}
                    borderRadius="8px"
                    tableHeight={getTableHeight(transactionTableData1)}
                    headerColor="#EAF4DF"
                    onRowClick={() => {}}
                  />
                </DashboardItemContainer>
              </Grid>
            </Box>
            <Divider sx={{ marginY: "48px" }} />
            <Box sx={{}}>
              <Box>
                <CustomTypography variant="Medium20">
                  Sale Details
                </CustomTypography>
              </Box>
              <Grid
                item
                my={"24px"}
              >
                <DashboardItemContainer>
                  <SharedVirtualizedTable
                    columns={transactionTableColumns}
                    rows={transactionTableData3}
                    borderRadius="8px"
                    sorting={false}
                    tableHeight={getTableHeight(transactionTableData3)}
                    headerColor="#EAF4DF"
                    onRowClick={() => {}}
                  />
                </DashboardItemContainer>
              </Grid>
            </Box>
            <Divider sx={{ marginY: "48px" }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Box>
                  <CustomTypography variant="Medium20">
                    Additional Charges
                  </CustomTypography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    mt: "32px",
                  }}
                >
                  <RowElements data={data3} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    mt: "32px",
                  }}
                >
                  <RowElements data={data3} />
                </Box>
              </Box>
              <Divider
                orientation="vertical"
                sx={{ height: "180px", marginX: "52px" }}
              />
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    mt: "42px",
                  }}
                >
                  <Box sx={{ width: "220px" }}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <CustomTypography
                        variant="Regular18"
                        color={"#64677A"}
                      >
                        {"Total Amount"}
                      </CustomTypography>
                      <CustomTypography
                        variant="Medium14"
                        color={"#64677A"}
                        sx={{
                          ml: "8px",
                          border: "1px solid #9A9EB2",
                          borderRadius: "4px",
                          padding: "2px 4px",
                        }}
                      >
                        Receivable
                      </CustomTypography>
                    </Box>
                    <CustomTypography variant="Regular20">
                      {"12345000.00"}
                    </CustomTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const style = {
  layout: {
    display: "flex",
    width: { xs: "900px", sm: "100%" },
  },
  stickyHeader: {
    padding: { xs: "16px 42px", lg: "17px 42px" },
  },
};

// Static data for the table -
const transactionTableColumns: ColumnDataProps[] = [
  {
    FieldKey: "TransactionType",
    HeaderName: "ASSET NAME",
    ColumnWidthPercent: "20%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "left",
    IsRowName: true,
  },
  {
    FieldKey: "Quantity",
    HeaderName: "PURCHASE QUANTITY",
    FooterName: "",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "Quantity",
    HeaderName: "BROKERAGE",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "Price",
    HeaderName: "PURCHASE AMOUNT",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "Brokerage",
    HeaderName: "BROKERAGE",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
];

const transactionTableData1 = [
  {
    AssetAllocationCategID: 1,
    TransactionType: "Opening Balance",
    Date: "24/09/20",
    Quantity: "12194.21",
    Price: "2308455.89",
    Brokerage: "2296261.68",
    Amount: 20,
    Balance: "12194.21",
  },
  // {
  //   AssetAllocationCategID: 100,
  //   TransactionType: "Buy",
  //   Date: "24/09/20",
  //   Quantity: "504.89",
  //   Price: "2308455.89",
  //   Brokerage: "2296261.68",
  //   Amount: 20,
  //   Balance: "12194.21",
  // },
  // {
  //   AssetAllocationCategID: 5,
  //   TransactionType: "Sell",
  //   Date: "24/09/20",
  //   Quantity: "30",
  //   Price: "2000",
  //   Brokerage: "2296261.68",
  //   Amount: 20,
  //   Balance: "12194.21",
  // },
];

const transactionTableData3 = [
  {
    AssetAllocationCategID: 1,
    TransactionType: "Opening Balance",
    Date: "24/09/20",
    Quantity: "12194.21",
    Price: "2308455.89",
    Brokerage: "2296261.68",
    Amount: 20,
    Balance: "12194.21",
  },
  {
    AssetAllocationCategID: 100,
    TransactionType: "Buy",
    Date: "24/09/20",
    Quantity: "504.89",
    Price: "2308455.89",
    Brokerage: "2296261.68",
    Amount: 20,
    Balance: "12194.21",
  },
  {
    AssetAllocationCategID: 5,
    TransactionType: "Sell",
    Date: "24/09/20",
    Quantity: "30",
    Price: "2000",
    Brokerage: "2296261.68",
    Amount: 20,
    Balance: "12194.21",
  },
];
