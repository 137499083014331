import Big from "big.js";
import { TranTypeMap } from "./transactions";

export interface PortfolioListByPType {
    [PType: number]: Portfolio[]
}

export interface Portfolio {
    Id: number,
    FamilyId: number,
    CCID: number,
    PFolioType: number,
    PName: string,
    PFullName: string,
    IsGroup: boolean,
    PMobile: number,
    PAN: string,
    Email: string,
    IsCashMgmtPortfolio: boolean;
    IsStrategyPortfolio: boolean;
    IsGoalPortfolio: boolean;
    FamilyName?: string;
}

export interface PortfoliosByPTypeRaw {
    PList: Portfolio[],
    PType: PFolioTypes
}

export enum PFolioTypes {
    PMS = 0,
    FNO = 5,
    ACT = 10
}

export interface Family {
    FamilyId: number;
    FamilyName: string;
    Category: string;
    CategoryId: number;
    PeriodFrom: string;
    PeriodTo: string;
    CustomerID: number;
    LastPfid: number;
    Read: number;
    Write: number;
    Import: number;
    LockUnlock: number;
}

export interface FamiliesByID {
    ByID: {[ID: number]: Family},
    AllIDs: number[]
}

export interface GlobalSearchResults {
    Family?: Family[],
    Portfolio?: Portfolio[],
    Assets?: AssetSearchItem[]
}

export interface AssetSearchItem {
    Name: string,
    AMID: number,
    AssetTypeID: number,
    AssetTypeName?: string
}

export interface GlobalSearchQuery {
    Term: string,
    Filter: GlobalSearchFilter
}

export enum GlobalSearchFilter {
    ALL = 0,
    FAMILY = 1,
    PORTFOLIO = 2,
    ASSET = 3
}

export interface PortfoliosInGroupByDB {
    [CCID: number]: PortfoliosInGroupByFamily;
}

export interface PortfoliosInGroupByFamily {
    [FamilyId: number]: PortfoliosInGroupByGroupPFID
    
}
export interface PortfoliosInGroupByGroupPFID {
    [PFID: number]: number[];
}

export class Transaction {
    TransID: number;
    DT: Date;
    TranType: number;
    TranTypeStr: string;
    StoredTranTypeStr: string;
    Quant: Big;
    Amount: Big;
    CNID: number;
    NARR: string;
    ChequeRefNum: string;
    Price: Big | undefined;
    Brokerage: Big;
    Quant_Disp: Big;

    constructor(Transaction: RawTransactionFromAPI) {
        this.TransID = Transaction.TransID;
        this.DT = new Date(Transaction.DT);
        this.DT.setHours(0,0,0,0);
        this.TranType = Transaction.TranType;
        this.TranTypeStr = Transaction.TranTypeStr;
        if (this.TranTypeStr) {
            this.TranTypeStr = this.TranTypeStr.replace("*","");
        }
        this.StoredTranTypeStr = Transaction.StoredTranTypeStr;
        this.Quant = new Big(Transaction.Quant);
        if ((this.TranType > TranTypeMap.SELL_DEMARC) && (this.TranType != TranTypeMap.DMRGR_CLOSE) && (this.TranType != TranTypeMap.SPLIT_CLOSE)) {
            this.Quant_Disp = new Big(-1*Transaction.Quant);
        } else {
            this.Quant_Disp = new Big(Transaction.Quant);
        }
        this.Amount = new Big(Transaction.Amount).round(2);
        this.CNID = Transaction.CNID;
        this.NARR = Transaction.NARR || '';
        this.ChequeRefNum = Transaction.ChequeRefNum || '';
        if (Transaction.Price != 'Infinity') {
            this.Price = new Big(Transaction.Price);
        }
        this.Brokerage = new Big(Transaction.Brokerage).round(2);
        if (this.TranType === TranTypeMap.BNK_OPBAL) {
            this.NARR = 'Opening Balance';
        }
    }
}

export interface TransactionBalQuant extends Transaction {
    BalQuant: Big;
    BalAmount: Big;
    Deposit?: Big;
    Withdrawal?: Big;
}

export interface RawTransactionFromAPI {
    TransID: number;
    DT: string;
    TranType: number;
    TranTypeStr: string;
    StoredTranTypeStr: string;
    Quant: number;
    Amount: number;
    CNID: number;
    NARR: string | null;
    Price: number | string;
    Brokerage: number;
    ChequeRefNum: string | null;
}
export interface Balance {
    Quant: number;
    Amount: number;
}
export interface DatabaseItem {
    IsShared: boolean;
    SharedByUserName: string;
    SharedByEmail: string | null;
    CustomerId: string;
    EmailAddress: string;
    IsCloud: boolean;
    DBVersion: number;
    Cloud_DBVersion: number;
    CanAccessAnalyticsForSharedData: boolean;
    CanAccessPerformanceViewForSharedData: boolean;
}