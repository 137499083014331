import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { MProfitAPI, ResponseGenerator } from "../../api";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios, { HttpStatusCode } from "axios";
import { Actions, FetchFamilyPortfolioSummaryPayload, Selectors } from "../modules/portfolioSummaryReducer";
import { Actions as PortfolioActions, Selectors as PortfolioSelectors, SetActivePortfolioPayload } from "../modules/portfoliosReducer";
import { Selectors as LoginSelectors } from "../modules/loginReducer";
import { ActivePortfolioSummary, AssetsAddInfo, Portfolio, PriceValues, StockAddInfoByAMID } from "../../constants";
import { LicensingSelectors } from "../modules/licensing";
const { XMLParser } = require("fast-xml-parser");

const summaryAPI = (payload: FetchFamilyPortfolioSummaryPayload) => {
    return MProfitAPI.get('/api/summary', {
      params: {
        ccid: payload.CCID,
        familyId: payload.FamilyId,
        recalcType: 3
      }
    });
}

const assetsAddInfoAPI = (payload: FetchFamilyPortfolioSummaryPayload) => {
  return MProfitAPI.get('/api/summary/AssetsAddInfo', {
    params: {
      ccid: payload.CCID,
      familyId: payload.FamilyId
    }
  });
}

const MFPortfolioBreakdownAPI = (payload: SetActivePortfolioPayload) => {
  return MProfitAPI.get('/api/summary/MFETFPortfolioBreakdown/v2', {
    params: {
      ccid: payload.CCID,
      familyId: payload.FamilyId,
      pfid: payload.PFID
    }
  });
}

function* onFetchPortfolioSummary(action: PayloadAction<FetchFamilyPortfolioSummaryPayload>) {
    // const response: ResponseGenerator = yield call(summaryAPI, action.payload);
    // console.log('onFetchPortfolioSummary',response);

    if (action.payload.CCID < 0 || action.payload.FamilyId < 0) return;

    const [summaryResponse, assetsAddInfoResponse]: [ResponseGenerator, ResponseGenerator] = yield all([
      call(summaryAPI, action.payload),
      call(assetsAddInfoAPI, action.payload)
    ]);

    if (assetsAddInfoResponse.status == HttpStatusCode.Ok) {
      yield put(Actions.setActiveAssetsAddInfo(assetsAddInfoResponse.data));
    }

    if (summaryResponse.status == HttpStatusCode.Ok) {
      yield put(Actions.setFamilyPortfolioSummary({CCID: action.payload.CCID, FamilyId: action.payload.FamilyId, FamilyPortfolioSummary: summaryResponse.data}));

      const activePortfolioIDs: SetActivePortfolioPayload = yield select(PortfolioSelectors.selectActivePortfolioIDs);

      yield put(Actions.refreshActivePortfolioSummaryOnPortfolioChange(activePortfolioIDs));
    } else {
      // yield put(loginFailure(Login_Status.LOGIN_FAILURE));
    }
}

export const fetchPrices = (_priceDataUrls: {[AssetTypeID: number]: string[]}) => {
  var priceDataUrls: {AssetTypeID: number, URL: string}[] = [];
  for (const assetTypeID in _priceDataUrls) {
    _priceDataUrls[assetTypeID]?.forEach((url) => {
      priceDataUrls.push({
        AssetTypeID: +assetTypeID,
        URL: url
      });
    })
  }

  var priceValues: PriceValues = {};
  priceDataUrls = priceDataUrls || [];
  const xmlParserOptions = {
    ignoreAttributes : false
  };
  const parser = new XMLParser(xmlParserOptions);

  var axiosInstance = axios.create();
  return Promise.all(priceDataUrls.map(urlInfo => Promise.all([urlInfo.AssetTypeID, axiosInstance.get(urlInfo.URL)])))
      .then(priceDataInfo => {
          try {
            priceDataInfo.forEach((data) => {
              var assetTypeID = data[0];
              if (data[1].status != HttpStatusCode.Ok) {
                throw 'error';
              }
              var priceDataRaw: any = parser.parse(data[1].data);
              var priceDataArray = priceDataRaw['PRCS']['AP'];
              if (!priceDataArray.forEach) {
                priceDataArray = [priceDataArray];
              }
              priceDataArray.forEach((asset: any) => {
                priceValues[assetTypeID] = {
                  ...(priceValues[assetTypeID] || {}),
                  [asset['@_ID']]: {
                    CP: ('' + asset['CP']).replace(/\,/g,''),
                    PP: (''+asset['PP']).replace(/\,/g,'')
                  }
                }
              });
            })
          } catch(e) {
              throw(e);
          }
          return priceValues;
      });
}

function* onRefreshPrices(action: PayloadAction) {
  var isFirstTime = true;
  while (true) {
    var isLicenseViolation: boolean | undefined = yield select(LicensingSelectors.selectIsLicenseViolation);

    if (isLicenseViolation === true) break;

    var hasCurrentValueAccess: boolean = yield select(LoginSelectors.selectHasCurrentValueAccess);

    if (hasCurrentValueAccess !== true) break;

    if (isLicenseViolation === false) {
      var priceURLs: {[AssetTypeID: number]: string[]} = yield select(Selectors.selectPriceURLs);

      let priceValues: PriceValues = yield call(fetchPrices,priceURLs);
    
      var activePortfolioSummary: ActivePortfolioSummary = yield select(Selectors.selectActivePortfolioSummary);
    
      yield put(Actions.setPriceValues({PriceValues: priceValues, ActivePortfolioSummary: activePortfolioSummary}));
      
      yield delay(60000);
    } else {
      yield delay(isFirstTime ? 2000 : 10000);
      isFirstTime = false;
    }

  }
}

const portfoliosInGroupAPI = (ccid: number, familyId: number, grpPfid: number) => {
  return MProfitAPI.get('/api/Portfolio/PortfoliosInAGrp', {
      params: {
          ccid,
          familyId,
          grpPfid
      }
  });
}

function* onSetActivePortfolio(action: PayloadAction<SetActivePortfolioPayload>) {
  yield put(Actions.refreshActivePortfolioSummaryOnPortfolioChange(action.payload));
}

function* onRefreshActivePortfolioSummaryOnPortfolioChange(action: PayloadAction<SetActivePortfolioPayload>) {
  const activePortfolio: boolean = yield select(PortfolioSelectors.selectActivePortfolio);
  if (!activePortfolio) return;
  
  const IsGroup: boolean = yield select(PortfolioSelectors.selectIsActivePortfolioAGroup);
  console.log(IsGroup,activePortfolio,'onSetActivePortfolio')

  if (IsGroup) {
    var portfolioInGroup: number[] | undefined = yield select(Selectors.selectPortfoliosInGroupForIDs(action.payload));
    if (!portfolioInGroup) {
      var portfoliosInGroupResponse: ResponseGenerator = yield call(portfoliosInGroupAPI, action.payload.CCID, action.payload.FamilyId, action.payload.PFID);
      if (portfoliosInGroupResponse.status === HttpStatusCode.Ok) {
        yield put(Actions.setPortfoliosInGroup({...action.payload, PortfoliosInGroup: portfoliosInGroupResponse.data?.map((x: Portfolio) => x.Id)}));
      }
    }
  }

  yield put(Actions.setActivePortfolioSummary({...action.payload, IsGroup}));

  yield put(Actions.refreshPrices());
}

function* onRefreshMFETFPortfolioBreakdown(action: PayloadAction<{Force: boolean} | undefined>) {
  var activePortfolio: SetActivePortfolioPayload = yield select(PortfolioSelectors.selectActivePortfolioIDs);
  if (!activePortfolio) return;

  if (!action.payload || !action.payload.Force) {
    var isMFETFPortfolioBreakdownLoading: boolean = yield select(Selectors.selectIsActiveMFETFPortfolioBreakdownLoading);
    if (isMFETFPortfolioBreakdownLoading === false) {
      return;
    }
  }

  const response: ResponseGenerator = yield call(MFPortfolioBreakdownAPI, activePortfolio);

  if (response.status === HttpStatusCode.Ok) {
    yield put(Actions.setMFETFPortfolioBreakdown(response.data));
  }
}

function* portfolioSummarySaga() {
    yield takeLatest(Actions.fetchFamilyPortfolioSummary.type, onFetchPortfolioSummary);
    yield takeLatest(Actions.refreshPrices.type, onRefreshPrices);
    yield takeLatest(PortfolioActions.setActivePortfolio.type, onSetActivePortfolio);
    yield takeLatest(Actions.refreshActivePortfolioSummaryOnPortfolioChange.type, onRefreshActivePortfolioSummaryOnPortfolioChange);
    yield takeLatest(Actions.refreshMFETFPortfolioBreakdown.type, onRefreshMFETFPortfolioBreakdown)
}

export default portfolioSummarySaga;



