export function Other(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g id="Group_228852" data-name="Group 228852" transform="translate(-11489 8928)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(11489 -8928)" fill="#fff" opacity="0" />
        <g id="Group_228850" data-name="Group 228850" transform="translate(11492.5 -8924.5)">
          <circle id="Ellipse_2825" data-name="Ellipse 2825" cx="10.77" cy="10.77" r="10.77" transform="translate(1.5 1.5)" fill="none" stroke="#babdcc" stroke-width="1" />
          <path id="Path_272297" data-name="Path 272297" d="M21.212,18.548v2.789a.539.539,0,0,1-.538.538H17.885a.539.539,0,0,1-.538-.538V18.548a.537.537,0,0,1,.538-.538h2.789A.537.537,0,0,1,21.212,18.548Zm.419-3.076-1.972,1.972a.541.541,0,0,1-.76,0l-1.972-1.972a.541.541,0,0,1,0-.76L18.9,12.741a.534.534,0,0,1,.76,0l1.972,1.972A.534.534,0,0,1,21.631,15.472Zm-5.27,3.076v2.789a.539.539,0,0,1-.538.538H13.035a.539.539,0,0,1-.538-.538V18.548a.537.537,0,0,1,.538-.538h2.789A.537.537,0,0,1,16.361,18.548Zm0-4.85v2.789a.539.539,0,0,1-.538.538H13.035a.539.539,0,0,1-.538-.538V13.7a.537.537,0,0,1,.538-.538h2.789a.537.537,0,0,1,.538.538Z" transform="translate(-4.873 -4.958)" fill="#babdcc" />
        </g>
      </g>
    </svg>
  )
}