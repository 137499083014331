import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AssetAllocationCategID, AssetAllocationCategNameMapping, AssetTypeEnum, AssetTypeNameMapping, FamiliesByID, Family, GlobalSearchFilter, GlobalSearchQuery, GlobalSearchResults, HoldingsMode, PFolioTypes, Portfolio, PortfoliosInGroupByDB, PortfolioListByPType, PortfoliosByPTypeRaw, Transaction, Balance, PeriodInfo, SetPeriodPayload, DatabaseItem, RawTransactionFromAPI } from "../../constants";
import { ClientRootState } from "../reducers";
import { ScreenEnum } from "../../constants/screen";
import { Selectors as LoginSelectors } from './loginReducer';
import { getAssetTypeNameForAA } from "../../utilities/assetTypeNames";
import { Actions as LoginActions } from './loginReducer';
import { getFY } from "../../utilities/shared";
import { getTransactionsForTable } from "../../utilities";
import { formatDateYYYYMMDD } from "../../utilities/dateFormat";

export interface SharedPortfoliosState {
    IsPortfoliosLoading: boolean;
    AllPortfolioListByPType: PortfolioListByPType;
    ActivePortfolioIDs?: SetActivePortfolioPayload;
    PreviousPortfolioIDs?: SetActivePortfolioPayload;
    FamiliesByID?: FamiliesByID;
    ActiveScreenIDs?: ActiveScreenPayload;
    GlobalSearchQuery: GlobalSearchQuery;
    GlobalSearchResults?: GlobalSearchResults;
    Transactions?: RawTransactionFromAPI[];
    OpeningBalances?: Balance;
    ClosingBalances?: Balance;
    ActivePeriod: PeriodInfo;
    Databases: DatabaseItem[];
}

const getCurrentFiscalYearPeriod = (): PeriodInfo => {
    const [start, end] = getFY(new Date());
    return {
        PeriodFrom: formatDateYYYYMMDD(start),
        PeriodTo: formatDateYYYYMMDD(end),
        PeriodText: `FY ${(start.getFullYear() + '').substring(2, 4)} - ${(end.getFullYear() + '').substring(2, 4)}`,
        IsAllToDate: false
    };
};

const initialState: SharedPortfoliosState = {
    IsPortfoliosLoading: true,
    AllPortfolioListByPType: {
        [PFolioTypes.PMS]: [],
        [PFolioTypes.FNO]: [],
        [PFolioTypes.ACT]: []
    },
    GlobalSearchQuery: {
        Term: '',
        Filter: GlobalSearchFilter.ALL
    },
    GlobalSearchResults: undefined,
    Transactions: [],
    OpeningBalances: undefined,
    ClosingBalances: undefined,
    ActivePeriod: getCurrentFiscalYearPeriod(),
    Databases: []
}

const PortfolioSlice = createSlice({
    name: 'portfolios',
    initialState,
    reducers: {
        setPortfolios: (state, action: PayloadAction<SetPortfoliosPayload>) => {
            action.payload.PortfoliosData.forEach((portfolios) => {
                state.AllPortfolioListByPType[portfolios.PType] = portfolios.PList.map(x => ({ ...x, CCID: action.payload.CCID }));
            });

            state.IsPortfoliosLoading = false;
        },
        fetchPortfolios: (state, action: PayloadAction<FetchPortfoliosPayload>) => { },
        setActivePortfolio: (state, action: PayloadAction<SetActivePortfolioPayload>) => {
            state.PreviousPortfolioIDs = state.ActivePortfolioIDs;
            state.ActivePortfolioIDs = action.payload;
        },
        setActiveFamily: (state, action: PayloadAction<SetActiveFamilyPayload>) => {
            // [PFolioTypes.PMS, PFolioTypes.FNO, PFolioTypes.ACT].forEach((pfolioType) => {
            //     state.ActivePortfolioListByPType[pfolioType] = state.AllPortfolioListByPType[pfolioType].filter(x => x.FamilyId == action.payload.FamilyId);
            // })
        },
        setFamilies: (state, action: PayloadAction<SetFamiliesPayload>) => {
            state.FamiliesByID = action.payload.FamiliesByID;
        },
        setActiveScreenIDs: (state, action: PayloadAction<ActiveScreenPayload>) => {
            state.ActiveScreenIDs = action.payload;
        },
        globalSearch: (state, action: PayloadAction<GlobalSearchQuery>) => {
            state.GlobalSearchQuery = action.payload;
        },
        setGlobalSearchResults: (state, action: PayloadAction<GlobalSearchResults>) => {
            state.GlobalSearchResults = action.payload;
        },
        setTransactions: (state, action: PayloadAction<{ transactions: RawTransactionFromAPI[] }>) => {
            state.Transactions = action.payload.transactions;
        },
        setOpeningBalances: (state, action: PayloadAction<{ openingBalances: Balance }>) => {
            state.OpeningBalances = action.payload.openingBalances;
        },
        setClosingBalances: (state, action: PayloadAction<{ closingBalances: Balance }>) => {
            state.ClosingBalances = action.payload.closingBalances;
        },
        fetchTransactions: (state, action: PayloadAction<FetchTransactionsPayload>) => {},
        fetchBalances: (state, action: PayloadAction<FetchBalancesPayload>) => {},
        setActivePeriod: (state, action: PayloadAction<SetPeriodPayload>) => {
            state.ActivePeriod = {
                PeriodFrom: action.payload.PeriodFromValue ? formatDateYYYYMMDD(action.payload.PeriodFromValue) : undefined,
                PeriodTo: action.payload.PeriodToValue ? formatDateYYYYMMDD(action.payload.PeriodToValue) : undefined,
                PeriodText: action.payload.PeriodText,
                IsAllToDate: action.payload.IsAllToDate
            }
        },
        setDatabases: (state, action: PayloadAction<DatabaseItem[]>) => {
            state.Databases = action.payload;
        },
        resetToCurrentFiscalYear: (state) => {
            state.ActivePeriod = getCurrentFiscalYearPeriod();
        },
        clearTransactionsAndBalances: (state) => {
            state.Transactions = [];
            state.OpeningBalances = undefined;
            state.ClosingBalances = undefined;
        },
        clearActivePeriod: (state) => {
            state.ActivePeriod = getCurrentFiscalYearPeriod();
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginActions.logout, () => initialState)
            .addCase(LoginActions.resetData, () => initialState)
    }
});



//#endregion

//#region Payloads

export interface FetchPortfoliosPayload { CCID: number, IsLaunch: boolean, ActivePortfolio?: SetActivePortfolioPayload, OnNavigateToPortfolio?(portfolio: SetActivePortfolioPayload): void }
export interface SetPortfoliosPayload { CCID: number, PortfoliosData: PortfoliosByPTypeRaw[] }
export interface SetFamiliesPayload { FamiliesByID: FamiliesByID }

export interface SetActiveFamilyPayload { CCID: number, FamilyId: number }
export interface SetActivePortfolioPayload { CCID: number, FamilyId: number, PFID: number }
export interface FetchTransactionsPayload { CCID: number, FamilyId: number, PFID: number, SID: number }
export interface FetchBalancesPayload { CCID: number, FamilyId: number, PFID: number, SID: number, Amid: number, Atyp: number, IsClosing: boolean }

export interface ActiveScreenPayload {
    Screen?: ScreenEnum;
    DatabaseID?: number;
    FamilyID?: number;
    PortfolioID?: number;
    AssetType?: number;
    AMID?: number;
    SID?: number;
    SectorID?: number;
    MarketCapID?: number;
    L3Mode?: HoldingsMode,
    AssetAllocationCategID?: AssetAllocationCategID;
    SEBICategoryID?: number;
    SEBISubCategoryID?: number;
    OrigPFID?: number;
}

//#endregion

//#region Selectors

const portfoliosSelector = (state: ClientRootState) => state.shared.portfolios;
const selectAllPortfoliosListByPType = (state: ClientRootState) => portfoliosSelector(state).AllPortfolioListByPType;
const selectActivePortfolioIDs = (state: ClientRootState) => portfoliosSelector(state).ActivePortfolioIDs;
const selectPreviousPortfolioIDs = (state: ClientRootState) => portfoliosSelector(state).PreviousPortfolioIDs;
const selectTransactions = (state: ClientRootState) => portfoliosSelector(state).Transactions;
const selectOpeningBalances = (state: ClientRootState) => portfoliosSelector(state).OpeningBalances;
const selectClosingBalances = (state: ClientRootState) => portfoliosSelector(state).ClosingBalances;

const selectActivePortfoliosListByPType = createSelector([selectAllPortfoliosListByPType, selectActivePortfolioIDs], (allPortfolioListByPType, activePortfolioIDs) => {
    var activePortfolioListByPType: PortfolioListByPType = {
        [PFolioTypes.PMS]: [],
        [PFolioTypes.FNO]: [],
        [PFolioTypes.ACT]: []
    };

    if (activePortfolioIDs?.FamilyId == undefined || activePortfolioIDs?.FamilyId <= 0) return activePortfolioListByPType;

    [PFolioTypes.PMS, PFolioTypes.FNO, PFolioTypes.ACT].forEach((pfolioType) => {
        activePortfolioListByPType[pfolioType] = allPortfolioListByPType[pfolioType].filter(x => x.FamilyId == activePortfolioIDs.FamilyId);
    });

    return activePortfolioListByPType;
})

// const selectActivePortfoliosListByPType = (state: ClientRootState) => portfoliosSelector(state).ActivePortfolioListByPType;
const selectActivePortfoliosList = (state: ClientRootState, pfolioType: PFolioTypes) => selectActivePortfoliosListByPType(state)[pfolioType];
const selectIsPortfoliosLoading = (state: ClientRootState) => portfoliosSelector(state).IsPortfoliosLoading;
const selectDatabases = (state: ClientRootState) => portfoliosSelector(state).Databases;
const selectHasSharedData = createSelector(
    [selectDatabases],
    (databases) => databases.some(db => db.IsShared)
);

const selectActiveMainPortfoliosList = createSelector([selectActivePortfoliosList], list => list.filter(x => !x.IsGroup && !x.IsStrategyPortfolio && !x.IsGoalPortfolio));
const selectActiveGroupsList = createSelector([selectActivePortfoliosList], list => list.filter(x => x.IsGroup));
const selectActiveStrategyGoalPortfoliosList = createSelector([selectActivePortfoliosList], list => list.filter(x => x.IsStrategyPortfolio || x.IsGoalPortfolio));

const selectActiveScreenIDs = (state: ClientRootState) => portfoliosSelector(state).ActiveScreenIDs;
const selectActiveCCID = createSelector([selectActiveScreenIDs, LoginSelectors.selectUserCCID],
    (activeScreenIDs, userCCID) => activeScreenIDs && activeScreenIDs.DatabaseID && activeScreenIDs.DatabaseID > 0 ? activeScreenIDs.DatabaseID : userCCID
);
const selectActiveDatabase = createSelector(
    [selectDatabases, selectActiveCCID],
    (databases, activeCCID) => {
        if (!databases || !activeCCID) return undefined;
        return databases.find(db => Number(db.CustomerId) === activeCCID);
    }
);

const selectIsActiveDatabaseShared = createSelector(
    [selectActiveDatabase],
    (activeDatabase) => {
        return activeDatabase?.IsShared === true;
    }
);

const selectActivePortfolio = createSelector([selectActivePortfoliosListByPType, selectActivePortfolioIDs], (activePortfolioListByPType, activePortfolioIDs) => {
    if (activePortfolioListByPType == null || activePortfolioIDs == null) return null;

    for (var key in activePortfolioListByPType) {
        var activePortfoliosList = activePortfolioListByPType[key];
        var activePortfolio = activePortfoliosList.find(x => x.FamilyId == activePortfolioIDs.FamilyId && x.Id == activePortfolioIDs.PFID);
        if (activePortfolio) return activePortfolio;
    }

    return null;
});

const selectIsActivePortfolioAGroup = (state: ClientRootState) => selectActivePortfolio(state)?.IsGroup === true;

const selectActivePortfolioShortName = (state: ClientRootState) => selectActivePortfolio(state)?.PName;

export const selectIsActivePortfolioCashMgmtPortfolio = (state: ClientRootState) => selectActivePortfolio(state)?.IsCashMgmtPortfolio;

const selectActivePortfolio_INV = createSelector([state => selectActivePortfoliosList(state, PFolioTypes.PMS), selectActivePortfolioIDs], (activePortfoliosList, activePortfolioIDs) => {
    if (activePortfoliosList == null || activePortfolioIDs == null) return null;

    return activePortfoliosList.find(x => x.FamilyId == activePortfolioIDs.FamilyId && x.Id == activePortfolioIDs.PFID);
});

const selectFamiliesByID = (state: ClientRootState) => portfoliosSelector(state).FamiliesByID;
// const selectActiveFamilyIDs = (state: ClientRootState) => portfoliosSelector(state).ActiveFamilyIDs;
const selectActiveFamily = createSelector([selectFamiliesByID, selectActivePortfolioIDs], (familiesByID, activePortfolioIDs) => {
    if (!familiesByID || !activePortfolioIDs || activePortfolioIDs.FamilyId <= 0) return undefined;

    return familiesByID?.ByID[activePortfolioIDs.FamilyId];
});

const selectFamilyList = createSelector([selectFamiliesByID], (familiesByID) => {
    return familiesByID ? familiesByID.AllIDs.map(ID => familiesByID.ByID[ID]) : [];
});

const selectActiveAssetType = createSelector([selectActiveScreenIDs], (activeScreenIDs) => activeScreenIDs?.AssetType);
const selectActiveAMIDSID = createSelector([selectActiveScreenIDs], (activeScreenIDs) => ({ AMID: activeScreenIDs?.AMID, SID: activeScreenIDs?.SID }));
const selectActiveScreen = createSelector([selectActiveScreenIDs], (activeScreenIDs) => activeScreenIDs?.Screen);
const selectActiveAssetAllocationCategID = createSelector([selectActiveScreenIDs], (activeScreenIDs) => activeScreenIDs?.AssetAllocationCategID);
const selectActiveAssetAllocationCategName = createSelector([selectActiveAssetAllocationCategID], (assetAllocationCategID) => assetAllocationCategID && assetAllocationCategID > 0 ? AssetAllocationCategNameMapping[assetAllocationCategID] : undefined);
const selectActiveAssetTypeIfNotAAA = createSelector([selectActiveScreenIDs, selectActiveAssetAllocationCategID], (activeScreenIDs, assetAllocationCategID) => {
    return assetAllocationCategID && assetAllocationCategID > 0 ? undefined : activeScreenIDs?.AssetType;
});

const selectAllPortfoliosListForPType = (state: ClientRootState, pfolioType: PFolioTypes) => portfoliosSelector(state).AllPortfolioListByPType[pfolioType];

const selectPortfolioListByFamily = createSelector(
    [(state, family: Family | undefined) => selectAllPortfoliosListForPType(state, PFolioTypes.PMS), selectActiveCCID, (state, family: Family | undefined) => family],
    (allPortfolioListByPType, CCID, family) => {
        if (!allPortfolioListByPType || !family) return [];

        return allPortfolioListByPType.filter(x => x.FamilyId == family.FamilyId && x.CCID == CCID);
    }
);


const selectGlobalSearchQuery = (state: ClientRootState) => portfoliosSelector(state).GlobalSearchQuery;
const selectGlobalSearchResults = (state: ClientRootState) => portfoliosSelector(state).GlobalSearchResults;

const selectActiveAssetTypeOrNegative = (state: ClientRootState) => selectActiveAssetType(state) || -1;

const selectIsAssetTypeWithQuant = (state: ClientRootState) => selectActiveAssetTypeOrNegative(state) <= AssetTypeEnum.ASSET_WITH_QUANT;
const selectIsAssetTypeStocks = (state: ClientRootState) => selectActiveAssetTypeOrNegative(state) == AssetTypeEnum.EQ;
const selectIsAssetTypeWithRefNum = (state: ClientRootState) => selectActiveAssetTypeOrNegative(state) > AssetTypeEnum.EQ;

const selectActiveAssetTypeIDName = (state: ClientRootState) => {
    var assetTypeID: AssetTypeEnum | undefined = selectActiveAssetType(state);
    var assetAllocationCategID: AssetAllocationCategID | undefined = selectActiveAssetAllocationCategID(state);
    var assetTypeName = assetTypeID ? getAssetTypeNameForAA(assetTypeID, assetAllocationCategID) : '';
    return { AssetTypeID: assetTypeID, AssetTypeName: assetTypeName };
}

const selectIsActiveAssetTypeTradedBonds = (state: ClientRootState) => selectActiveAssetTypeOrNegative(state) === AssetTypeEnum.BND;

const selectActivePortfolioTypeName = (state: ClientRootState) => {
    var activePortfolio = selectActivePortfolio(state);
    if (activePortfolio) {
        if (activePortfolio.IsGroup) {
            return 'Group';
        } else if (activePortfolio.IsGoalPortfolio) {
            return 'Goal Portfolio';
        } else if (activePortfolio.IsStrategyPortfolio) {
            return 'Strategy Portfolio';
        }
    }

    return 'Portfolio';
}

const selectActivePeriod = (state: ClientRootState) => portfoliosSelector(state).ActivePeriod;

const selectPortfolioById = createSelector(
    [selectAllPortfoliosListByPType],
    (allPortfolioListByPType) => {
        const portfolioIdToObjectMapping: { [key: number]: Portfolio } = {};
        for (const pfolioType in allPortfolioListByPType) {
            allPortfolioListByPType[pfolioType].forEach(portfolio => {
                portfolioIdToObjectMapping[portfolio.Id] = portfolio;
            });
        }
        return portfolioIdToObjectMapping;
    }
);

//#endregion

export default PortfolioSlice.reducer;
export const Actions = { ...PortfolioSlice.actions };
export const Selectors = {
    portfoliosSelector,
    selectActivePortfolioIDs,
    selectPreviousPortfolioIDs,
    selectActivePortfoliosList,
    selectAllPortfoliosListForPType,
    selectActiveMainPortfoliosList,
    selectActiveGroupsList,
    selectActiveStrategyGoalPortfoliosList,
    selectIsPortfoliosLoading,
    selectActivePortfolio,
    selectIsActivePortfolioAGroup,
    selectActivePortfolioShortName,
    selectActivePortfolioTypeName,
    selectActivePortfolio_INV,
    selectFamiliesByID,
    selectFamilyList,
    // selectActiveFamilyIDs,
    selectActiveFamily,
    selectActiveScreenIDs,
    selectActiveCCID,
    selectDatabases,
    selectHasSharedData,
    selectActiveDatabase,
    selectIsActiveDatabaseShared,
    selectActiveAssetType,
    selectActiveAssetTypeIfNotAAA,
    selectActiveAMIDSID,
    selectActiveAssetTypeIDName,
    selectIsActiveAssetTypeTradedBonds,
    selectActiveScreen,
    selectActiveAssetAllocationCategID,
    selectActiveAssetAllocationCategName,
    selectPortfolioListByFamily,
    selectActivePortfoliosListByPType,
    selectGlobalSearchQuery,
    selectGlobalSearchResults,
    selectIsAssetTypeWithQuant,
    selectIsAssetTypeStocks,
    selectIsAssetTypeWithRefNum,
    // selectIsActivePortfolioCashMgmtPortfolio_WithLicenseCheck,
    selectTransactions,
    selectOpeningBalances,
    selectClosingBalances,
    selectActivePeriod,
    selectPortfolioById,
};
