export function CD(props: any) {
  return (
  <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0"/>
    <g id="money-bag" transform="translate(-111.2 -76.914)">
      <path id="Path_198705" data-name="Path 198705" d="M129.6,87.069l2.283-3.64a.825.825,0,0,0,.1-.658.852.852,0,0,0-.4-.535,9.757,9.757,0,0,0-9.769.014.856.856,0,0,0-.267,1.179l2.276,3.633A13.507,13.507,0,0,0,117.2,98.051a5.827,5.827,0,0,0,1.769,4.353c2.44,2.365,6.615,2.509,7.575,2.509h.165c.439.007,5.1.055,7.739-2.5a5.838,5.838,0,0,0,1.769-4.353A13.467,13.467,0,0,0,129.6,87.069Zm.336-3.757-2.084,3.332a5.344,5.344,0,0,1-2.269-.007l-2.077-3.318A7.951,7.951,0,0,1,129.937,83.312Zm3.325,17.864c-2.18,2.125-6.478,2.022-6.526,2.022h-.055c-.041,0-4.326.11-6.519-2.022a4.117,4.117,0,0,1-1.248-3.126,11.955,11.955,0,0,1,6.231-9.769.244.244,0,0,0,.069.014,4.857,4.857,0,0,0-.692,1.913.512.512,0,0,0,.432.583.281.281,0,0,0,.075.007.51.51,0,0,0,.507-.439,3.38,3.38,0,0,1,.816-1.871c.014-.014.021-.027.034-.034.1.007.213.007.315.007.007.007.014.021.027.027a2.362,2.362,0,0,1,.555,1.3.521.521,0,0,0,.507.439.281.281,0,0,0,.075-.007.512.512,0,0,0,.432-.583,3.927,3.927,0,0,0-.411-1.268,3.835,3.835,0,0,0,.384-.082,11.8,11.8,0,0,1,6.218,9.762A4.034,4.034,0,0,1,133.262,101.177Z" fill="#babdcc" className="icon-by-asset-type-fill" />
      <path id="Path_198706" data-name="Path 198706" d="M13.052,8.25H9m4.052,2.026H9m2.026,4.052L9,12.3h1.013a2.026,2.026,0,0,0,0-4.052" transform="translate(116.2 85.914)" fill="none" stroke="#babdcc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" className="icon-by-asset-type-stroke" />
    </g>
  </svg>
)}
