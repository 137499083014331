import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { useEffect } from "react";
import ExpandableVirtualizedTable from '../../../shared/expandable-table';
import SharedVirtualizedTable, { ColumnDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from '../../../shared/virtual-table-component';
import { HoldingAssetAllocationChart } from "../holdingAssetAllocationChart";
import { CustomTypography } from "../../../shared";
import { Box, TableCell, TableRow } from "@mui/material";
import { NavigateToPortfolio } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AmountWithTooltip } from "../../../shared/numberWithTooltip";
import useAutoScrollTop from "../../../hooks/useAutoScrollTop";

interface L2_AllStocks_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

interface AllocationChartProps {
  AMID: number;
  assetName: string;
}

const getHeaderName = (tab: SharedConstants.HoldingsMode): string => {
  switch (tab) {
    case SharedConstants.HoldingsMode.AssetAllocation:
      return 'Asset Type';
    case SharedConstants.HoldingsMode.Assets:
      return 'Asset';
    case SharedConstants.HoldingsMode.Portfolio:
      return 'Portfolio';
  }

  return '';
}

const getColumns = (tab: SharedConstants.HoldingsMode): ColumnDataProps[] => {
  return [
    {
      FieldKey: "Name",
      HeaderName: getHeaderName(tab),
      ColumnWidthPercent: "60%",
      IsRowName: true,
      Align: "left",
      FieldFormatter: TableDefaultFieldFormatter
    },
    {
      FieldKey: "Value",
      HeaderName: "Current Value",
      ColumnWidthPercent: "20%",
      Align: "right",
      FieldFormatter: TableDecimalFieldFormatter
    },
    {
      FieldKey: "Percentage",
      HeaderName: "%",
      ColumnWidthPercent: "20%",
      Align: "right",
      FieldFormatter: TablePercentFieldFormatter,
    }
  ];
}

export default function L2_AllStocks_AA_Holding(props: L2_AllStocks_Holding_Props) {

  useAutoScrollTop();

  const [searchParams] = useSearchParams();

  const activeTabID = parseInt(searchParams.get('tab') || (''+SharedConstants.HoldingsMode.AssetAllocation));

  const assetClassWiseBreakdown = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectAssetClassWiseDetailedEquityExposureBreakdown(state, props.MPrUrlParams.AMID!)
  );
  const assetClassWiseTableData = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectAssetClassWiseDetailedEquityExposureTableData(state, props.MPrUrlParams.AMID!)
);

  const holdingsWiseTableData = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectAssetClassWiseDetailedEquityExposureSubEntriesTableData(state, props.MPrUrlParams.AMID!)
  );

  const portfolioWiseBreakdown = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectPortfolioWiseDetailedEquityExposureBreakdown(state, props.MPrUrlParams.AMID!)
  );
  const portfolioWiseTableData = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectPortfolioWiseDetailedEquityExposureTableData(state, props.MPrUrlParams.AMID!)
);

  const assetName = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveStockNameForEquityExposure);
  const activePortfolio = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolio);
  const IsGroup: boolean = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsActivePortfolioAGroup);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onAssetClassWiseTableTertiaryRowClick = (row: any) => {
    var newActivePortfolio = { CCID: activePortfolio?.CCID || -1, FamilyId: activePortfolio?.FamilyId || -1, PFID: row.PFID };
    NavigateToPortfolio({ navigate, shouldDispatchActivePortfolios: true, dispatch, activePortfolio: newActivePortfolio });
  }

  const TableFooterContent = ({ totalValue }: { totalValue: number }) => {
    return (
      <TableRow sx={{ height: "74px", verticalAlign: "center" }}>
        <TableCell sx={{ borderTop: "1px solid #EBECF2", background: "white", borderBottom: "none" }} />
        <TableCell align="left" sx={{ background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }}>
          <CustomTypography variant="Regular18">Total Current Exposure</CustomTypography>
        </TableCell>
        <TableCell align="right" sx={{ background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }}>
          <CustomTypography variant="Regular18">
            <AmountWithTooltip Value={totalValue} />
          </CustomTypography>
        </TableCell>
        <TableCell align="right" sx={{ background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }}>
          <CustomTypography variant="Regular18">100%</CustomTypography>
        </TableCell>
        <TableCell sx={{ width: "50px", background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }} />
        <Box sx={{ width: "12px", height: "100%", background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }} />
      </TableRow>
    );
  };

  const HoldingsTableFooterContent = ({ totalValue }: { totalValue: number }) => {
    return (
      <TableRow sx={{ background: "#FFFFFF" }}>
        <TableCell align="left" sx={{ background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }}>
          <CustomTypography variant="Regular18">Total Current Exposure</CustomTypography>
        </TableCell>
        <TableCell align="right" sx={{ background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }}>
          <CustomTypography variant="Regular18">
            <AmountWithTooltip Value={totalValue} />
          </CustomTypography>
        </TableCell>
        <TableCell align="right" sx={{ background: "white", borderTop: "1px solid #EBECF2", borderBottom: "none" }}>
          <CustomTypography variant="Regular18">100%</CustomTypography>
        </TableCell>
      </TableRow>
    );
  };

  return <Box>

    <RefreshCheck />

    {activeTabID === SharedConstants.HoldingsMode.AssetAllocation && (
      <>
        <Box sx={{ margin: "13px 45px 35px 45px" }}>
          <AssetAllocationChart AMID={props.MPrUrlParams.AMID!} assetName={assetName || ''} />
        </Box>
        <Box sx={{ margin: "35px 0" }}>
        <CustomTypography variant="Medium28" sx={{ margin: "0 0 25px 45px" }}>{`Breakdown of exposure to ${assetName}`}</CustomTypography>
        <Box sx={{ margin: "0 45px" }}>
          <ExpandableVirtualizedTable
            columns={getColumns(activeTabID)}
            rows={assetClassWiseTableData}
            primaryRowId="PrimaryRowID"
            secondaryRowId="SecondaryRowID"
            headerColor="#EAF4DF"
            sorting={true}
            onTertiaryRowClick={onAssetClassWiseTableTertiaryRowClick}
            emptyRowStyles={{ background: "#FFFFFF", borderTop: false }}
            footerContent={() => <TableFooterContent totalValue={assetClassWiseBreakdown?.totalValue || 0} />}
            tableHeight="500px"
            isMarginCellRequired={false}
            PMSBadgeField="IsCashMgmtPortfolio"
            tableType={SharedConstants.TableType.EquityExposureAssetClassWise}
            defaultSortConfig={SharedConstants.DefaultSortingForL2AllStocksAAEquityExposure}
            expandAllByDefault={true}
          />
        </Box>
        </Box>
      </>
    )}

    {activeTabID === SharedConstants.HoldingsMode.Assets && (
      <>
        <Box sx={{ margin: "13px 45px 35px 45px" }}>
          <AssetAllocationDetailChart AMID={props.MPrUrlParams.AMID!} assetName={assetName || ''} />
        </Box>
        <Box sx={{ margin: "35px 0" }}>
          <CustomTypography variant="Medium28" sx={{ margin: "0 0 25px 45px" }}>{`Breakdown of exposure to ${assetName}`}</CustomTypography>
          <Box sx={{ margin: "0 45px 35px 45px" }}>
            <SharedVirtualizedTable
              columns={getColumns(activeTabID)}
              rows={holdingsWiseTableData}
              headerColor="#EAF4DF"
              sorting={true}
              footerContent={() => <HoldingsTableFooterContent totalValue={portfolioWiseBreakdown?.totalValue || 0} />}
              defaultSortConfig={SharedConstants.DefaultSortingForL2AllStocksAAEquityExposure}
              tableType={SharedConstants.TableType.EquityExposureHoldingsWise}
            />
          </Box>
        </Box>
      </>
    )}

    {activeTabID === SharedConstants.HoldingsMode.Portfolio && IsGroup && (
      <>
        <Box sx={{ margin: "13px 45px 35px 45px" }}>
          <PortfolioDistributionChart AMID={props.MPrUrlParams.AMID!} assetName={assetName || ''} />
        </Box>
        <Box sx={{ margin: "35px 0" }}>
          <CustomTypography variant="Medium28" sx={{ margin: "0 0 25px 45px" }}>{`Breakdown of exposure to ${assetName}`}</CustomTypography>
          <Box sx={{ margin: "0 45px" }}>
            <ExpandableVirtualizedTable
              columns={getColumns(activeTabID)}
              rows={portfolioWiseTableData}
              primaryRowId="PrimaryRowID"
              secondaryRowId="SecondaryRowID"
              headerColor="#EAF4DF"
              sorting={true}
              emptyRowStyles={{ background: "#FFFFFF", borderTop: false }}
              footerContent={() => <TableFooterContent totalValue={portfolioWiseBreakdown?.totalValue || 0} />}
              tableHeight="500px"
              isMarginCellRequired={false}
              PMSBadgeField="IsCashMgmtPortfolio"
              tableType={SharedConstants.TableType.EquityExposurePortfolioWise}
              defaultSortConfig={SharedConstants.DefaultSortingForL2AllStocksAAEquityExposure}
              expandAllByDefault={true}
            />
          </Box>
        </Box>
      </>
    )}
  </Box>;
}

const AssetAllocationChart = (props: AllocationChartProps) => {
  const pieChartData = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectAssetClassWiseDetailedEquityExposurePieChartData(state, props.AMID!)
  );

  if (!pieChartData.PieChartData.length) return null;

  return (
    <HoldingAssetAllocationChart
      data={pieChartData.PieChartData}
      totalValue={pieChartData.TotalValue}
      heading={`Consolidated exposure to ${props.assetName}`}
      subHeading={`across Direct Holdings, PMS, Mutual Funds and ETFs`}
      dropdownValue="CurrValue"
      hideDropdown={true}
    />
  );
};

const AssetAllocationDetailChart = (props: AllocationChartProps) => {
  const { PieChartData, TotalValue } = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectAssetClassWiseDetailedEquityExposureSubEntriesPieChartData(state, props.AMID!)
  );

  if (PieChartData.length === 0) return null;

  return (
    <HoldingAssetAllocationChart
      data={PieChartData}
      totalValue={TotalValue}
      heading={`Consolidated exposure to ${props.assetName}`}
      subHeading={`across Direct Holdings, PMS, Mutual Funds and ETFs`}
      dropdownValue="CurrValue"
      hideDropdown={true}
    />
  );
};

const PortfolioDistributionChart = (props: AllocationChartProps) => {
  const { PieChartData, TotalValue } = useAppSelector((state) =>
    SharedRedux.PortfolioSummary.Selectors.selectPortfolioWiseDetailedEquityExposurePieChartData(state, props.AMID!)
  );

  if (PieChartData.length === 0) return null;

  return (
    <HoldingAssetAllocationChart
      data={PieChartData}
      totalValue={TotalValue}
      heading={`Consolidated exposure to ${props.assetName}`}
      subHeading={`across Portfolios`}
      dropdownValue="CurrValue"
      hideDropdown={true}
    />
  );
};

const RefreshCheck = () => {
  const dispatch = useAppDispatch();
  const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);

  useEffect(() => {
    dispatch(SharedRedux.PortfolioSummary.Actions.refreshMFETFPortfolioBreakdown());
  }, [activePortfolioIDs])

  return <></>;
}