import { Box, Button, Divider, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
  usePrevious,
} from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import {
  NavigateOnLogout,
  NavigateToPortfolio,
} from "packages/web/src/routes/navigationMethods";
import { SetAPIResponseInterceptors } from "packages/shared/src/api";
import { CustomTypography } from "../../shared";
import BreadCrumbComponent from "../../shared/breadcrumbs";
import { ArrowDown } from "../../../assets/icons/ArrowDown";
import ButtonWithTextAndIcon from "../../shared/button-with-label-and-icon";
import ButtonPrimaryCTA from "../../shared/button-primary-cta";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { DocumentIcon } from "../../../assets/icons/documentIcon";
import {
  ColumnDataProps,
  TableDefaultFieldFormatter,
} from "../../shared/virtual-table-component";

import ButtonText from "../../shared/button-text";
import TradeDetailsCard from "./tradeDetailsCard";

const data1 = [
  { type: "Quantity", value: "10" },
  { type: "Price", value: " 23,48,033.00" },
  { type: "Brokerage", value: "100" },
  { type: "Net Amount", value: "234,80,330.00", rightBtnValue: "Receivable" },
];

const data2 = [
  { type: "STT", value: "1234.00" },
  { type: "GST / S.Tax", value: "1234.00" },
  { type: "Stamp Charges", value: "1234.00" },
  { type: "Transaction Charges", value: "1234.00" },
  { type: "Other Charges", value: "1234.00" },
];

export const RowElements = ({ data }: { data: any }) => {
  return (
    <>
      {data.map((item: any) => (
        <Box sx={{ width: "220px" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
          >
            <CustomTypography
              variant="Regular18"
              color={"#64677A"}
            >
              {item.type}
            </CustomTypography>
            {item.rightBtnValue && (
              <CustomTypography
                variant="Medium14"
                color={"#64677A"}
                sx={{
                  ml: "8px",
                  border: "1px solid #9A9EB2",
                  borderRadius: "4px",
                  padding: "2px 4px",
                }}
              >
                Receivable
              </CustomTypography>
            )}
          </Box>
          <CustomTypography variant="Regular20">{item.value}</CustomTypography>
        </Box>
      ))}
    </>
  );
};

export default function TransactionDetails(props: {
  MPrUrlParams?: MPrUrlParams;
  isPlaceholder?: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  let currentPath = location.pathname.split("/").pop();
  const dispatch = useAppDispatch();
  const previousUrlParams = usePrevious(props.MPrUrlParams);

  const onNavigateToPortfolio = (
    activePortfolio: SharedRedux.Portfolios.SetActivePortfolioPayload
  ) => {
    NavigateToPortfolio({
      navigate,
      activePortfolio,
      pathname: location.pathname,
    });
  };

  useEffect(() => {
    SetAPIResponseInterceptors({
      On401Error: () => {
        NavigateOnLogout({
          navigate,
          dispatch,
        });
      },
      On403Error: () => {
        NavigateOnLogout({
          navigate,
          dispatch,
        });
      },
    });
  }, []);

  const userFromStore = useAppSelector(SharedRedux.Login.Selectors.selectUser);

  useEffect(() => {
    var activeCCID: number = -1;
    if (!userFromStore) {
      var userFromSessionStorageStr = sessionStorage.getItem("MprCurrentUser");
      if (userFromSessionStorageStr) {
        var userFromSessionStorage: SharedConstants.User = JSON.parse(
          userFromSessionStorageStr
        );
        if (userFromSessionStorage && userFromSessionStorage.access_token) {
          activeCCID = +(userFromSessionStorage.customerId || "-1");
          dispatch(
            SharedRedux.Login.Actions.loginSuccess(userFromSessionStorage)
          );
        }
      }
    } else {
      activeCCID = +(userFromStore.customerId || "-1");
    }

    if (activeCCID > 0) {
      dispatch(
        SharedRedux.Login.Actions.loadDashboard({
          CCID: activeCCID,
          ActivePortfolio:
            props.MPrUrlParams &&
            props.MPrUrlParams.PortfolioID &&
            props.MPrUrlParams.PortfolioID > 0
              ? {
                  CCID: props.MPrUrlParams.DatabaseID || -1,
                  FamilyId: props.MPrUrlParams.FamilyID || -1,
                  PFID: props.MPrUrlParams.PortfolioID || -1,
                }
              : undefined,
          OnNavigateToPortfolio: onNavigateToPortfolio,
        })
      );
    } else if (!props.isPlaceholder) {
      NavigateOnLogout({ navigate, dispatch });
    }
  }, []);

  useEffect(() => {
    if (props.isPlaceholder) return;

    // runs on location, i.e. route, change
    dispatch(
      SharedRedux.Portfolios.Actions.setActiveScreenIDs({
        ...props.MPrUrlParams,
      })
    );

    if (
      previousUrlParams == null ||
      props.MPrUrlParams == null ||
      previousUrlParams.DatabaseID != props.MPrUrlParams.DatabaseID ||
      previousUrlParams.FamilyID != props.MPrUrlParams.FamilyID ||
      previousUrlParams.PortfolioID != props.MPrUrlParams.PortfolioID
    ) {
      dispatch(
        SharedRedux.Portfolios.Actions.setActivePortfolio({
          CCID: props.MPrUrlParams?.DatabaseID || -1,
          FamilyId: props.MPrUrlParams?.FamilyID || -1,
          PFID: props.MPrUrlParams?.PortfolioID || -1,
        })
      );
    }
  }, [props.MPrUrlParams]);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    if (userFromStore && !props.isPlaceholder) {
      (window as any).FreshworksWidget("identify", "ticketForm", {
        name:
          userFromStore.userName && userFromStore.userName != "RESET"
            ? userFromStore.userName
            : undefined,
        email: userFromStore.email,
      });
    }
  }, [userFromStore]);

  const isSticky = () => {
    const header = document.querySelector("#myHeader");
    const scrollTop = window.scrollY;

    if (header && currentPath !== "reports") {
      scrollTop >= 88
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
  };

  return (
    <Grid
      container
      sx={style.layout}
    >
      <Grid
        item
        xs={12}
      >
        <Grid
          item
          mx={"32px"}
        >
          <BreadCrumbComponent />
        </Grid>
        <Grid
          item
          sx={{
            flex: "1 1 100%",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            margin: "0px 40px",
            borderRadius: "8px",
            minHeight: "85vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "32px 40px",
            }}
          >
            <CustomTypography variant="Medium24">
              Single Trade Details
            </CustomTypography>
            <ButtonText
              handleClick={() => {}}
              buttonText="Edit details"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              padding: "40px",
            }}
          >
            <TradeDetailsCard
              transType={"Buy"}
              assetName={"Bajaj Finserv Ltd"}
              date={"10 Jun, 2023"}
            />
            <Box
              sx={{
                display: "flex",
                padding: "42px 0 0 32px",
              }}
            >
              <RowElements data={data1} />
            </Box>
            <Divider sx={{ marginY: "32px" }} />
            <Box sx={{ margin: "32px" }}>
              <CustomTypography variant="Medium20">
                Additional Charges
              </CustomTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "0 0 0 32px",
              }}
            >
              <RowElements data={data2} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

const style = {
  layout: {
    display: "flex",
    width: { xs: "900px", sm: "100%" },
  },
  stickyHeader: {
    padding: { xs: "16px 42px", lg: "17px 42px" },
  },
};

// Static data for the table -
const transactionTableColumns: ColumnDataProps[] = [
  {
    FieldKey: "TransactionType",
    HeaderName: "TRANSACTION TYPE",
    FooterName: "Closing Balance",
    ColumnWidthPercent: "20%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "left",
    IsRowName: true,
  },
  {
    FieldKey: "Date",
    HeaderName: "DATE",
    FooterName: "",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
    // Sorting: true,
  },
  {
    FieldKey: "Quantity",
    HeaderName: "QUANTITY",
    FooterName: "12",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "Price",
    HeaderName: "PRICE",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "Brokerage",
    HeaderName: "BROKERAGE",
    FooterName: "",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "Amount",
    HeaderName: "AMOUNT",
    FooterName: "129839",
    ColumnWidthPercent: "10%",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
  {
    FieldKey: "Balance",
    HeaderName: "BALANCE QUANTITY",
    ColumnWidthPercent: "10%",
    FooterName: "0.00",
    FieldFormatter: TableDefaultFieldFormatter,
    Align: "right",
  },
];

const transactionTableData = [
  {
    AssetAllocationCategID: 1,
    TransactionType: "Opening Balance",
    Date: "24/09/20",
    Quantity: "12194.21",
    Price: "2308455.89",
    Brokerage: "2296261.68",
    Amount: 20,
    Balance: "12194.21",
  },
  {
    AssetAllocationCategID: 100,
    TransactionType: "Buy",
    Date: "24/09/20",
    Quantity: "504.89",
    Price: "2308455.89",
    Brokerage: "2296261.68",
    Amount: 20,
    Balance: "12194.21",
  },
  {
    AssetAllocationCategID: 5,
    TransactionType: "Sell",
    Date: "24/09/20",
    Quantity: "30",
    Price: "2000",
    Brokerage: "2296261.68",
    Amount: 20,
    Balance: "12194.21",
  },
  // {
  //   AssetAllocationCategID: 1,
  //   TransactionType: "Opening Balance",
  //   Date: "24/09/20",
  //   Quantity: "12194.21",
  //   Price: "2308455.89",
  //   Brokerage: "2296261.68",
  //   Amount: 20,
  //   Balance: "12194.21",
  // },
  // {
  //   AssetAllocationCategID: 100,
  //   TransactionType: "Buy",
  //   Date: "24/09/20",
  //   Quantity: "504.89",
  //   Price: "2308455.89",
  //   Brokerage: "2296261.68",
  //   Amount: 20,
  //   Balance: "12194.21",
  // },
  // {
  //   AssetAllocationCategID: 5,
  //   TransactionType: "Sell",
  //   Date: "24/09/20",
  //   Quantity: "30",
  //   Price: "2000",
  //   Brokerage: "2296261.68",
  //   Amount: 20,
  //   Balance: "12194.21",
  // },
];
