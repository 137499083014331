import { Box, TableCell } from "@mui/material";
import { ColumnDataProps, RowDataProps } from "../virtual-table-component";
import CustomTypography from "../customTypography";
import { TableIconUp } from "packages/web/src/assets/icons/TableIconUp";
import { TableIconDown } from "packages/web/src/assets/icons/TableIconDown";
import PMSBadge from "../pms-badge";
import { useState } from "react";
import { isZeroOrPositive } from '../../../../../shared/src/utilities';
import { RowLevelCellProps, SkeletonLoaderConfig } from "./index";
import { getIconByAssetTypeID } from "packages/web/src/assets/icons/assetTypes/outlined";
import SkeletonLoader from "../skeleton-loader";
import { ArrowUp } from "packages/web/src/assets/icons/ArrowUp";
import { ArrowDown } from "packages/web/src/assets/icons/ArrowDown";

export const PrimaryRowCell = ({ columns, row, isFirstRow, isLastRow, onRowClick, PMSBadgeField, expandedRows, primaryRowId, isHovered, isExpanded, isLoading, excludeLoadingFields = [], skeletonLoaderStyles = {}, emptyRowStyles = {}, isMarginCellRequired, StartingCell, EndingCell }: { columns: ColumnDataProps[], row: RowDataProps, isFirstRow: boolean, isLastRow: boolean, onRowClick?: (row: RowDataProps) => void, PMSBadgeField?: string, expandedRows: Set<string>, primaryRowId: string, isHovered: boolean, isExpanded: boolean, isLoading?: boolean, excludeLoadingFields?: string[], skeletonLoaderStyles?: SkeletonLoaderConfig, emptyRowStyles?: { background?: string, borderTop?: boolean }, isMarginCellRequired?: boolean, StartingCell?: RowLevelCellProps, EndingCell?: RowLevelCellProps }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openRowMenu = Boolean(anchorEl);

    const handleOpenRowMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleCloseRowMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const borderBottom = 'none';
    const borderTop = isFirstRow || (emptyRowStyles?.borderTop !== undefined ? emptyRowStyles.borderTop : row.empty) ? 'none' : '1px solid #EBECF2';

    const IconByAssetTypeID = getIconByAssetTypeID(row.AssetTypeID);

    return (
        <>
            { isMarginCellRequired ? 
            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
            : null
            }
            { StartingCell?.PrimaryRowCell ? 
            StartingCell.PrimaryRowCell({ borderTop, borderBottom }) : 
            <TableCell sx={{ borderTop, borderBottom }}>
                <Box sx={{ marginTop: "5px" }}>
                    {IconByAssetTypeID ? <IconByAssetTypeID /> : null}
                </Box>
            </TableCell>
            }
            {
                columns.map((column, index) => {
                    var showRowNameClass = column.IsRowName;
                    if (column.subColumns) {
                        return column.subColumns.map((subColumn) => (
                            <TableCell className={`${showRowNameClass ? "IsRowName" : ""} ${isExpanded ? "IsSelected" : ""}`} key={subColumn.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent, borderTop, borderBottom }}>
                                {isLoading && !excludeLoadingFields.includes(column.FieldKey) && !row.empty ? (
                                    <SkeletonLoader {...(skeletonLoaderStyles[column.FieldKey] || {height: "12px", width: "86px"})} animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8'}} sxContainer={{display: "flex", justifyContent: skeletonLoaderStyles[column.FieldKey]?.position || "flex-end"}}  />
                                    ) : 
                                    (
                                    <>
                                        {column.PrimaryRowCell ? column.PrimaryRowCell({column, row}) : (
                                        <>
                                        <CustomTypography variant='Regular18'>{column.FieldFormatter(row[column.FieldKey])}</CustomTypography>
                                        {row[subColumn.FieldKey] ?
                                            <Box>
                                                {(subColumn.UpFieldKey ? row[subColumn.UpFieldKey] === true : isZeroOrPositive(row[subColumn.FieldKey])) ?
                                                    <CustomTypography sx={{ color: "#5F854C", alignItems: 'center', gap: '4px' }} >
                                                        {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                                                        <TableIconUp />
                                                    </CustomTypography>
                                                    :
                                                    <CustomTypography sx={{ color: '#C94C40', alignItems: 'center', gap: '4px' }} >
                                                        {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                                                        <TableIconDown />
                                                    </CustomTypography>
                                                }
                                            </Box>
                                            :
                                            null
                                        }
                                        </>
                                        )}
                                    </>
                                )}
                            </TableCell >
                        ))
                    }
                    else {
                        return (
                            <TableCell className={`${showRowNameClass ? "IsRowName" : ""} ${isExpanded ? "IsSelected" : ""}`} key={column.FieldKey} align={column.Align} sx={{
                                 width: column.ColumnWidthPercent, minWidth: "130px", maxWidth: column.ColumnWidthPercent,
                                  borderTop, borderBottom }}>
                                {isLoading && !excludeLoadingFields.includes(column.FieldKey) && !row.empty ? (
                                    <SkeletonLoader {...(skeletonLoaderStyles[column.FieldKey] || {height: "12px", width: "86px"})} animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8'}} sxContainer={{display: "flex", justifyContent: skeletonLoaderStyles[column.FieldKey]?.position || "flex-end"}} />
                                    ) : 
                                    (
                                    <>
                                        <Box display="flex" alignItems="center" justifyContent={column.Align}>
                                            {column.PrimaryRowCell ? column.PrimaryRowCell({column, row}) : (
                                                <CustomTypography variant='Regular18' >{column.FieldFormatter(row[column.FieldKey])}</CustomTypography>
                                            )}
                                            {
                                                column.IsRowName && PMSBadgeField && row[PMSBadgeField] ? <Box ml={1}><PMSBadge badgeStyles={{ color: '#000', borderColor: '#000', backgroundColor: '#E2DA90', padding: '1px 6px' }} /></Box> : null
                                            }
                                            {index === 0 && (<Box marginLeft="18px">{!row.empty && (isExpanded ? <ArrowUp /> : <ArrowDown />)}</Box>)}
                                        </Box>
                                    </>
                                )}
                            </TableCell>
                        )
                    }
                })
            }
            { EndingCell?.PrimaryRowCell ? 
            EndingCell.PrimaryRowCell({ borderTop, borderBottom }) : 
            (<>
            <TableCell sx={{ borderTop, borderBottom, width: "50px" }}>
                {/* { isHovered && <Box onClick={handleOpenRowMenu} sx={{display:"flex", justifyContent: "center", alignItems: "center"}}><DotIcon /></Box> }
                { <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openRowMenu}
                    onClose={handleCloseRowMenu}
                    onClick={handleCloseRowMenu}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                        width: "237px",
                        overflow: 'hidden',
                        borderRadius:"4px",
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleCloseRowMenu}>Add Single Trade</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>Add Contract note</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>Other Transactions</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>All Transactions</MenuItem>
                    <MenuItem onClick={handleCloseRowMenu}>Advanced</MenuItem>
                </Menu> } */}
            </TableCell>
            <Box sx={{ width: "12px", borderBottom: isFirstRow || row.empty ? "none" : "1px solid #EBECF2" }} />
            </>
            )}
            { isMarginCellRequired ? 
            <TableCell sx={{ borderStyle: "none", width: "10px", background: "#F6F6F6", pointerEvents: "none" }} />
            : null
            }
        </>
    );
};
