export function Retirement(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g id="Group_228845" data-name="Group 228845" transform="translate(-11489 8928)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(11489 -8928)" fill="#fff" opacity="0" />
        <g id="Group_228845-2" data-name="Group 228845" transform="translate(11493.231 -8923.752)">
          <path id="Path_272286" data-name="Path 272286" d="M7,24.988l2.5-6.864a1.22,1.22,0,0,1,1.443-.768L18.5,19.244" transform="translate(-1.692 -4.602)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-width="1.6" />
          <line id="Line_2271" data-name="Line 2271" x1="0.718" y2="7.18" transform="translate(16.078 14.642)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-width="1.6" />
          <line id="Line_2272" data-name="Line 2272" y1="12.924" x2="5.744" transform="translate(16.796 1.718)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-width="1.6" />
          <path id="Path_272287" data-name="Path 272287" d="M22.036,14.324l-4.818-1.608a2.166,2.166,0,0,0-2.542.941l-.187.309" transform="translate(-3.804 -3.272)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-width="1.6" />
          <path id="Path_272289" data-name="Path 272289" d="M8.628,16.256a7.628,7.628,0,1,1,7.628-7.628" transform="translate(-3.166 -2.166)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" />
          <path id="Path_272290" data-name="Path 272290" d="M1.97,25c5.3,4.961,13.821,5.493,20.126,2.154" transform="translate(-0.274 -6.768)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-width="1.6" />
        </g>
        <path id="Path_272304" data-name="Path 272304" d="M13.364,7.2H7.8m5.566,2.7H7.8m2.783,5.4L7.8,12.593H9.189a2.7,2.7,0,1,0,0-5.4" transform="translate(11488.71 -8928.375)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
      </g>
    </svg>
  )
}
