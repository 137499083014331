import { Box, Modal } from '@mui/material';
import { Outlet, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import LeftNav from '../../leftNav';
import DashboardHeader from '../shared/dashboardHeader';
import DashboardMainHeader from '../shared/dashboardMainHeader';
import ReportsHeader from '../../reports/reports-header';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, usePrevious } from 'packages/web/src/redux/hooks';
import { SharedConstants, SharedRedux } from '@mprofit/shared';
import { MPrUrlParams } from 'packages/web/src/classes/MPrUrlParams';
import { NavigateOnLogout, NavigateToPortfolio } from 'packages/web/src/routes/navigationMethods';
import { SetAPIResponseInterceptors } from 'packages/shared/src/api';
import { CustomTypography, GraphLoader } from '../../shared';
import { CloseIcon } from 'packages/web/src/assets/icons/CloseIcon';
import ButtonPrimaryCTA from '../../shared/button-primary-cta';
import { RighSideTourModal } from 'packages/web/src/assets/icons/RighSideTourModal';
import BreadCrumbComponent from '../../shared/breadcrumbs';

export default function PortfolioLayout(props: { MPrUrlParams?: MPrUrlParams, isPlaceholder?: boolean }) {
  const location = useLocation();
  const navigate = useNavigate();
  let currentPath = location.pathname.split("/").pop();
  const dispatch = useAppDispatch();
  const [openAppTourModal, setAppTourModal] = useState(true)
  const previousUrlParams = usePrevious(props.MPrUrlParams);

  const onNavigateToPortfolio = (activePortfolio: SharedRedux.Portfolios.SetActivePortfolioPayload) => {
    NavigateToPortfolio({ navigate, activePortfolio, pathname: location.pathname });
  }

  useEffect(() => {
    SetAPIResponseInterceptors({
      On401Error: () => {
        NavigateOnLogout({
          navigate,
          dispatch
        });
      },
      On403Error: () => {
        NavigateOnLogout({
          navigate,
          dispatch
        });
      }
    })
  }, [])

  const userFromStore = useAppSelector(SharedRedux.Login.Selectors.selectUser);

  // For resetting the summary table state when user navigates to any other page
  const summaryTableConfig = useAppSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(SharedConstants.TableType.SummaryScreen));
  const excludedPaths = ['summary', 'transaction'];
  useEffect(() => {
    let currentPath = location?.pathname?.split("/")?.pop();
    if (currentPath && !excludedPaths.includes(currentPath)) {
      if (summaryTableConfig) {
        dispatch(SharedRedux.Dashboard.Actions.setTableConfig({ searchTerm: '', tableType: SharedConstants.TableType.SummaryScreen, sortConfig: { key: 'CurrValue', direction: SharedConstants.SortDirection.Down }, scrollPosition: 0, expTableConfig: { expandAllState: false, expandedRowState: Array.from(new Set()), expandedSecondaryRowState: Array.from(new Set() ) } }));
      }
    }
  }, [props.MPrUrlParams]);

  
  useEffect(() => {
    var activeCCID: number = -1;
    if (props.MPrUrlParams && props.MPrUrlParams.DatabaseID && props.MPrUrlParams.DatabaseID > 0) {
      activeCCID = props.MPrUrlParams.DatabaseID;
    }

    var userCCID: number = -1;

    if (!userFromStore) {
      var userFromSessionStorageStr = sessionStorage.getItem('MprCurrentUser');
      if (userFromSessionStorageStr) {
        var userFromSessionStorage: SharedConstants.User = JSON.parse(userFromSessionStorageStr);
        if (userFromSessionStorage && userFromSessionStorage.access_token) {
          userCCID = +(userFromSessionStorage.customerId || "-1")
          dispatch(SharedRedux.Login.Actions.loginSuccess(userFromSessionStorage));
        }
      }
    } else {
      userCCID = +(userFromStore.customerId || "-1");
    }

    if (userCCID > 0) {
      dispatch(SharedRedux.Login.Actions.loadDashboard({
        CCID: activeCCID,
        ActivePortfolio: props.MPrUrlParams && props.MPrUrlParams.PortfolioID && props.MPrUrlParams.PortfolioID > 0 ? {
          CCID: props.MPrUrlParams.DatabaseID || -1,
          FamilyId: props.MPrUrlParams.FamilyID || -1,
          PFID: props.MPrUrlParams.PortfolioID || -1
        } : undefined,
        OnNavigateToPortfolio: onNavigateToPortfolio
      }));
    } else if (!props.isPlaceholder) {
      NavigateOnLogout({ navigate, dispatch });
    }
  }, [])

  useEffect(() => {
    if (props.isPlaceholder) return;

    // runs on location, i.e. route, change
    dispatch(SharedRedux.Portfolios.Actions.setActiveScreenIDs({ ...props.MPrUrlParams }));

    if (previousUrlParams == null || props.MPrUrlParams == null
      || previousUrlParams.DatabaseID != props.MPrUrlParams.DatabaseID || previousUrlParams.FamilyID != props.MPrUrlParams.FamilyID
      || previousUrlParams.PortfolioID != props.MPrUrlParams.PortfolioID
    ) {
      dispatch(SharedRedux.Portfolios.Actions.setActivePortfolio({ CCID: props.MPrUrlParams?.DatabaseID || -1, FamilyId: props.MPrUrlParams?.FamilyID || -1, PFID: props.MPrUrlParams?.PortfolioID || -1 }))
    }
  }, [props.MPrUrlParams])

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    if (userFromStore && !props.isPlaceholder) {
      (window as any).FreshworksWidget('identify', 'ticketForm', {
        name: userFromStore.userName && userFromStore.userName != 'RESET' ? userFromStore.userName : undefined,
        email: userFromStore.email,
      })
    }
  }, [userFromStore])

  const isSticky = () => {
    const header = document.querySelector("#myHeader");
    const scrollTop = window.scrollY;

    if (header && currentPath !== 'reports') {
      scrollTop >= 88
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    }
  };


  const renderHeader = () => {
    switch (currentPath) {
      case 'today':
      case 'performance':
      case 'holding':
        return <DashboardHeader MPrUrlParams={props.MPrUrlParams} />
      case 'explore':
      case 'saved':
        return <ReportsHeader />
      case 'transaction':
        return <BreadCrumbComponent />
      default:
        break;
    }
  }

  return (
    <Box sx={style.layout}>
      {/* <Modal
        open={openAppTourModal}
        // open={false}
        onClose={() => setAppTourModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '65%',
          height: '57%',
          bgcolor: 'background.paper',
          // border: '2px solid #000',
          boxShadow: 24,
          padding: '2% 4% 4% 4%',

        }}>
          <Box sx={{ height: '10%', display: 'flex', justifyContent: 'end', marginRight: '-2%' }} >
            <CloseIcon sx={{ color: '#707070', marginTop: '-30px' }} />
          </Box>
          <Box sx={{ display: 'flex', paddingTop: '1%' }}>
            <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <CustomTypography variant='Medium24' sx={{ marginBottom: '3%' }}>Welcome to Profit. Check out our features that will help you become a trading expert.</CustomTypography>
              <CustomTypography variant='Regular20' sx={{ marginBottom: '3%' }}>Total Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</CustomTypography>
              <Box sx={{ paddingTop: '1%' }}>
                <ButtonPrimaryCTA buttonText='Start Tour' />
              </Box>
            </Box>
            <Box sx={{ flex: 1.5, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              <RighSideTourModal />
            </Box>
          </Box>
        </Box>
      </Modal> */}
      {/* Left Nav */}
      <Box sx={{ flex: { xs: '0 0 64px', lg1: 'auto' } }} className='apptour-today-leftnav'>
        <LeftNav />
      </Box>
      <Box sx={{ flex: '1 1 100%', minWidth: 0, backgroundColor: '#F6F6F6', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box height='88px'>
          <DashboardMainHeader isPlaceholder={props.isPlaceholder} />
        </Box>
        {/* Tab Nav  */}
        <Box id="myHeader" sx={style.stickyHeader}>
          {renderHeader()}
        </Box>
        {/* Routes */}
        <Box sx={{ flex: '1 1 100%', background: '#F6F6F6', minWidth: { xs: "800px", sm: 'auto' } }}>
          {
            props.isPlaceholder ?
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', background: '#FFFFFF', height: '100%' }}>
                <GraphLoader />
              </Box>
              :
              <Outlet />
          }
        </Box>
      </Box>
    </Box >
  )
}

const style = {
  layout: {
    display: 'flex',
    width: { xs: "900px", sm: '100%' },
    minHeight: '100vh',
  },
  stickyHeader: {
    padding: { xs: '16px 42px', lg: '17px 42px' },
    // padding: { xs: '16px 42px', lg: '17px 31px 17px 42px', lg2: '17px 42px'},

  }
}