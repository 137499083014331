export function GreenCornerCircle(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170"
      height="132"
      viewBox="0 0 170 132"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_48975"
            data-name="Rectangle 48975"
            width="170"
            height="122"
            rx="6"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_100"
        data-name="Mask Group 100"
        clip-path="url(#clip-path)"
      >
        <circle
          id="Oval"
          cx="75.5"
          cy="75.5"
          r="75.5"
          transform="translate(67 -54)"
          fill="#eaf4df"
        />
      </g>
    </svg>
  );
}
