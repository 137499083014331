export function GLD(props: any) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="Group_150665" data-name="Group 150665" transform="translate(-35 -318)">
      <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0"/>
      <g id="noun-shiny-gold-4695913" transform="translate(-161.8 205.58)">
        <path id="Path_198307" data-name="Path 198307" d="M209.292,129.4l2.047,5.118H199.823l2.047-5.118h7.421m0-1.023H201.87a1.024,1.024,0,0,0-.95.643l-2.047,5.118a1.024,1.024,0,0,0,.95,1.4h11.515a1.024,1.024,0,0,0,.95-1.4l-2.047-5.118a1.024,1.024,0,0,0-.95-.643Zm14.074,1.023,2.047,5.118H213.9l2.047-5.118h7.421m0-1.023h-7.421a1.024,1.024,0,0,0-.95.643l-.007.017-2.04,5.1a1.024,1.024,0,0,0,.95,1.4h11.515a1.024,1.024,0,0,0,.95-1.4l-2.047-5.118a1.024,1.024,0,0,0-.95-.644Zm-7.037-6.93,2.047,5.118H206.86l2.047-5.118h7.421m0-1.023h-7.421a1.024,1.024,0,0,0-.95.643l-2.047,5.118a1.024,1.024,0,0,0,.95,1.4h11.515a1.024,1.024,0,0,0,.95-1.4l-2.047-5.118a1.024,1.024,0,0,0-.951-.643Z" transform="translate(0 0)" fill="#babdcc" stroke="#babdcc" strokeWidth="1" className="icon-by-asset-type-stroke icon-by-asset-type-fill"  />
      </g>
    </g>
  </svg>
)}