import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, formatDateForReading } from "@mprofit/shared";
import HoldingLayout from "../layout";
import SharedVirtualizedTable, { ColumnDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { HoldingAssetAllocationChart, RowDataProps } from "../holdingAssetAllocationChart";
import { useEffect } from "react";
import { navigateBasedOnPathnameDashboardTabUrlParams } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomTypography } from "../../../shared";
import ExpandableVirtualizedTable from "../../../shared/expandable-table";
import { Box } from "@mui/material";

interface L2_AllStocks_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

type ChartType = 'default' | 'sector' | 'marketCap';

const useRowClickHandler = () => {
  const isAdvancedEquityExposureEnabled = useAppSelector(SharedRedux.Licensing.Selectors.selectIsAdvancedEquityExposureEnabled);
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (row: RowDataProps) => {
    if (!isAdvancedEquityExposureEnabled) return;
    if (!activeScreenIDs) return;

    var urlParams = new MPrUrlParams({
      Screen: SharedConstants.ScreenEnum.L2_AllStocks_AA,
      DatabaseID: activeScreenIDs.DatabaseID,
      FamilyID: activeScreenIDs.FamilyID,
      PortfolioID: activeScreenIDs.PortfolioID,
      AMID: row.AMID ? Number(row.AMID) : undefined,
      AssetType: row.AssetTypeID ? Number(row.AssetTypeID) : undefined,
    });

    dispatch(SharedRedux.Dashboard.Actions.resetTableConfig({ tableType: SharedConstants.TableType.EquityExposureAssetClassWise }));
    dispatch(SharedRedux.Dashboard.Actions.resetTableConfig({ tableType: SharedConstants.TableType.EquityExposurePortfolioWise }));
    navigateBasedOnPathnameDashboardTabUrlParams({ navigate, urlParams, dashboardTab: SharedConstants.DashboardTabs.HOLDING });
  }
};

export default function L2_AllStocks_Holding(props: L2_AllStocks_Holding_Props) {
  const [searchParams] = useSearchParams();
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActiveMFETFPortfolioBreakdownLoading);

  const tableDataSectorWise = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_Sector);
  const tableDataMarketCapWise = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_MarketCap);

  const activeTabID = parseInt(searchParams.get('tab') || (''+SharedConstants.HoldingsMode.Assets));

  const onRowClick = useRowClickHandler();

  return <>
    <RefreshCheck />

    {activeTabID === SharedConstants.HoldingsMode.Assets && (
      <Box sx={{ marginTop: '13px' }}>
        <HoldingLayout
          {...props}
          Screen_AssetAllocationChart={AssetAllocationChart}
          Screen_Table={HoldingsTable}
            isLoading={isLoading}
            tableHeading={'Stock-wise Details'}
          />
      </Box>
    )}

    {activeTabID === SharedConstants.HoldingsMode.Sectors && (
      <Box sx={{ marginBottom: 4, marginTop: '13px' }}>
        <Box sx={{ margin: "0 45px 35px 45px" }}>
          <SectorWiseAssetAllocationChart />
        </Box>
        <CustomTypography variant="Medium28" sx={{ margin: "0 0 10px 45px" }}>Sector-wise Details</CustomTypography>
        <Box sx={{ margin: "0 45px" }}>
          <ExpandableVirtualizedTable
            columns={sectorTableColumns}
            rows={tableDataSectorWise}
            primaryRowId="PrimaryRowID"
            secondaryRowId="SecondaryRowID"
            headerColor="#EAF4DF"
            sorting={true}
            tableHeight="400px"
            emptyRowStyles={{ background: "#FFFFFF", borderTop: false }}
            onSecondaryRowClick={onRowClick}
            isMarginCellRequired={false}
            tableType={SharedConstants.TableType.EquityExposureSectorWise}
            defaultSortConfig={SharedConstants.DefaultSortingForL2AllStocksEquityExposure}
          />
        </Box>
      </Box>

    )}

    {activeTabID === SharedConstants.HoldingsMode.MarketCap && (
      <Box sx={{ marginBottom: 4, marginTop: '13px' }}>
        <Box sx={{ margin: "0 45px 35px 45px" }}>
          <MarketCapWiseAssetAllocationChart />
        </Box>
        <CustomTypography variant="Medium28" sx={{ margin: "0 0 10px 45px" }}>Market Cap-wise Details</CustomTypography>
        <Box sx={{ margin: "0 45px" }}>
          <ExpandableVirtualizedTable
            columns={marketCapTableColumns}
            rows={tableDataMarketCapWise}
            primaryRowId="PrimaryRowID"
            secondaryRowId="SecondaryRowID"
            headerColor="#EAF4DF"
            sorting={true}
            tableHeight="400px"
            emptyRowStyles={{ background: "#FFFFFF", borderTop: false }}
            onSecondaryRowClick={onRowClick}
            isMarginCellRequired={false}
            tableType={SharedConstants.TableType.EquityExposureMarketCapWise}
            defaultSortConfig={SharedConstants.DefaultSortingForL2AllStocksEquityExposure}
          />
        </Box>
      </Box>
    )}
  </>;
}

const RefreshCheck = () => {
  const dispatch = useAppDispatch();
  const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);

  useEffect(() => {
    dispatch(SharedRedux.PortfolioSummary.Actions.refreshMFETFPortfolioBreakdown());
  }, [activePortfolioIDs])

  return <></>;
}

const AssetAllocationChartWrapper = ({ type = 'default' }: { type?: ChartType }) => {
  const getChartConfig = () => {
    switch (type) {
      case 'sector':
        return {
          chartSelector: SharedRedux.PortfolioSummary.Selectors.selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown_Sector,
          tableSelector: SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_Sector_Primary,
          heading: 'Sectoral Exposure across Stocks, Mutual Funds and ETFs',
          chartHeading: 'Sector-wise Details',
          reportNameForFileName: 'Sectoral Exposure',
          reportTableColumns: sectorTableColumns
        };
      case 'marketCap':
        return {
          chartSelector: SharedRedux.PortfolioSummary.Selectors.selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown_MarketCap,
          tableSelector: SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown_MarketCap_Primary,
          heading: 'Market Cap Exposure across Stocks, Mutual Funds and ETFs',
          chartHeading: 'Market Cap-wise Details',
          reportNameForFileName: 'Market Cap Exposure',
          reportTableColumns: marketCapTableColumns
        };
      default:
        return {
          chartSelector: (state: any) => SharedRedux.PortfolioSummary.Selectors.selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown(state, 'CurrValue'),
          tableSelector: SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown,
          heading: 'Equity Exposure across Stocks, Mutual Funds and ETFs',
          chartHeading: 'Stock-wise Details',
          reportNameForFileName: 'Equity Exposure',
          reportTableColumns: tableColumns
        };
    }
  };

  const { chartSelector, tableSelector, heading, chartHeading, reportNameForFileName, reportTableColumns } = getChartConfig();
  const result = useAppSelector(chartSelector);

  const { PieChartData = [], TotalValue = '0' } = Array.isArray(result)
    ? { PieChartData: [], TotalValue: '0' }
    : result;

  const tableData: RowDataProps[] = useAppSelector(tableSelector) as RowDataProps[];
  const handleChartItemClick = useRowClickHandler();

  const onItemClick = (item: SharedConstants.PieChartData) => {
    if (type === 'default' && item.IDs?.AMID && item.IDs?.AssetTypeID) {
      handleChartItemClick(item.IDs)
    }
  }

  return (
    <HoldingAssetAllocationChart
      data={PieChartData}
      totalValue={TotalValue}
      dropdownValue={'CurrValue'}
      heading={heading}
      subHeading={`as on ${formatDateForReading(new Date())}`}
      hideDropdown={true}
      showDownloadPdfIcon={true}
      tableType={SharedConstants.TableType.EquityExposure}
      pdfData={{
        ShowPDFHeading: true,
        ReportNameForFileName: reportNameForFileName,
        Table: {
          columnData: reportTableColumns,
          rowData: tableData,
          chartHeading: chartHeading
        }
      }}
      onItemClick={onItemClick}
    />
  );
};

const AssetAllocationChart = () => <AssetAllocationChartWrapper />;
const SectorWiseAssetAllocationChart = () => <AssetAllocationChartWrapper type="sector" />;
const MarketCapWiseAssetAllocationChart = () => <AssetAllocationChartWrapper type="marketCap" />;

const HoldingsTable = ({ AssetType, searchTerm }: { AssetType?: number, searchTerm: string }) => {
  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown);
  const fieldNamesToSearch = ['Name'];
  const onRowClick = useRowClickHandler();

  return <SharedVirtualizedTable
    columns={tableColumns}
    rows={tableData}
    headerColor="#EAF4DF"
    sorting={true}
    onRowClick={onRowClick}
    searchTerm={searchTerm}
    fieldNamesToSearch={fieldNamesToSearch}
    defaultSortConfig={SharedConstants.DefaultSortingForHoldingTable}
    tableType={SharedConstants.TableType.EquityExposure}
  />
}

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'Name',
    HeaderName: 'Stock',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'HoldingPct',
    HeaderName: '% of Equity Exposure',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'PctOfTotal',
    HeaderName: '% of Portfolio',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  }
];

const sectorTableColumns = tableColumns
  .filter(col => col.FieldKey !== 'PctOfTotal')
  .map(col => col.FieldKey === 'Name' ? { ...col, HeaderName: 'SECTOR', ColumnWidthPercent: '50%' } : col);

const marketCapTableColumns = tableColumns
  .filter(col => col.FieldKey !== 'PctOfTotal')
  .map(col => col.FieldKey === 'Name' ? { ...col, HeaderName: 'MARKET CAP', ColumnWidthPercent: '50%' } : col);