import { Box, Grid } from '@mui/material'
import Big from 'big.js'
import { t } from 'i18next';
import { DashboardItemContainer } from '../../shared';
import { LeaderBoard, WatchList, CustomTypography, RecentCorporateActions, GraphLoader } from '../../../shared';
import { todayStyles } from '../index';
import { ComponentType, useEffect, useState } from 'react';
import { DropdownItem } from '../../../shared/drop-down';
import { TableSearchBar } from '../../../shared/table-search-bar';
import useAutoScrollTop from '../../../hooks/useAutoScrollTop';
import AppTour from '../../../shared/app-tour';
import AppTourModal from '../../../shared/app-tour/appTourModal';
import { TODAY_TOUR_STEPS } from '../../../shared/app-tour/appTourSteps';
import { NavigateToTab } from 'packages/web/src/routes/navigationMethods';
import { SharedConstants, SharedRedux } from '@mprofit/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import { WebRedux } from '@mprofit/web';

interface Today_Layout_Props {
  IsLoading: boolean;
  Screen_TodayValueCard: ComponentType<any>;
  Screen_TodayGainLossCard: ComponentType<any>;
  Screen_PieChart: ComponentType<any> | undefined;
  Screen_TodayTable: ComponentType<any>;
  Screen_TodayPerformanceColumnChart: ComponentType<any>;
  Screen_TodayTableDropdown?: ComponentType<any>;
  DefaultTableDropdownValue?: string | number;
  ShowStocksCards: boolean;
  IsAllowTour?: boolean;
  TableTitle: string;
  HideCorporateActions?: boolean;
}

export default function TodayLayout({ IsLoading, Screen_TodayValueCard, Screen_TodayGainLossCard, Screen_PieChart, Screen_TodayTable, Screen_TodayPerformanceColumnChart, Screen_TodayTableDropdown, DefaultTableDropdownValue, TableTitle, ShowStocksCards, IsAllowTour, HideCorporateActions = false }: Today_Layout_Props) {
  useAutoScrollTop();
  const [runAppTour, setRunAppTour] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const onTourEnd = () => {
    NavigateToTab({tab: SharedConstants.DashboardTabs.HOLDING, navigate, location });
    dispatch(WebRedux.Tutorial.Actions.toggleHoldingTour(true));
  }

  const [showTourModal, setShowTourModal] = useState(false);

  const showTutorial_Main = useAppSelector(SharedRedux.Dashboard.Selectors.selectShowTutorial_Main);


  useEffect(() => {
    if (showTutorial_Main) {
      dispatch(SharedRedux.Dashboard.Actions.addUpdateUserProfileItem({Item: [{Key: 'IsWebTutorialViewed_Main', Value: true}]}))
      setShowTourModal(true);
    }
  }, [showTutorial_Main])

  return (
    <AppTour
      runAppTour={IsAllowTour === true && runAppTour}
      setRunAppTour={setRunAppTour}
      appTourSteps={TODAY_TOUR_STEPS}
      onTourEnd={onTourEnd}
    >
      <>
        {
          IsLoading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', background: '#FFFFFF', height: '100%' }}>
              <GraphLoader />
            </Box>
            :
            <Box className="todayContainer" sx={todayStyles.todayContainer}>
              {
                showTourModal && IsAllowTour &&
                <AppTourModal onStartClick={() => {setRunAppTour(true); setShowTourModal(false);}} onClose={() => setShowTourModal(false)}/>
              }
              <Box sx={Screen_PieChart ? todayStyles.leftContainer : todayStyles.leftContainerFullWidth}>
                <Box sx={todayStyles.topCards}>
                  <Grid container spacing={{ xs: 2, lg: 3 }} sx={{ height: IsLoading ? "228px" : "auto" }} >
                    <Grid item xs={7.5} md={7} lg={7} xl={7} >
                      <Screen_TodayValueCard />
                    </Grid>
                    <Grid item xs={4.5} md={5} lg={5} xl={5}>
                      <Screen_TodayGainLossCard />
                    </Grid>
                  </Grid>
                </Box>
                <Grid sx={todayStyles.portfolioPerformance}>
                  <Screen_TodayPerformanceColumnChart />
                </Grid>
                {
                  Screen_PieChart &&
                  <Grid sx={todayStyles.rightContainer}>
                    <Screen_PieChart />
                  </Grid>
                }
              </Box>
              {
                ShowStocksCards &&
                <>
                  <Grid container>
                    <Grid item xs={12} sx={{ padding: " 64px 5px 20px 5px" }}>
                      <CustomTypography variant='Medium28'>{t('portfolio.today.stockInsight.title')}</CustomTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ height: { lg: "670px" } }}>
                    <Grid item xs={12} lg={6} height="100%">
                      <DashboardItemContainer sx={{ height: { xs: "570px", lg: "100%" } }} className='apptour-today-leaderboard'>
                        <LeaderBoard />
                      </DashboardItemContainer>
                    </Grid>
                    <Grid item xs={12} lg={6} height="100%">
                      <DashboardItemContainer sx={{ height: { xs: "570px", lg: "100%" } }} className='apptour-today-watchlist'>
                        <WatchList />
                      </DashboardItemContainer>
                    </Grid>
                  </Grid>
                </>
              }
              <Grid container spacing={3} className='apptour-today-table' sx={{marginTop: '64px'}}>
                <TableSection Screen_TodayTableDropdown={Screen_TodayTableDropdown} Screen_TodayTable={Screen_TodayTable} DefaultDropdownValue={DefaultTableDropdownValue} TableTitle={TableTitle}/>
              </Grid>
              {
                ShowStocksCards && !HideCorporateActions &&
                <Grid container marginTop={'69px'}>
                  <Grid item lg={12} xs={12}>
                    <DashboardItemContainer className='apptour-today-corporateactions'>
                      <RecentCorporateActions />
                    </DashboardItemContainer>
                  </Grid>
                </Grid>
              }
            </Box>
        }
      </>
    </AppTour>
  )
}

const TableSection = ({ Screen_TodayTableDropdown, Screen_TodayTable, DefaultDropdownValue, TableTitle }: { Screen_TodayTableDropdown?: ComponentType<any>, Screen_TodayTable: ComponentType<any>, DefaultDropdownValue?: string | number, TableTitle: string }) => {
  const [dropdownValue, setDropdownValue] = useState(DefaultDropdownValue);
  const [searchTerm, setSearchTerm] = useState('');
  return <>
    <Grid xs={12} item sx={{ display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: 'center' }}>
      <Box sx={{ display: "flex", justifyContent: "start", flexDirection: 'row', gap: "24px", alignItems: 'center' }}>
        <CustomTypography variant='Medium28'>{TableTitle}</CustomTypography>
        {
          Screen_TodayTableDropdown &&
          <Screen_TodayTableDropdown dropdownValue={dropdownValue} handleChange={(item: DropdownItem) => setDropdownValue(item.id)} />
        }
      </Box>
      <Box width="408px">
        <TableSearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Box>
    </Grid>
    <Grid item lg={12} xs={12}>
      <DashboardItemContainer>
        <Screen_TodayTable dropdownValue={dropdownValue} searchTerm={searchTerm} />
      </DashboardItemContainer>
    </Grid>
  </>
}