export function MIS(props: any) {
  return (
  <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32.001" height="32" viewBox="0 0 32.001 32">
    <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0"/>
    <g id="Group_222246" data-name="Group 222246" transform="translate(-34.137 -500.497)">
      <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(34.137 507.497)" fill="none" stroke="#babdcc" strokeWidth="1" className="icon-by-asset-type-stroke" >
        <rect width="32" height="18" rx="4" stroke="none"/>
        <rect x="0.5" y="0.5" width="31" height="17" rx="3.5" fill="none"/>
      </g>
      <path id="FC_Chelsea" data-name="FC Chelsea" d="M.42,0A.251.251,0,0,1,.246-.072a.235.235,0,0,1-.078-.18L.192-.36,3.084-8.1a.39.39,0,0,1,.42-.3H4.812a.413.413,0,0,1,.42.3L8.124-.36a.364.364,0,0,1,.012.108.244.244,0,0,1-.072.18A.23.23,0,0,1,7.9,0H6.852a.358.358,0,0,1-.24-.072A.326.326,0,0,1,6.5-.228L5.94-1.692H2.376L1.812-.228A.348.348,0,0,1,1.464,0Zm5.1-3.072L4.152-6.78,2.8-3.072ZM10.02,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.112a.273.273,0,0,1,.084-.21.289.289,0,0,1,.2-.078h1.128a.284.284,0,0,1,.21.078.284.284,0,0,1,.078.21V-.288a.289.289,0,0,1-.078.2.273.273,0,0,1-.21.084Zm4.068,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.1a.3.3,0,0,1,.078-.216.273.273,0,0,1,.21-.084H19.3a.292.292,0,0,1,.216.084A.292.292,0,0,1,19.6-8.1v.84a.273.273,0,0,1-.084.21.3.3,0,0,1-.216.078H15.444v2.28h3.612a.292.292,0,0,1,.216.084.292.292,0,0,1,.084.216v.84a.273.273,0,0,1-.084.21.3.3,0,0,1-.216.078H15.444V-.288a.273.273,0,0,1-.084.21A.3.3,0,0,1,15.144,0Z" transform="translate(40.256 520.696)" fill="#babdcc" className="icon-by-asset-type-fill" />
    </g>
  </svg>
)}