export function ULIP(props: any) {
  return (
  <svg id="Group_150509" data-name="Group 150509" xmlns="http://www.w3.org/2000/svg" width="32.001" height="32" viewBox="0 0 32.001 32">
    <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" fill="#fff" opacity="0"/>
    <g id="Group_222246" data-name="Group 222246" transform="translate(-34.137 -500.497)">
      <g id="Rectangle_24192" data-name="Rectangle 24192" transform="translate(34.137 507.497)" fill="none" stroke="#babdcc" strokeWidth="1" className="icon-by-asset-type-stroke">
        <rect width="32" height="18" rx="4" stroke="none"/>
        <rect x="0.5" y="0.5" width="31" height="17" rx="3.5" fill="none"/>
      </g>
      <path id="Name" d="M-7.514.12A4.3,4.3,0,0,1-9.326-.234a2.577,2.577,0,0,1-1.188-1.092,3.789,3.789,0,0,1-.42-1.89V-8.1a.292.292,0,0,1,.084-.216.278.278,0,0,1,.2-.084h1.092a.273.273,0,0,1,.21.084.3.3,0,0,1,.078.216v4.884A2,2,0,0,0-8.8-1.758a1.723,1.723,0,0,0,1.284.474,1.7,1.7,0,0,0,1.278-.474,2.018,2.018,0,0,0,.462-1.458V-8.1a.292.292,0,0,1,.084-.216A.292.292,0,0,1-5.474-8.4h1.08a.273.273,0,0,1,.21.084.3.3,0,0,1,.078.216v4.884a3.834,3.834,0,0,1-.414,1.89A2.55,2.55,0,0,1-5.7-.234,4.285,4.285,0,0,1-7.514.12ZM-1.932,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.112a.278.278,0,0,1,.084-.2.278.278,0,0,1,.2-.084H-.816a.278.278,0,0,1,.2.084.278.278,0,0,1,.084.2v6.684h3.8a.292.292,0,0,1,.216.084.292.292,0,0,1,.084.216v.84a.278.278,0,0,1-.084.2A.292.292,0,0,1,3.276,0ZM5.114,0a.278.278,0,0,1-.2-.084.278.278,0,0,1-.084-.2V-8.1a.292.292,0,0,1,.084-.216.278.278,0,0,1,.2-.084H8.33a4.172,4.172,0,0,1,1.632.294,2.327,2.327,0,0,1,1.08.888,2.677,2.677,0,0,1,.384,1.482,2.64,2.64,0,0,1-.384,1.482,2.317,2.317,0,0,1-1.08.87A4.249,4.249,0,0,1,8.33-3.1H6.542V-.288a.289.289,0,0,1-.078.2A.273.273,0,0,1,6.254,0Zm1.4-4.428H8.27a1.559,1.559,0,0,0,1.068-.33,1.239,1.239,0,0,0,.372-.978A1.344,1.344,0,0,0,9.362-6.7,1.445,1.445,0,0,0,8.27-7.068H6.518Z" transform="translate(50.138 520.497)" fill="#babdcc" className="icon-by-asset-type-fill"/>
    </g>
  </svg>
)}
