export function CurveBgStyle(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1431"
      height="194"
      viewBox="0 0 1431 194"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_48744"
            data-name="Rectangle 48744"
            width="1431"
            height="194"
            rx="8"
            transform="translate(-20940.551 6045.17)"
            fill="#f6f6f6"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop
            offset="0"
            stop-color="#d0e3bb"
          />
          <stop
            offset="1"
            stop-color="#dbdbdb"
          />
        </linearGradient>
      </defs>
      <g
        id="Mask_Group_122"
        data-name="Mask Group 122"
        transform="translate(20940.551 -6045.17)"
        clip-path="url(#clip-path)"
      >
        <g
          id="Group_226069"
          data-name="Group 226069"
          transform="translate(810 -43.517)"
          opacity="0.6"
        >
          <path
            id="Path_271867"
            data-name="Path 271867"
            d="M0,0C2.8,18.3,19.9,32.2,38.231,38.743s38.23,7.417,57.544,10.383,39.34,8.681,52.606,22.346c23.6,24.312,16.867,61.931,20.671,94.632,5.755,49.476,41.263,95.1,89.591,115.113"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, -20568.463, 6391.796)"
            fill="none"
            stroke="#d0e3bb"
            stroke-width="1"
          />
          <path
            id="Path_271868"
            data-name="Path 271868"
            d="M0,0C2.8,18.3,19.9,32.2,38.231,38.743s38.23,7.417,57.544,10.383,39.34,8.681,52.605,22.346c23.6,24.312,16.868,61.931,20.671,94.632,5.755,49.476,41.264,95.1,89.592,115.113"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, -20559.564, 6399.226)"
            fill="none"
            stroke="#d0e3bb"
            stroke-width="1"
          />
          <path
            id="Path_271869"
            data-name="Path 271869"
            d="M0,0C2.8,18.3,19.9,32.2,38.231,38.743s38.23,7.417,57.544,10.383,39.339,8.681,52.605,22.346c23.6,24.312,16.868,61.931,20.671,94.632,5.755,49.476,41.264,95.1,89.591,115.113"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, -20550.668, 6406.656)"
            fill="none"
            stroke="#d0e3bb"
            stroke-width="1"
          />
          <path
            id="Path_271870"
            data-name="Path 271870"
            d="M0,0C2.8,18.3,19.9,32.2,38.231,38.743s38.23,7.417,57.544,10.382,39.339,8.682,52.605,22.347c23.6,24.312,16.868,61.931,20.671,94.632,5.755,49.476,41.264,95.1,89.591,115.113"
            transform="matrix(-0.07, -0.998, 0.998, -0.07, -20541.771, 6414.085)"
            fill="none"
            stroke="#d0e3bb"
            stroke-width="1"
          />
        </g>
        <path
          id="Path_271866"
          data-name="Path 271866"
          d="M503.454,0c-4.315,27.082-14.683,53.079-30.614,74.788-31.437,42.816-83.557,67.652-134.682,64.16-5.432-.365-10.905-1.021-16.4-1.805-28.715-4.073-57.979-11.5-83.74,1.193-21.872,10.779-35.854,33.907-55.593,48.576-25.822,19.194-60.579,22.332-90.614,12.09S36.341,166.283,17.749,139.3C11.322,129.969,5.635,120.081,0,110.162V0Z"
          transform="translate(-21016.141 5948.275)"
          opacity="0.15"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  );
}
