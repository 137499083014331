import { Box, Divider } from "@mui/material";
import { CustomTypography } from "../shared";
import { GreenCornerCircle } from "../../assets/icons/GreenCornerCircle";
import { formatDecimalWithRupeeSymbol, SharedRedux, SharedUtilities } from "@mprofit/shared";
import SkeletonLoader from "../shared/skeleton-loader";
import { useAppSelector } from "../../redux/hooks";

interface TransactionProps {
  type: string;
  assetName: string;
  assetTypeID: number;
  currentPrice: string;
  currentValue: string;
  isLoading: boolean;
  PFID: number;
  refNo: string;
}

export default function TransactionTableCard({
  type,
  assetName,
  assetTypeID,
  currentPrice,
  currentValue,
  isLoading,
  PFID,
  refNo
}: TransactionProps) {

  const IsGroup: boolean = useAppSelector(SharedRedux.Portfolios.Selectors.selectIsActivePortfolioAGroup);
  const portfolioMap = useAppSelector(SharedRedux.Portfolios.Selectors.selectPortfolioById);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#fff",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative"
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          padding: "32px",
          justifyContent: "space-between",
        }}
      >
        <Box display={"block"}>
          <CustomTypography
            variant="Regular14"
            color={"#FFFFFF"}
            bgcolor={"#3A3D4D"}
            borderRadius={"4px"}
            padding={"5px 8px"}
          >
            {type}
          </CustomTypography>
          {isLoading ? (
            <SkeletonLoader height="26px" width="100%" animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8', marginTop: '16px' }} sxContainer={{ display: "flex", justifyContent: "flex-start" }} />
          ) : (
            <>
              <CustomTypography
                variant="Regular28"
                color={"#12131A"}
                mt={"8px"}
              >
                {assetName}
              </CustomTypography>
              {
                PFID || refNo ? (
                  <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                    {
                      refNo && (
                        <CustomTypography
                          variant="Regular14"
                          color={"#12131A"}
                        >
                          {SharedUtilities.getRefNumField(assetTypeID)}{" "}{refNo}
                        </CustomTypography>
                      )
                    }
                    {
                      IsGroup && PFID && (
                        <CustomTypography
                          variant="Regular14"
                          color={"#12131A"}
                          ml={refNo ? "8px" : "0px"}
                        >
                          {`Portfolio: ${portfolioMap[PFID]?.PName}`}
                        </CustomTypography>
                      )
                    }
                  </Box>
                ) : (
                  <></>
                )}
            </>
          )}
        </Box>
        <Box display={"flex"}>
          <Box
            display={"block"}
            mr={"44px"}
          >
            <CustomTypography
              variant="Regular18"
              color={"#12131A"}
            >
              {"Current Price"}
            </CustomTypography>
            <CustomTypography
              variant="Regular24"
              color={"#12131A"}
              mt={"8px"}
            >
              {isLoading ? (
                <SkeletonLoader height="16px" width="100%" animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8', marginTop: '12px' }} sxContainer={{ display: "flex", justifyContent: "flex-start" }} />
              ) : (
                <>
                  {formatDecimalWithRupeeSymbol(currentPrice, 2, 'IN')}
                </>
              )}
            </CustomTypography>
          </Box>
          <Divider
            orientation="vertical"
            sx={{ height: "66px", m: "0px 24px" }}
          />
          <Box
            display={"block"}
            mr={"106px"}
            ml={"10px"}
          >
            <CustomTypography variant="Regular18">
              {"Current value"}
            </CustomTypography>
            <CustomTypography
              variant="Regular24"
              color={"#12131A"}
              mt={"8px"}
            >
              {isLoading ? (
                <SkeletonLoader height="16px" width="100%" animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8', marginTop: '12px' }} sxContainer={{ display: "flex", justifyContent: "flex-start" }} />
              ) : (
                <>
                  {formatDecimalWithRupeeSymbol(currentValue, 2, 'IN')}
                </>
              )}
            </CustomTypography>
          </Box>
        </Box>
      </Box>
      <Box
        position={"absolute"}
        right={0}
        top={0}
      >
        <GreenCornerCircle />
      </Box>
    </Box>
  );
}
