export function PE(props: any) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="Group_150661" data-name="Group 150661" transform="translate(-35 -318)">
      <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0"/>
      <g id="noun-lock-1786558" transform="translate(-195.637 191.004)">
        <path id="Path_198387" data-name="Path 198387" d="M253.28,137.879h-1.541v-2.787a5.952,5.952,0,1,0-11.9,0v2.787h-1.541a1.832,1.832,0,0,0-1.83,1.83v13.373a1.832,1.832,0,0,0,1.83,1.83H253.28a1.832,1.832,0,0,0,1.83-1.83V139.709a1.832,1.832,0,0,0-1.83-1.83Zm-7.493-7.367a4.585,4.585,0,0,1,4.579,4.58v2.787h-9.159v-2.787a4.585,4.585,0,0,1,4.579-4.58Zm-7.95,9.2a.458.458,0,0,1,.458-.458h14.986a.458.458,0,0,1,.458.458v13.373a.458.458,0,0,1-.458.458H238.294a.458.458,0,0,1-.458-.458Z" transform="translate(0.642 0.887)" fill="#babdcc" className="icon-by-asset-type-fill" />
        <path id="Name" d="M5.169-1.153a.457.457,0,0,1-.309-.093,1.851,1.851,0,0,1-.194-.194L1.581-4.871a.7.7,0,0,1-.165-.273A1.4,1.4,0,0,1,1.38-5.5v-.517a.346.346,0,0,1,.093-.244.326.326,0,0,1,.251-.1H2.4A3.271,3.271,0,0,0,3.792-6.6a1.32,1.32,0,0,0,.667-.639,2.27,2.27,0,0,0,.179-.933,2.195,2.195,0,0,0-.179-.9,1.4,1.4,0,0,0-.639-.653A2.726,2.726,0,0,0,2.529-9.98h-.9a.346.346,0,0,1-.244-.093.326.326,0,0,1-.1-.251v-.531a.332.332,0,0,1,.1-.244.332.332,0,0,1,.244-.1H7.552a.332.332,0,0,1,.244.1.332.332,0,0,1,.1.244v.531a.326.326,0,0,1-.1.251.346.346,0,0,1-.244.093H5.27l.029-.344a1.687,1.687,0,0,1,1.091.868,3.177,3.177,0,0,1,.3,1.4A3.158,3.158,0,0,1,6.332-6.55,2.639,2.639,0,0,1,5.248-5.473a4.111,4.111,0,0,1-1.787.459l-.5.043.158-.818L6.806-1.627a.365.365,0,0,1,.115.215.227.227,0,0,1-.086.194.349.349,0,0,1-.215.065Zm-3.545-6.5a.326.326,0,0,1-.251-.1A.346.346,0,0,1,1.28-8V-8.53a.326.326,0,0,1,.1-.251.346.346,0,0,1,.244-.093H7.552a.346.346,0,0,1,.244.093.326.326,0,0,1,.1.251L7.882-8a.346.346,0,0,1-.093.244.326.326,0,0,1-.251.1Z" transform="translate(241.84 153.249)" fill="#babdcc" className="icon-by-asset-type-fill" />
      </g>
    </g>
  </svg>
)}