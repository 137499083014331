export function Debt(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g id="Group_228842" data-name="Group 228842" transform="translate(-11489 8928)">
        <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(11489 -8928)" fill="#fff" opacity="0" />
        <g id="Group_228838" data-name="Group 228838" transform="translate(11492.478 -8925.13)">
          <g id="Group_228786" data-name="Group 228786" transform="translate(4.438 8.965)">
            <path id="Path_272253" data-name="Path 272253" d="M25.766,32.23H9.893a2.039,2.039,0,0,1-1.524-.66,1.67,1.67,0,0,1-.4-1.494l2.441-10.79a1.628,1.628,0,0,1,.1-.3,1.8,1.8,0,0,1,.123-.235,1.468,1.468,0,0,1,.19-.245,1.421,1.421,0,0,1,.145-.147.676.676,0,0,1,.07-.057c.056-.045.117-.088.178-.129a2.134,2.134,0,0,1,.533-.237,1.264,1.264,0,0,1,.134-.035,2.114,2.114,0,0,1,.455-.049h9.082a.328.328,0,1,1,0,.653H12.334a1.335,1.335,0,0,0-.285.03.728.728,0,0,0-.071.018,1.426,1.426,0,0,0-.353.153c-.032.023-.081.055-.125.092a.336.336,0,0,1-.043.031c-.016.019-.044.043-.066.071a.3.3,0,0,1-.033.037.775.775,0,0,0-.11.142,1.047,1.047,0,0,0-.077.147,1.012,1.012,0,0,0-.063.187L8.668,30.207a1.063,1.063,0,0,0,.257.95,1.3,1.3,0,0,0,.969.42H25.766a1.3,1.3,0,0,0,.97-.419,1.065,1.065,0,0,0,.256-.952l-.857-3.425a.328.328,0,0,1,.278-.385.36.36,0,0,1,.423.254l.857,3.425a1.671,1.671,0,0,1-.4,1.5A2.039,2.039,0,0,1,25.766,32.23Z" transform="translate(-9.685 -17.855)" fill="#babdcc" stroke="#babdcc" stroke-width="0.4" />
          </g>
          <g id="Group_228788" data-name="Group 228788" transform="translate(8.364 2.115)">
            <path id="Path_272255" data-name="Path 272255" d="M19.461,9.387a.326.326,0,0,1-.118-.022,3.762,3.762,0,1,1,2.717,0,.326.326,0,0,1-.235-.609,3.1,3.1,0,1,0-2.245,0,.326.326,0,0,1-.118.631Z" transform="translate(-16.945 -2.115)" fill="#babdcc" stroke="#babdcc" stroke-width="0.4" />
          </g>
          <g id="Group_228789" data-name="Group 228789" transform="translate(10.552 6.649)">
            <path id="Path_272256" data-name="Path 272256" d="M24.78,15.5H22.3a.327.327,0,0,1-.326-.326V14.1a1.568,1.568,0,0,1,3.135,0v1.077A.327.327,0,0,1,24.78,15.5Zm-2.156-.653h1.829V14.1a.915.915,0,0,0-1.829,0v.75Z" transform="translate(-21.972 -12.533)" fill="#babdcc" stroke="#babdcc" stroke-width="0.4" />
          </g>
          <g id="Group_228790" data-name="Group 228790" transform="translate(14.688 7.385)">
            <path id="Path_272257" data-name="Path 272257" d="M37.078,25.432a5.6,5.6,0,1,1,5.6-5.6A5.61,5.61,0,0,1,37.078,25.432Zm0-10.357a4.753,4.753,0,1,0,4.753,4.753A4.759,4.759,0,0,0,37.078,15.075Z" transform="translate(-31.774 -14.525)" fill="#babdcc" stroke="#babdcc" stroke-width="0.4" />
          </g>
        </g>
        <path id="Path_272303" data-name="Path 272303" d="M11.533,7.2H7.8m3.736,1.811H7.8M9.665,12.63,7.8,10.819h.934a1.812,1.812,0,1,0,0-3.621" transform="translate(11502.962 -8922.316)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
      </g>
    </svg>
  )
}