export function PO(props: any) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g id="Group_150663" data-name="Group 150663" transform="translate(-35 -318)">
      <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(35 318)" fill="#fff" opacity="0"/>
      <g id="mailbox" transform="translate(39.412 323.534)">
        <path id="Path_198394" data-name="Path 198394" d="M5.794,4.448A4.345,4.345,0,0,0,1.448,8.794v8.691h8.691V8.794A4.345,4.345,0,0,0,5.794,4.448ZM5.794,3H17.381a5.794,5.794,0,0,1,5.794,5.794v8.691a1.448,1.448,0,0,1-1.448,1.448H1.448A1.448,1.448,0,0,1,0,17.484V8.794A5.794,5.794,0,0,1,5.794,3ZM9.626,4.448a5.779,5.779,0,0,1,1.961,4.345v8.691H21.727V8.794a4.345,4.345,0,0,0-4.345-4.345Z" fill="#babdcc" className="icon-by-asset-type-fill" />
        <path id="Path_198395" data-name="Path 198395" d="M15.736,9.621H11.691V8.173h7.242a.724.724,0,0,1,.724.724v1.448a.724.724,0,0,1-.724.724H17.484a.724.724,0,0,1-.513-.211ZM5.9,7.448c0,.8-.649,0-1.448,0S3,8.248,3,7.448a1.448,1.448,0,1,1,2.9,0Z" transform="translate(1.345 1.345)" fill="#babdcc" className="icon-by-asset-type-fill"/>
      </g>
    </g>
  </svg>
)}
