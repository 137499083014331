import { Box, Button, Grid, Stack, TableCell, TableRow } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { CustomTypography } from "../shared";
import BreadCrumbComponent from "../shared/breadcrumbs";
import { ArrowDown } from "../../assets/icons/ArrowDown";
import SharedVirtualizedTable, {
  ColumnDataProps,
  TableDecimalFieldFormatter,
  TableDefaultFieldFormatter,
} from "../shared/virtual-table-component";
import TransactionTableCard from "./transactionTableCard";
import CustomModal from "../shared/custom-modal";
import PeriodSelector from "../shared/period-selector";
import { AssetTypeDetails, AssetTypeEnum, Transaction as TransactionType } from "packages/shared/src/constants";
import SkeletonLoader from "../shared/skeleton-loader";
import { SkeletonLoaderConfig } from "../shared/expandable-table";
import { getBalQuant, getBalAmountForBanks, getTransactionForTable, checkHideTransaction, getTransactionsForTable } from "packages/shared/src/utilities";
import Big from "big.js";

interface TransactionsPeriodSelectorProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  transactions: TransactionType[] | undefined; 
}

interface BalanceRow { TranTypeStr: string; DT: string; Quant: number; Amount: number; BalQuant: number; BalAmount: number; }

export interface TableCellContentProps {
  excludeLoadingFields?: string[];
  skeletonLoaderStyles?: SkeletonLoaderConfig;
  isMarginCellRequired?: boolean;
}

const skeletonLoaderStyles = {
  'TranTypeStr': {
      height: '16px',
      width: '91px',
      position: 'flex-start'
  }
}

const skeletonLoaderStylesCommon = {
  'TranTypeStr': {
      height: '16px',
      width: '197px',
      position: 'flex-start'
  }
}

const getTransactionColumns = (assetTypeId?: number): ColumnDataProps[] => {
  if (!assetTypeId) return [];

  const assetType = AssetTypeDetails.find(asset => asset.ATyp === assetTypeId);
  if (!assetType) return [];

  const isDepositWithdrawalMode = assetTypeId === AssetTypeEnum.BANKS;

  const columns: ColumnDataProps[] = [];

  if (!isDepositWithdrawalMode) {
    columns.push({
      FieldKey: "TranTypeStr",
      HeaderName: "TRANSACTION TYPE",
      ColumnWidthPercent: "20%",
      FieldFormatter: TableDefaultFieldFormatter,
      Align: "left",
      IsRowName: true,
    })
  }

  columns.push({
    FieldKey: "DT",
    HeaderName: "DATE",
    IsDate: true,
    ColumnWidthPercent: "10%",
    FieldFormatter: (value) => new Date(value).toLocaleDateString('en-GB'),
    Align: "right",
    SortConfig_IsOnlyReverse: true,
    SortConfig_ReverseWhenDirection: SharedConstants.SortDirection.Up,
  });

  if (assetType.TransactionFields.Quantity) {
    columns.push({
      FieldKey: "Quant_Disp",
      HeaderName: "QUANTITY",
      ColumnWidthPercent: "10%",
      FieldFormatter: TableDecimalFieldFormatter,
      Align: "right",
    });
  }

  if (assetType.TransactionFields.Price) {
    columns.push({
      FieldKey: "Price",
      HeaderName: "PRICE",
      ColumnWidthPercent: "10%",
      FieldFormatter: TableDecimalFieldFormatter,
      Align: "right",
    });
  }

  if (assetType.TransactionFields.Brokerage) {
    columns.push({
      FieldKey: "Brokerage",
      HeaderName: "BROKERAGE",
      ColumnWidthPercent: "10%",
      FieldFormatter: TableDecimalFieldFormatter,
      Align: "right",
    });
  }

  if (assetType.TransactionFields.NARR) {
    columns.push({
      FieldKey: "NARR",
      HeaderName: "NARRATION",
      ColumnWidthPercent: "15%",
      FieldFormatter: TableDefaultFieldFormatter,
      Align: "left",
    });
  }

  if (isDepositWithdrawalMode) {
    columns.push(
      {
        FieldKey: "Deposit",
        HeaderName: "DEPOSIT",
        ColumnWidthPercent: "10%",
        FieldFormatter: TableDecimalFieldFormatter,
        Align: "right",
      },
      {
        FieldKey: "Withdrawal",
        HeaderName: "WITHDRAWAL",
        ColumnWidthPercent: "10%",
        FieldFormatter: TableDecimalFieldFormatter,
        Align: "right",
      }
    );
  } else {
    columns.push({
      FieldKey: "Amount",
      HeaderName: "AMOUNT",
      ColumnWidthPercent: "10%",
      FieldFormatter: TableDecimalFieldFormatter,
      Align: "right",
    });
  }

  if (assetType.TransactionFields.BalQuantity) {
    columns.push({
      FieldKey: "BalQuant",
      HeaderName: "BALANCE QUANTITY",
      ColumnWidthPercent: "10%",
      FieldFormatter: TableDecimalFieldFormatter,
      Align: "right",
    });
  }

  if (assetType.TransactionFields.BalAmount) {
    columns.push({
      FieldKey: "BalAmount",
      HeaderName: "BALANCE",
      ColumnWidthPercent: "10%",
      FieldFormatter: TableDecimalFieldFormatter,
      Align: "right",
    });
  }

  return columns;
};

export default function Transaction(props: {
  MPrUrlParams?: MPrUrlParams;
}) {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [openingBalanceRow, setOpeningBalanceRow] = useState<BalanceRow | null>(null);
  const [closingBalanceRow, setClosingBalanceRow] = useState<BalanceRow | null>(null);
  const [showPeriodSelectorModal, setShowPeriodSelectorModal] = useState(false);

  const activePeriod = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePeriod);
  const _transactions = useAppSelector(SharedRedux.Portfolios.Selectors.selectTransactions);
  const transactions = useMemo(() => {
    return getTransactionsForTable(_transactions);
  }, [_transactions])
  const openingBalances = useAppSelector(SharedRedux.Portfolios.Selectors.selectOpeningBalances);
  const closingBalances = useAppSelector(SharedRedux.Portfolios.Selectors.selectClosingBalances);
  const activeL4PortfolioSummaryTotal = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL4PortfolioSummaryTotal);
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const tableConfig = useAppSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(SharedConstants.TableType.Transaction));

  const assetTypeName = activeScreenIDs && activeScreenIDs.AssetType && activeScreenIDs.AssetType > 0 ? SharedConstants.AssetTypeNameMapping[activeScreenIDs.AssetType as SharedConstants.AssetTypeEnum] : undefined;
  const shouldSwapHeaderFooter = tableConfig?.sortConfig.key === "DT" && 
    tableConfig.sortConfig.direction === SharedConstants.SortDirection.Up;

  const isDepositWithdrawalMode = activeL4PortfolioSummaryTotal?.AssetTypeID === AssetTypeEnum.BANKS;

  const transactionTableColumns = useMemo(() => 
    getTransactionColumns(activeL4PortfolioSummaryTotal?.AssetTypeID),
  [activeL4PortfolioSummaryTotal?.AssetTypeID]);

  useEffect(() => {
    if (transactions && openingBalances && closingBalances && activeL4PortfolioSummaryTotal) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [transactions, openingBalances, closingBalances, activeL4PortfolioSummaryTotal]);

  const processedTransactions = useMemo(() => {
    if (!transactions || !activePeriod.PeriodFrom || !activePeriod.PeriodTo || !openingBalances) return { filtered: [], finalBalQuant: 0, finalBalAmount: new Big(0) };
    
    const periodStart = new Date(activePeriod.PeriodFrom);
    const periodEnd = new Date(activePeriod.PeriodTo);
    
    periodStart.setHours(0, 0, 0, 0);
    periodEnd.setHours(23, 59, 59, 999);
    
    let prevBalQuant = new Big(openingBalances.Quant || 0);
    let prevBalAmount = new Big(openingBalances.Amount || 0);

    const filtered = transactions
      .filter(transaction => {
        return transaction.DT >= periodStart && transaction.DT <= periodEnd && !checkHideTransaction(transaction);
      })
      .map(transaction => {
        var transactionBalQuant = getTransactionForTable(transaction, prevBalQuant, prevBalAmount, isDepositWithdrawalMode);
        if (transactionBalQuant) {
          prevBalQuant = transactionBalQuant.BalQuant;
          prevBalAmount = transactionBalQuant.BalAmount;
        }
        return transactionBalQuant;
      });

    return { 
      filtered, 
      finalBalQuant: prevBalQuant,
      finalBalAmount: prevBalAmount 
    };
  }, [transactions, activePeriod, openingBalances, activeL4PortfolioSummaryTotal?.AssetTypeID, isDepositWithdrawalMode]);

  useEffect(() => {
    if (activePeriod.PeriodFrom && openingBalances) {
      setOpeningBalanceRow({
        TranTypeStr: "Opening Balance",
        DT: activePeriod.PeriodFrom,
        Quant: openingBalances.Quant || 0,
        Amount: openingBalances.Amount || 0,
        BalQuant: openingBalances.Quant || 0,
        BalAmount: openingBalances.Amount || 0
      });
    }
  }, [activePeriod.PeriodFrom, openingBalances]);

  useEffect(() => {
    if (activePeriod.PeriodTo && closingBalances) {
      setClosingBalanceRow({
        TranTypeStr: "Closing Balance",
        DT: activePeriod.PeriodTo,
        Quant: closingBalances.Quant || 0,
        Amount: closingBalances.Amount || 0,
        BalQuant: Big(processedTransactions.finalBalQuant).toNumber(),
        BalAmount: Big(processedTransactions.finalBalAmount).toNumber()
      });
    }
  }, [activePeriod.PeriodTo, closingBalances, processedTransactions.finalBalQuant, processedTransactions.finalBalAmount]);

  useEffect(() => {
    if (activeScreenIDs && activeScreenIDs.FamilyID && activeScreenIDs.OrigPFID && activeScreenIDs.DatabaseID && activeScreenIDs.SID && activeScreenIDs.AssetType && activeScreenIDs.AMID) {
      dispatch(SharedRedux.Portfolios.Actions.fetchTransactions({
        FamilyId: activeScreenIDs?.FamilyID,
        PFID:  activeScreenIDs?.OrigPFID,
        SID: activeScreenIDs?.SID,
        CCID: activeScreenIDs?.DatabaseID,
      }))

      dispatch(SharedRedux.Portfolios.Actions.fetchBalances({
        FamilyId: activeScreenIDs?.FamilyID,
        PFID:  activeScreenIDs?.OrigPFID,
        SID: activeScreenIDs?.SID,
        Amid: activeScreenIDs?.AMID,
        Atyp: activeScreenIDs?.AssetType,
        IsClosing: false,
        CCID: activeScreenIDs?.DatabaseID,
      }))
  
      dispatch(SharedRedux.Portfolios.Actions.fetchBalances({
        FamilyId: activeScreenIDs?.FamilyID,
        PFID:  activeScreenIDs?.OrigPFID,
        SID: activeScreenIDs?.SID,
        Amid: activeScreenIDs?.AMID,
        Atyp: activeScreenIDs?.AssetType,
        IsClosing: true,
        CCID: activeScreenIDs?.DatabaseID,
      }))

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreenIDs])

  useEffect(() => {
    if (activePeriod.IsAllToDate && transactions && transactions.length > 0) {
      const firstTransactionDate = new Date(transactions[0].DT);
      const today = new Date();   
      if (firstTransactionDate.getTime() !== (activePeriod.PeriodFrom ? new Date(activePeriod.PeriodFrom).getTime() : 0)) {
        dispatch(SharedRedux.Portfolios.Actions.setActivePeriod({
          ...activePeriod,
          PeriodFromValue: firstTransactionDate,
          PeriodToValue: today
        }));
      }
    }
  }, [transactions]);

  const headerContent = ({ excludeLoadingFields=[], skeletonLoaderStyles={} }: TableCellContentProps) => {
    return (
      <TableRow sx={{ background: "#FFFFFF" }}>
        {transactionTableColumns.map((column) => {
          return (
            <TableCell
              key={column.FieldKey}
              align={column.Align}
              sx={{ borderTop: tableConfig?.sortConfig?.direction === SharedConstants.SortDirection.Up ? "1px solid #e0e0e0" : "none", height: "41px", borderBottom: tableConfig?.sortConfig?.direction === SharedConstants.SortDirection.Down ? "1px solid #e0e0e0" : "none" }}
            >
              {isLoading && !excludeLoadingFields.includes(column.FieldKey) ? (
                <SkeletonLoader {...(skeletonLoaderStyles[column.FieldKey] || {height: "12px", width: "86px"})} animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8'}} sxContainer={{display: "flex", justifyContent: skeletonLoaderStyles[column.FieldKey]?.position || "flex-end"}} />
              ) : (
              <CustomTypography variant="Medium18">
                {column.FieldKey === "NARR" && isDepositWithdrawalMode ? "Opening Balance" : column.FieldFormatter(openingBalanceRow?.[column.FieldKey as keyof BalanceRow])}
              </CustomTypography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const footerContent = ({ excludeLoadingFields=[], skeletonLoaderStyles={} }: TableCellContentProps) => {
    return (
      <TableRow sx={{ background: "#FFFFFF" }}>
        {transactionTableColumns.map((column) => {
          return (
            <TableCell
              key={column.FieldKey}
              align={column.Align}
              sx={{ borderTop: tableConfig?.sortConfig?.direction === SharedConstants.SortDirection.Down ? "1px solid #e0e0e0" : "none", height: "41px", borderBottom: tableConfig?.sortConfig?.direction === SharedConstants.SortDirection.Up ? "1px solid #e0e0e0" : "none" }}
            >
              {isLoading && !excludeLoadingFields.includes(column.FieldKey) ? (
                <SkeletonLoader {...(skeletonLoaderStyles[column.FieldKey] || {height: "12px", width: "86px"})} animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#F8F8F8'}} sxContainer={{display: "flex", justifyContent: skeletonLoaderStyles[column.FieldKey]?.position || "flex-end"}} />
              ) : (
              <CustomTypography variant="Medium18">
                {column.FieldFormatter(closingBalanceRow?.[column.FieldKey as keyof BalanceRow])}
              </CustomTypography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <Grid
      container
      sx={style.layout}
    >
      <Grid
        item
        xs={12}
      >
        <Box mx={"32px"}>
          <TransactionTableCard
            assetName={activeL4PortfolioSummaryTotal?.Name || ''}
            assetTypeID={activeL4PortfolioSummaryTotal?.AssetTypeID || 0}
            PFID={activeL4PortfolioSummaryTotal?.PFID || 0}
            refNo={activeL4PortfolioSummaryTotal?.Refno || ''}
            type={assetTypeName!}
            currentPrice={`${activeL4PortfolioSummaryTotal?.PxCurr || 0}`}
            currentValue={`${activeL4PortfolioSummaryTotal?.CurrValue || 0}`}
            isLoading={isLoading}
          />
        </Box>
        <Grid
          item
          m={"32px"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
          >
            <CustomTypography
              variant="Regular18"
              sx={{ color: { xs: "#64677A" }, paddingRight: "17px" }}
            >
              Period
            </CustomTypography>
            <Box>
              <Button
                disableRipple
                endIcon={<ArrowDown />}
                sx={{
                  backgroundColor: "#fff",
                  textTransform: "none",
                  border: "1px solid #D8DAE5",
                  padding: "6px 16px 6.5px 16px",
                  color: "#64677A",
                  fontSize: "18px",
                  fontWeight: "400",
                  "&.Mui-disabled": {
                    color: "#64677A",
                  },
                }}
                onClick={() => setShowPeriodSelectorModal(true)}
              >
                {activePeriod.PeriodText}
              </Button>
              {showPeriodSelectorModal && 
                <TransactionsPeriodSelector showModal={showPeriodSelectorModal} setShowModal={setShowPeriodSelectorModal} transactions={transactions} />
              }
            </Box>
          </Box>
          {/* <Box display={"flex"}>
            <ButtonWithTextAndIcon
              buttonlabel={"Other Transaction"}
              endIcon={<ArrowDown />}
              startIcon={<DocumentIcon />}
              style={{ padding: "0 12px 0 12px" }}
            />
            <Box ml={"16px"}>
              <ButtonPrimaryCTA
                buttonText="Add Trade"
                startIcon={<AddIcon />}
                maxHeight="40px"
              />
            </Box>
          </Box> */}
        </Grid>
        <Grid
          item
          mx={"32px"}
          my={"24px"}
        >
          <SharedVirtualizedTable
            columns={transactionTableColumns}
            rows={processedTransactions.filtered}
            borderRadius="8px 8px 0px 0px"
            sorting={true}
            tableHeight='600px'
            headerColor="#EAF4DF"
            onRowClick={() => {}}
            headerContent={shouldSwapHeaderFooter ? 
              () => footerContent({ excludeLoadingFields: ['Price', 'Brokerage'], skeletonLoaderStyles: skeletonLoaderStylesCommon }) : 
              () => headerContent({ excludeLoadingFields: ['Price', 'Brokerage'], skeletonLoaderStyles: skeletonLoaderStylesCommon })
            }
            footerContent={shouldSwapHeaderFooter ? 
              () => headerContent({ excludeLoadingFields: ['Price', 'Brokerage'], skeletonLoaderStyles: skeletonLoaderStylesCommon }) : 
              () => footerContent({ excludeLoadingFields: ['Price', 'Brokerage'], skeletonLoaderStyles: skeletonLoaderStylesCommon })
            }
            style={{ height: "41px", variant: "Medium18", borderBottom: "1px solid #EBECF2" }}
            defaultSortConfig={SharedConstants.DefaultSortingForTransactionTable}
            tableType={SharedConstants.TableType.Transaction}
            sortableColumns={["DT"]}
            isLoading={isLoading}
            skeletonLoaderStyles={skeletonLoaderStyles}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export const TransactionsPeriodSelector =
    ({showModal, setShowModal, transactions}: TransactionsPeriodSelectorProps) => {
    const dispatch = useAppDispatch();

    return (
        <CustomModal
            open={showModal}
            handleClose={() => setShowModal(false)}
        >
            <Stack
                sx={{
                    width: "fit-content",
                    background: "white",
                    borderRadius: "10px",
                }}
                >
                <PeriodSelector
                    closePopOver={() => setShowModal(false)}
                    onSetPeriod={(period: SharedConstants.SetPeriodPayload) => {  
                      if (period.IsAllToDate && transactions && transactions.length > 0) {
                        const firstTransactionDate = new Date(transactions[0].DT);
                        const today = new Date();
                        const updatedPeriod = {
                          ...period,
                          PeriodFromValue: firstTransactionDate,
                          PeriodToValue: today
                        };
                        dispatch(SharedRedux.Portfolios.Actions.setActivePeriod(updatedPeriod));
                      } else {
                        dispatch(SharedRedux.Portfolios.Actions.setActivePeriod(period));
                      }
                      setShowModal(false);
                    }}
                />
                </Stack>
        </CustomModal>
    )
}

const style = {
  layout: {
    display: "flex",
    width: { xs: "100%" },
  },
  stickyHeader: {
    padding: { xs: "16px 42px", lg: "17px 42px" },
  },
};
