import { Big } from "big.js";
import { TranTypeMap } from "../constants/transactions";
import { RawTransactionFromAPI, Transaction, TransactionBalQuant } from "../constants";

export const getBalQuant = (TranType: number, PrevBalQuant: Big, QuantInRow: Big): Big => {
  var BalQuant: Big = new Big(0);
  if (TranType == TranTypeMap.BUY) {
      BalQuant = PrevBalQuant.plus(QuantInRow);
  } else if (TranType == TranTypeMap.SELL) {
      BalQuant = PrevBalQuant.minus(QuantInRow);
  } else if ((TranType > TranTypeMap.SELL_DEMARC) && (TranType != TranTypeMap.DMRGR_CLOSE) && (TranType != TranTypeMap.SPLIT_CLOSE)) {
      BalQuant = PrevBalQuant.minus(QuantInRow);
  } else if ((TranType == TranTypeMap.SPLIT) || (TranType == TranTypeMap.DMRGR)) {
      BalQuant = QuantInRow;
  } else if ((TranType < TranTypeMap.BUY_DEMARC)) {
      BalQuant = PrevBalQuant.plus(QuantInRow);
  } else {
      BalQuant = PrevBalQuant;
  }

  return BalQuant;
}

export const getBalAmountForBanks = (TranType: number, PrevBalAmount: Big, AmountInRow: Big): Big => {
  var BalAmount: Big = new Big(0);
  if (TranType === TranTypeMap.BNK_OPBAL) {
      BalAmount = AmountInRow;
  } else if (TranType <= TranTypeMap.BNK_DEPOSIT_DEMARC) {
      BalAmount = PrevBalAmount.plus(AmountInRow);
  } else {
      BalAmount = PrevBalAmount.minus(AmountInRow);
  }

  return BalAmount;
}

export const getTransactionForTable = (transaction: Transaction, prevBalQuant: Big, prevBalAmount: Big, isDepositWithdrawalMode: boolean) => {
    const balQuant = getBalQuant(transaction.TranType, prevBalQuant, new Big(transaction.Quant || 0));
        
    let balAmount = prevBalAmount;
    if (isDepositWithdrawalMode) {
      balAmount = getBalAmountForBanks(
        transaction.TranType, 
        prevBalAmount, 
        new Big(transaction.Amount || 0)
      );
    }

    prevBalQuant = balQuant;
    prevBalAmount = balAmount;

    var transactionBalQuant = <TransactionBalQuant>{
        ...transaction,
        BalQuant: balQuant,
        BalAmount: balAmount,
    };

    if (isDepositWithdrawalMode) {
        if (transaction.TranType <= TranTypeMap.BNK_DEPOSIT_DEMARC) {
            transactionBalQuant.Deposit = transaction.Amount;
        } else {
            transactionBalQuant.Withdrawal = transaction.Amount;
        }
    }

    return transactionBalQuant;
}

export const getTransactionsForTable = (transactions: RawTransactionFromAPI[] | undefined) => {
    if (!transactions) return undefined;

    var mtmTransTypes = [TranTypeMap.MtoMBUY,TranTypeMap.MtoMSELL];

    return transactions?.map(x => new Transaction(x))
    .sort((x,y) => {
        if (x.DT < y.DT) {
            return -1;
        } else if (x.DT > y.DT) {
            return 1;
        } else if (mtmTransTypes.includes(x.TranType) && !mtmTransTypes.includes(y.TranType)) {
            return 1;
        } else if (!mtmTransTypes.includes(x.TranType) && mtmTransTypes.includes(y.TranType)) {
            return -1;
        } else if (mtmTransTypes.includes(x.TranType) && mtmTransTypes.includes(y.TranType)) {
            return x.TransID < y.TransID ? -1 : 1;
        } else if (x.StoredTranTypeStr <= y.StoredTranTypeStr) {
            return -1;
        } else {
            return 1;
        }
    });
}

export const TransHide = [TranTypeMap.SPLIT_CLOSE, TranTypeMap.DMRGR_CLOSE, TranTypeMap.MERGER_RECFOR_CG]

export const checkHideTransaction = (transaction: Transaction) => {
    return TransHide.includes(transaction.TranType);
}