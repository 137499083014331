export function RealEstate(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
            <g id="Group_228828" data-name="Group 228828" transform="translate(-11489 8928)">
                <rect id="Rectangle_24190" data-name="Rectangle 24190" width="32" height="32" transform="translate(11489 -8928)" fill="#fff" opacity="0" />
                <path id="Path_272248" data-name="Path 272248" d="M2.25,21h19.5M3.75,3V21M14.25,3V21m6-13.5V21M6.75,9.75H7.5m3,0h.75M6.75,21V17.625A1.125,1.125,0,0,1,7.875,16.5h2.25a1.125,1.125,0,0,1,1.125,1.125V21M3,3H15m-.75,4.5H21" transform="translate(11493.25 -8923.5)" fill="none" stroke="#babdcc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </g>
        </svg>
    )
}
